import { Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { CognitoCallback, CognitoUtil, LoggedInCallback } from './cognito.service';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import { environment } from 'src/environments/environment';
import { HomePathService } from 'src/app/services/home-path.service';
import { Login } from '../interfaces/login.interface';

@Injectable()
export class AuthService {

    constructor(public cognitoUtil: CognitoUtil, private homePath: HomePathService) { }

    private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

        AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

        const clientParams: any = {};

        if (environment.sts_endpoint) {
            clientParams.endpoint = environment.sts_endpoint;
        }

        const sts = new STS(clientParams);

        sts.getCallerIdentity(function (err: any, data: any) {
            callback.cognitoCallback('', session);
        });
    }

    private onLoginError = (callback: CognitoCallback, err: any) => {
        callback.cognitoCallback(err.message, null);
    }

    authenticate(login: Login, callback: CognitoCallback) {

        const authenticationData = {
            Username: login.user,
            Password: login.password
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: login.user,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback('Ingresar nuevo password', null),
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err)
        });
    }

    logout() {
        this.cognitoUtil.getCurrentUser()!.signOut();
        localStorage.clear();
    }

    isAuthenticatedPromise(): Promise<boolean> {
        return new Promise((resolve, reject) => {

            const cognitoUser = this.cognitoUtil.getCurrentUser();

            if (cognitoUser != null) {
                cognitoUser.getSession(function (err: any, session: any) {
                    if (err) {
                        resolve(false);
                    } else {
                        resolve(session.isValid());
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    isAuthenticated(callback: LoggedInCallback) {
        if (!this.homePath.getHomePath().includes('internal')) {
            if (callback == null) {
                throw ('AuthService: isAuthenticated: Callback es null');
            }

            const cognitoUser = this.cognitoUtil.getCurrentUser();

            if (cognitoUser != null) {
                cognitoUser.getSession(function (err: any, session: any) {
                    if (err) {
                        console.error('AuthService: isAuthenticated: No se puede obtener la sesion: ', err.stack);
                        console.error(err);
                        callback.isLoggedIn(err, false);
                    } else {
                        callback.isLoggedIn(err, session.isValid());
                    }
                });
            } else {
                console.log('AuthService: isAuthenticated: No se puede obtener el usuario');
                callback.isLoggedIn('No se puede obtener el usuario', false);
            }
        }
    }

}
