import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { AuthTokenService } from 'src/app/services/authToken.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    public authService: AuthTokenService,
     private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.includes('upload/') && !request.url.includes('download/')) {

      if (this.authService.getJwtToken()) {

        const decoded: any = jwt_decode(this.authService?.getJwtToken() || '');
        const jwtExp = new Date(decoded.exp * 1000);
        const dt = new Date(jwtExp.getTime() - 55 * 60000);

        if (new Date() >= jwtExp) {
          this.router.navigate(['/session-exp']);
        }

        if (new Date() >= dt && !request.url.includes('refresh_token')) {
          return this.handle401Error(request, next);
        }

        request = this.addToken(request, this.authService.getJwtToken()!);
      }

      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          this.isRefreshing = false;
          if (error.status === 403 || error.status === 401) {
            this.router.navigate(['/home-internal/logout']);
            return throwError(error);
          }
          return throwError(error);
        }
      }));

      

    } else {
      return next.handle(request);
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'tokenHeader': `${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken2().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

}
