import { Injectable } from '@angular/core';
import { Usuario } from 'src/app/interfaces/usuario_session.interface';
import { UserDetails } from '../interfaces/userDetails.interface';

@Injectable({
    providedIn: 'root'
})
export class UsuarioSesionService {

    constructor() { }

    get usuario() {
        let usuario: Usuario;
        usuario = JSON.parse(localStorage.getItem('usuario')!);
        return usuario;
    }

    get userDetails() {
        let userDetails: UserDetails;
        userDetails = JSON.parse(localStorage.getItem('userDetails')!);
        return userDetails;
    }

}
