import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { BuscarService } from 'src/app/services/buscar.service';
import { HomePathService } from 'src/app/services/home-path.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html'
})
export class BuscarComponent implements OnInit {

  homePath: string;
  numFolio: string;
  bandeja: any;

  constructor(
    private router: Router,
    private homePathService: HomePathService,
    private route: ActivatedRoute,
    private buscarService: BuscarService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    public usuarioSesionService: UsuarioSesionService
  ) { }

  ngOnInit(): void {
    this.homePath = this.homePathService.getHomePath();
    this.route.params.subscribe(p => {
      this.numFolio = (p['numSolicitud']);
      this.buscar();
    });
  }

  buscar() {
    if (this.numFolio.toUpperCase().endsWith('-P')) {
      this.buscarService.findProyectoByNumFolio(this.numFolio).subscribe({
        next: (v: any) => {
          if (v != null) {
            this.router.navigate([this.homePath + '/proyecto/gestionProyecto', v.cveProyecto]);
          }
          else {
            this.messageService.add({ severity: 'warn', summary: 'Mensaje!', detail: 'Registros no encontrados.' });
            this.router.navigate([this.homePath + '/inicio'])
          }
        },
        error: (e) => {
          console.error('Error:', e);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Registros no cargados.' });
        },
        complete: () => console.log('Complete')
      });
    }
    else if (this.numFolio.toUpperCase().endsWith('-S')) {
      this.buscarService.findSolicitudByNumFolio(this.numFolio).subscribe({
        next: (v: any) => {
          if (v) {
            if (this.usuarioSesionService.usuario.idUsuario === v.designado) {
              this.router.navigate([this.homePath + '/proyecto/respuestaSolicitud', v.id]);
            }
            else {
              this.router.navigate([this.homePath + '/proyecto/detalleSolicitud', v.id, v.idProyecto]);
            }
          }
          else {
            this.messageService.add({ severity: 'warn', summary: 'Mensaje!', detail: 'Registros no encontrados' });
            this.router.navigate([this.homePath + '/inicio']);
          }
        },
        error: (e) => {
          console.error('Error:', e);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Registros no cargados.' });
        },
        complete: () => console.log('Complete')
      });
    }
    else {
      if (this.usuarioSesionService.usuario.rol === 'DESIGNADO') {
        this.messageService.add({ severity: 'warn', summary: 'Mensaje!', detail: 'Registros no encontrados' });
        this.router.navigate([this.homePath + '/inicio']);
      }
      else {
        this.buscarService.findProyectoByNomProyecto(this.numFolio).subscribe({
          next: (v: any) => {
            this.bandeja = v;
          },
          error: (e) => {
            console.error('Error:', e);
            this.loaderService.isLoading.next(false);
          },
          complete: () => console.log('Complete')
        });
      }
    }

  }

  routeDetalleProyecto(id: number) {
    this.router.navigate([this.homePath + '/proyecto/gestionProyecto', id]);
  }

}
