import { Component, OnInit } from '@angular/core';
import { MessageService, MenuItem } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { Solicitud } from 'src/app/interfaces/solicitud.interface';


@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html'
})
export class SolicitudComponent implements OnInit {

  items!: MenuItem[];

  constructor(private messageService: MessageService,
    private fb: FormBuilder,
    public solicitudInformacionService: SolicitudInformacionService) {

  }

  ngOnInit(): void {

    this.items = [{
      label: 'Datos de la solicitud',
      routerLink: 'datos'
    },
    {
      label: 'Persona(s) o grupo a solicitar',
      routerLink: 'solicitar'
    },
    {
      label: 'Checklist de la solicitud',
      routerLink: 'checklist'
    },

    {
      label: 'Documentos adjuntos',
      routerLink: 'documentos'
    },
    {
      label: 'Confirmación',
      routerLink: 'confirmacion'
    }

    ]
  }


  ngOnDestroy() {
    this.solicitudInformacionService.datosSolicitud = {} as Solicitud;
  }



}
