import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { api } from "src/environments/environment";
import { Catalogo } from "../interfaces/catalogo.interface";

@Injectable({
    providedIn: 'root'
})
export class CatalogoService {

    URL = `${api.urlAPI}` + 'api/catalogo/';

    constructor(private http: HttpClient) { }

    getCatalogosNemonico(desNemonico: string, status: boolean): Observable<Catalogo[]> {
        return this.http.get<Catalogo[]>(this.URL + 'list/' + desNemonico + '/' + status);
    }
}