import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoCallback } from 'src/app/services/cognito.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserRegistrationService } from '../../../services/user-registration.service';
import { ConfirmationService } from 'primeng/api';

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
}

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent implements CognitoCallback {

  loginProcess: Boolean;
  registrationUser: NewPasswordUser;
  router: Router;
  errorMessage: string;
  formGroup: FormGroup;

  constructor(public userRegistration: UserRegistrationService,
    public userService: AuthService, router: Router, private confirmationService: ConfirmationService) {
    this.router = router;
    this.loginProcess = false;
    this.onInit();
  }

  onInit() {
    this.registrationUser = new NewPasswordUser();
    this.errorMessage = '';

    this.formGroup = new FormGroup({
      user: new FormControl('', [
        Validators.required
      ]),

      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),

      newPassword: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),

      newPassword2: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ])
    });
  }

  onRegister() {
    if (this.formGroup.value['newPassword'] === this.formGroup.value['newPassword2']) {
      this.loginProcess = true;
      this.registrationUser.username = this.formGroup.value['user'];
      this.registrationUser.existingPassword = this.formGroup.value['password'];
      this.registrationUser.password = this.formGroup.value['newPassword'];
      this.errorMessage = '';
      this.userRegistration.newPassword(this.registrationUser, this);
    } else {
      this.errorMessage = 'Las contraseñas no coinciden';
    }
  }

  cognitoCallback(message: any, result: any) {
    this.loginProcess = false;
    if (message !== null && message !== '') {
      if (message.message.includes('Password does not conform to policy:')) {
        this.errorMessage = 'La contraseña debe cumplir con al menos 8 caracteres de los cuales al menos debe haber una mayúscula,' +
          ' un número y un carácter especial';
      } else {
        this.errorMessage = message.message;
      }
    } else {
      this.confirmationService.confirm({
        message: 'Usuario actualizado correctamente, sera redireccionado para iniciar sesión',
        rejectVisible: false,
        accept: () => {
          setTimeout(() => { this.router.navigate(['home/logout']); }, 2000);
        }
      });
      this.router.navigate(['/ediscovery/logout']);
    }
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.navigate(['/home']);
    }
  }
}
