import { Injectable } from '@angular/core';
import { CognitoUtil } from './cognito.service';
import { HttpClient } from '@angular/common/http';
import { api } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TokenDTO } from 'src/app/interfaces/token_dto.interface';
import { PerfilesService } from './perfiles.service';
import { MfaService } from './mfa.service';

@Injectable()
export class UsuarioParametrosService {

    URL = `${api.urlAPI}` + 'api/adam/';

    constructor(public cognitoUtil: CognitoUtil, public perfilesService: PerfilesService, private http: HttpClient,
        private mfaService: MfaService) { }

    getParametros() {
        const cognitoUser = this.cognitoUtil.getCurrentUser();
        return new Promise((resolve, reject) => {
            this.perfilesService.getPerfilUsuario(cognitoUser!.getUsername().toUpperCase()).subscribe(result => {
                resolve(result);
            });
        });
    }

    getToken(usr: string): Observable<TokenDTO> {
        return this.http.get<TokenDTO>(this.URL + 'token/' + usr);
    }

    getTokenPromise(usr: string) {
        return new Promise((resolve, reject) => {
            this.getToken(usr).subscribe(result => {
                resolve(result);
            });
        });
    }

    getdatosGMfa() {
        return new Promise((resolve, reject) => {
            this.mfaService.getdatosMfaAdm().subscribe(result => {
                resolve(result);
            });
        });
    }

}
