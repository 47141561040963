import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CognitoCallback } from '../../../services/cognito.service';
import { UserLoginService } from '../../../services/user-login.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-nuevo-password',
  templateUrl: './password-olvidado-paso-1.component.html',
  styleUrls: ['./password-olvidado.component.css']
})
export class PasswordOlvidadoPaso1Component implements OnInit, CognitoCallback {

  loginProcess: Boolean;
  errorMessage: string;
  formGroup: FormGroup;

  constructor(public router: Router, public usuarioLogin: UserLoginService) {
    this.errorMessage = '';
    this.loginProcess = false;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      user: new FormControl('', [
        Validators.required
      ])
    });
  }

  onSiguiente() {
    console.log('PasswordOlvidadoPaso1Component: onSiguiente: usuario: ' + this.formGroup.value['user']);
    this.loginProcess = true;
    this.errorMessage = '';
    this.usuarioLogin.forgotPassword(this.formGroup.value['user'], this);
  }

  cognitoCallback(message:  string, result: any) {
    this.loginProcess = false;
    if (message.length === 0 && result == null) {
      this.router.navigate(['/ediscovery-forgotPassword', this.formGroup.value['user']]);
    } else {
      console.error('Error: ', message);
      this.errorMessage = message;
    }
  }

}

@Component({
  selector: 'app-nuevo-password',
  templateUrl: './password-olvidado-paso-2.component.html',
  styleUrls: ['./password-olvidado.component.css']
})
export class PasswordOlvidadoPaso2Component implements OnInit, OnDestroy, CognitoCallback {

  loginProcess: Boolean;
  formGroup: FormGroup;
  user: string;
  errorMessage: string;
  showError: boolean;
  private sub: any;

  constructor(public router: Router, public route: ActivatedRoute, public usuarioLoginService: UserLoginService) {
    this.showError = false;
    this.loginProcess = false;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      codigoConfirmacion: new FormControl('', [
        Validators.required
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ]),
      password2: new FormControl('', [
        Validators.required,
        Validators.minLength(8)
      ])
    });

    this.sub = this.route.params.subscribe(params => {
      this.user = params['user'];
    });
    this.errorMessage = '';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSiguiente() {
    this.loginProcess = true;
    console.log('PasswordOlvidadoPaso2Component: onSiguiente: user: ' + this.user);
    this.errorMessage = '';
    if (this.formGroup.value['password'] === this.formGroup.value['password2']) {
      this.usuarioLoginService.confirmNewPassword(this.user, this.formGroup.value['codigoConfirmacion'],
        this.formGroup.value['password'], this);
    } else {
      this.errorMessage = 'Las contraseñas no coinciden';
      this.loginProcess = false;
      this.showError = true;
    }
  }

  cognitoCallback(message: string) {
    this.loginProcess = false;
    console.log('PasswordOlvidadoPaso2Component: cognitoCallback: message: ' + message);
    if (message.length != 0) {
      if (message.includes('Password does not conform to policy:')) {
        this.errorMessage = 'La contraseña debe cumplir con al menos 8 caracteres de los cuales al menos debe haber una mayúscula,' +
          ' un número y un carácter especial';
      } else {
        this.errorMessage = message;
      }
      this.showError = true;
    } else {
      this.router.navigate(['/ediscovery/login']);
    }
  }

}
