import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { Nivel } from 'src/app/interfaces/nivel.interface';
import { MatrizService } from 'src/app/services/matriz.service';

@Component({
  selector: 'app-editar-escalamiento',
  templateUrl: './editar-escalamiento.component.html',
})
export class EditarEscalamientoComponent implements OnInit {

  @Input()
  showEscala: boolean = false;

  @Input()
  idEscalamiento$: Observable<any> = new Observable<any>();

  @Output()
  closingDlg: EventEmitter<boolean> = new EventEmitter<boolean>();

  nivelEscala?: number;
  fechaVencimiento?: Date;
  minDate?: Date;
  escalamiento?: any;
  escalamientoForm!: FormGroup;
  nivelActual: Nivel;
  idEscala: number;
  invalidDates: Array<Date>;


  nivelEsc: Nivel[] = [
    { nomNivel: "Nivel 2", code: 2 },
    { nomNivel: "Nivel 3", code: 3 },
  ];

  ngOnInit() {
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month === 0) ? 11 : month - 1;
    let prevYear = (prevMonth === 11) ? year - 1 : year;

    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);

    this.invalidDates = [];
    for (let index: number = 0; index < 10000; index++) {
      const invalidDate = new Date();
      invalidDate.setDate(today.getDate() - index);
      this.invalidDates.push(invalidDate);
    }

    this.idEscalamiento$.subscribe(escala => {
      this.idEscala = escala.escalamiento;
      this.nivelActual = escala.nivelEscala == 2 ? this.nivelEsc[0] : this.nivelEsc[1];
      this.fechaVencimiento = new Date(escala.fechaVencimiento);
    });
  }

  cancelarEdit() {
    this.closingDlg.emit(false);
    this.showEscala = false;
  }

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private matrizService: MatrizService) {
    this.editarEscalamiento();
  }

  async updateDlg() {
    const body = {
      "escalamiento": this.idEscala,
      "nivelEscala": this.nivelActual.code,
      "fechaVencimiento": this.fechaVencimiento
    }
    this.matrizService.updtEscalamiento(body).subscribe(
      () => {
        this.escalamiento as {};
        this.closingDlg.emit(false);
        this.messageService.add({ severity: 'success', summary: 'Actualizado', detail: 'Se ha actualizado el escalamiento correctamente.' })
      }
    )
  }

  editarEscalamiento() {
    this.escalamientoForm = this.fb.group({
      'nivel_escala': new FormControl('', [Validators.required]),
      'fecha_vencimiento': new FormControl('', [Validators.required])
    })
  }
}

