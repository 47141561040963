import { Component, OnInit } from '@angular/core';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { Proyecto, Solicitud } from 'src/app/interfaces/reportes.interface';
import { Router } from '@angular/router';
import { MimeType } from 'src/app/services/mimeType.service';
import { MessageService, LazyLoadEvent } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { ArchivoService } from 'src/app/services/archivo.service';
import * as fileSaver from 'file-saver';
import { ReportesService } from 'src/app/services/reportes.service';
import { lastValueFrom } from 'rxjs';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HomePathService } from 'src/app/services/home-path.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  // Catalogos
  catEstadoProyecto: Catalogo[] = [];
  slcTipo: string;
  slcTipos: Catalogo[];


  // Variables
  verSolicitudes: boolean;
  numId: string;

  // Servicio
  datosProyectos!: Proyecto[];
  datosSolicitud!: Solicitud[];

  // Tabla
  bandeja: Proyecto[];
  solicitudes: Solicitud[] = [
    {
      idSolicitud: 0, nombreSolicitud: '', fechaLimite: new Date, descripcion: '', usuarioCreador: '', fechaCreacion: new Date, numFolio: '',
      fechaModificacion: new Date, comentarioCierre: '', estado: '', indSemaforo: 0
    }
  ];

  numProyecto: number;
  nombreProyecto: string;
  descProyecto: string;
  usuarioAsignacion: string;
  tipoProy: string;
  cveEstadoProy: string;
  cveClasif: string;
  usuarioSolicitante: string;
  fechaSolicitud: String;
  fechaVencimiento: String;
  indSemaforo: number;

  // Excel
  loading: boolean;
  listaReportes: string[] = [];
  homePath: string;

  formGroup!: FormGroup;

  // Busqueda
  totalProyectos: number;
  totalElements: number;
  backup: string | null;



  constructor(
    private router: Router,
    private homePathService: HomePathService,
    private mimeType: MimeType,
    private catalogoService: CatalogoService,

    private reporteService: ReportesService,
    private messageService: MessageService,

    private loaderService: LoaderService,
    private archivoService: ArchivoService,
    private reportesService: ReportesService,
    private usuarioSesionService: UsuarioSesionService,
    private bitacoraPSService: BitacoraProyectoSolService
  ) {
    this.initFormGroup();
  }

  ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.catalogoService.getCatalogosNemonico('EDPRO', true).subscribe({
      next: (data: any) => this.catEstadoProyecto = data,
      error: (error) => console.log('ERROR: ', error),
      complete: () => console.log('COMPLETE')
    });
  }



  async routeDetalleProyecto(id: string) {
    this.verSolicitudes = true;
    this.numId = id;

    if (!this.datosSolicitud) {
      this.datosSolicitud = {} as Solicitud[];
    }
    const datosSol = this.reportesService.obtenerSolicitud(id);
    this.datosSolicitud = await lastValueFrom(datosSol);
    console.log('* Datos solicitud: ', this.datosSolicitud);

    this.solicitudes = this.datosSolicitud;
  }

  consultar() {
    var cveProyecto = '1';
    console.log('Se genera reporte de excel: PROYECTO: ', cveProyecto);
    this.reporteService.generaReporte(cveProyecto).subscribe({
      next: (data: any) => {
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Reporte generado', life: 6000 });
        this.lista();
      },
      error: error => {
        console.error('Error--->', error);
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Reporte generado', life: 6000 });
        this.lista();
      }
    });
  }

  getFileExtension3(filename: any) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  lista() {
    this.loading = true;
    this.listaReportes = [];
    this.reporteService.getListaReportes(this.usuarioSesionService.usuario.idUsuario).subscribe({
      next: (v: string[]) => {
        this.listaReportes = v.map(rep => rep.split('/')[2]);
      },
      error: (e) => {
        console.error('ERROR: ', e);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo obtener la lista de reportes.', life: 6000 });
      },
      complete: () => console.log('Complete')
    });
  }

  async descargaReporte(reporte: string) {
    const pathExternal = 'REPORTE/' + this.usuarioSesionService.usuario.idUsuario;
    const pathInternal = 'REPORTE-' + this.usuarioSesionService.usuario.idUsuario;
    this.loaderService.isLoading.next(true);
    if (this.homePath.includes('-internal')) {
      const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(reporte));
      this.archivoService.downloadFile(reporte, pathInternal).subscribe({
        next: file => {
          const data: Blob = new Blob([file], { type: mime });
          fileSaver.saveAs(data, reporte);
          this.loaderService.isLoading.next(false);
          this.saveBitacora(reporte, data);
        },
        error: error => {
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Reporte no descargado.', life: 6000 });
        }
      });
    } else {
      this.archivoService.downloadExternal(pathExternal, reporte);
    }
  }

  saveBitacora(reporte: string, file: any) {
    const bitacoraSubida = {
      cveBitacora: null,
      descAccion: 'Descarga archivo, nombre: ' + reporte + ' peso ' + file.size + ' bytes ',
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: null,
      tipoMovimiento: {
        id: 'TIPMO-6',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-3',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
  }
  // Busqueda
  loadLazy(event?: LazyLoadEvent) {
    let filter = null;
    let rows = 0;
    if (event && event.filters && event.filters.global && event.filters.global.value) {
      filter = event.filters.global.value;
    }
    let page = 0;
    if (event && event.first && event.rows) {
      page = event.first / event.rows;
    }
    if (this.backup !== null && this.backup !== filter) {
      page = 0;
    }
    if (event && event.rows) {
      rows = event.rows;
    }
    else {
      rows = 10;
    }

    // Estatus a buscar
    let estatus: Catalogo[] = this.formGroup.value['slcTipos'];

    let busqueda = estatus ? estatus.map(a => a.id).join(',') : null;

    this.reportesService.obtenerProyectos(page, rows, filter, busqueda).subscribe({
      next: (data: any) => {
        this.bandeja = data.content;
        this.totalProyectos = data.totalElements;
        this.backup = filter;
      },
      error: (e) => {
        console.error('ERROR: ', e);
      },
      complete: () => console.log('Complete')
    });
  }

  initFormGroup() {
    this.formGroup = new FormGroup({
      'slcTipos': new FormControl('', Validators.required)
    });
  }

  routeDetalleSolicitud(idSolicitud: string, idProyecto: string) {
    this.router.navigate([this.homePath + '/proyecto/detalleSolicitud', idSolicitud, idProyecto]);
  }
}
