import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { HomeComponent } from './components/shared/template/home/home.component';
import { CatalogosComponent } from './components/pages/admin/catalogos/catalogos.component';
import { ProyectoComponent } from './components/pages/proyecto/proyecto.component';
import { UsuariosComponent } from './components/pages/admin/usuarios/usuarios.component';
import { RolesComponent } from './components/pages/admin/roles/roles.component';
import { SolicitudComponent } from './components/pages/solicitud/solicitud.component';
import { BandejaComponent } from './components/pages/bandeja/bandeja.component';
import { RespuestaSolicitudComponent } from './components/pages/respuesta-solicitud/respuesta-solicitud.component';
import { DatosProyectoComponent } from './components/pages/proyecto/steps/datos-proyecto/datos-proyecto.component';
import { OwnersComponent } from './components/pages/proyecto/steps/owners/owners.component';
import { ResponsablesComponent } from './components/pages/proyecto/steps/responsables/responsables.component';
import { ConfirmacionComponent } from './components/pages/proyecto/steps/confirmacion/confirmacion.component';
import { GestionComponent } from './components/pages/proyecto/gestion/gestion.component';
import { DatosSolicitudComponent } from './components/pages/solicitud/steps/datos-solicitud/datos-solicitud.component';
import { ChecklistComponent } from './components/pages/solicitud/steps/checklist/checklist.component';
import { PersonaSolicitudComponent } from './components/pages/solicitud/steps/persona-solicitud/persona-solicitud.component';
import { DocumentosSolicitudComponent } from './components/pages/solicitud/steps/documentos-solicitud/documentos-solicitud.component';
import { ConfirmacionSolicitudComponent } from './components/pages/solicitud/steps/confirmacion-solicitud/confirmacion-solicitud.component';
import { DetalleSolicitudComponent } from './components/pages/detalle-solicitud/detalle-solicitud.component';
import { InicioComponent } from './components/pages/inicio/inicio.component';
import { BandejaSolicitudComponent } from './components/pages/bandeja_solicitud/bandeja-solicitud/bandeja-solicitud.component';
import { MatrizEscalamientoComponent } from './components/pages/matriz-escalamiento/matriz-escalamiento.component';
import { DocumentosAdjuntosComponent } from './components/pages/proyecto/steps/adjuntos/documentos-adjuntos.component';
import { LoginInternalComponent } from './components/shared/login-internal/login.component';
import { AuthTokenGuard } from './guards/authToken.guard';
import { AccessDeniedComponent } from './components/shared/access-denied/access-denied.component';
import { BrowserErrorComponent } from './components/shared/browser-error/browser-error.component';
import { LoginComponent } from './components/shared/login/login.component';
import { NewPasswordComponent } from './components/shared/new-password/new-password.component';
import { PasswordOlvidadoPaso1Component, PasswordOlvidadoPaso2Component } from './components/shared/password-olvidado/password-olvidado.component';
import { ReenviarCodigoComponent } from './components/shared/reenviar-codigo/reenviar-codigo.component';
import { AuthGuard } from './guards/auth.guard';
import { SessionExpComponent } from './components/shared/session-exp/session-exp.component';
import { LogoutComponent } from './components/shared/logout/logout.component';
import { LogoutInternalComponent } from './components/shared/logout-internal/logout.component';
import { GestionChecklistComponent } from './components/pages/admin/gestion-checklist/gestion-checklist.component';
import { ReportesComponent } from './components/pages/reportes/reportes.component';
import { BuscarComponent } from './components/pages/buscar/buscar.component';
import { MfaConfiguracionComponent } from './components/pages/mfa-configuracion/mfa-configuracion.component';

const LOGINROUTES: Routes = [
  {
    path: '',
    redirectTo: 'ediscovery',
    pathMatch: 'full'
  },
  {
    path: 'ediscovery',
    component: LoginComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'newPass', component: NewPasswordComponent },
      { path: 'forgotPassword/:user', component: PasswordOlvidadoPaso2Component },
      { path: 'forgotPassword', component: PasswordOlvidadoPaso1Component },
      { path: 'resend', component: ReenviarCodigoComponent },
    ]
  },
  {
    path: 'ediscovery-new-pass',
    component: NewPasswordComponent,
    children: [
      { path: 'newPass', component: NewPasswordComponent }
    ]
  },
  {
    path: 'ediscovery-forgotPassword/:user',
    component: PasswordOlvidadoPaso2Component,
    children: [
      { path: 'forgotPassword/:user', component: PasswordOlvidadoPaso2Component }
    ]
  },
  {
    path: 'ediscovery-forgotPassword',
    component: PasswordOlvidadoPaso1Component,
    children: [
      { path: 'forgotPassword', component: PasswordOlvidadoPaso1Component }
    ]
  },
  {
    path: 'ediscovery-resend',
    component: ReenviarCodigoComponent,
    children: [
      { path: 'resend', component: ReenviarCodigoComponent }
    ]
  },
  {
    path: 'ediscovery-mfa',
    component: MfaConfiguracionComponent,
    children: [
      { path: 'iniMfa', component: MfaConfiguracionComponent }
    ]
  }

];

const HOMEROUTES: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'logout', component: LogoutComponent },
      { path: 'inicio', component: InicioComponent },
      { path: 'buscar/:numSolicitud', component: BuscarComponent }
    ]

  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];

const PROYECTO_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/proyecto',
    pathMatch: 'full'
  },
  {
    path: 'home/proyecto',
    component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {
        path: 'proyecto', component: ProyectoComponent, children: [
          { path: '', redirectTo: 'datos', pathMatch: 'full' },
          { path: 'datos', component: DatosProyectoComponent },
          { path: 'owners', component: OwnersComponent },
          { path: 'responsables', component: ResponsablesComponent },
          { path: 'adjuntos', component: DocumentosAdjuntosComponent },
          { path: 'confirmacion', component: ConfirmacionComponent }
        ]
      },
      { path: 'gestionProyecto/:entrada', component: GestionComponent },
      {
        path: 'solicitud', component: SolicitudComponent, children: [
          { path: '', redirectTo: 'datos', pathMatch: 'full' },
          { path: 'datos', component: DatosSolicitudComponent },
          { path: 'checklist', component: ChecklistComponent },
          { path: 'solicitar', component: PersonaSolicitudComponent },
          { path: 'documentos', component: DocumentosSolicitudComponent },
          { path: 'confirmacion', component: ConfirmacionSolicitudComponent }
        ]
      },
      { path: 'inicio', component: InicioComponent },
      { path: 'bandeja/:tipo', component: BandejaComponent },
      { path: 'gestion/:entrada', component: GestionComponent },
      { path: 'respuestaSolicitud/:id', component: RespuestaSolicitudComponent },
      { path: 'detalleSolicitud/:id/:idProyecto', component: DetalleSolicitudComponent },
      { path: 'bandejaSolicitud/:tipo', component: BandejaSolicitudComponent },
      { path: 'reportes', component: ReportesComponent }
    ]
  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];


const ADMINISTRADOR_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full'
  },
  {
    path: 'home/admin',
    component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'catalogos', component: CatalogosComponent },
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'gestion/checklist', component: GestionChecklistComponent }
    ]

  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];

const MATRIZ_ESCALAMIENTO_ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/matriz',
    pathMatch: 'full'
  },
  {
    path: 'home/matriz',
    component: HomeComponent, canActivate: [AuthGuard],
    children: [
      { path: 'escalamiento', component: MatrizEscalamientoComponent }
    ]

  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];

/***Internal */
const LOGINROUTES_INTERNAL: Routes = [
  {
    path: '',
    redirectTo: 'ediscovery-internal',
    pathMatch: 'full'
  },
  {
    path: 'ediscovery-internal',
    component: LoginInternalComponent,
    children: [
      { path: 'login', component: LoginInternalComponent }
    ]
  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  }
];

const HOMEROUTES_INTERNAL: Routes = [
  {
    path: '',
    redirectTo: '/home-internal',
    pathMatch: 'full'
  },
  {
    path: 'home-internal',
    component: HomeComponent, canActivate: [AuthTokenGuard],
    children: [
      { path: 'logout', component: LogoutInternalComponent },
      { path: 'inicio', component: InicioComponent },
      { path: 'buscar/:numSolicitud', component: BuscarComponent }
    ]

  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];


const ADMINISTRADOR_ROUTES_INTERNAL: Routes = [
  {
    path: '',
    redirectTo: '/admin',
    pathMatch: 'full'
  },
  {
    path: 'home-internal/admin',
    component: HomeComponent, canActivate: [AuthTokenGuard],
    children: [
      { path: 'catalogos', component: CatalogosComponent },
      { path: 'usuarios', component: UsuariosComponent },
      { path: 'roles', component: RolesComponent },
      { path: 'gestion/checklist', component: GestionChecklistComponent }
    ]

  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];

const PROYECTO_ROUTES_INTERNAL: Routes = [
  {
    path: '',
    redirectTo: '/proyecto',
    pathMatch: 'full'
  },
  {
    path: 'home-internal/proyecto',
    component: HomeComponent, canActivate: [AuthTokenGuard],
    children: [
      {
        path: 'proyecto', component: ProyectoComponent, children: [
          { path: '', redirectTo: 'datos', pathMatch: 'full' },
          { path: 'datos', component: DatosProyectoComponent },
          { path: 'owners', component: OwnersComponent },
          { path: 'responsables', component: ResponsablesComponent },
          { path: 'adjuntos', component: DocumentosAdjuntosComponent },
          { path: 'confirmacion', component: ConfirmacionComponent }

        ]

      },
      { path: 'gestionProyecto/:entrada', component: GestionComponent },
      {
        path: 'solicitud', component: SolicitudComponent, children: [
          { path: '', redirectTo: 'datos', pathMatch: 'full' },
          { path: 'datos', component: DatosSolicitudComponent },
          { path: 'checklist', component: ChecklistComponent },
          { path: 'solicitar', component: PersonaSolicitudComponent },
          { path: 'documentos', component: DocumentosSolicitudComponent },
          { path: 'confirmacion', component: ConfirmacionSolicitudComponent }


        ]
      },
      { path: 'inicio', component: InicioComponent },
      { path: 'bandeja/:tipo', component: BandejaComponent },
      { path: 'gestion/:entrada', component: GestionComponent },
      { path: 'respuestaSolicitud/:id', component: RespuestaSolicitudComponent },
      { path: 'detalleSolicitud/:id/:idProyecto', component: DetalleSolicitudComponent },
      { path: 'bandejaSolicitud/:tipo', component: BandejaSolicitudComponent },
      { path: 'reportes', component: ReportesComponent }
    ]
  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];


const MATRIZ_ESCALAMIENTO_ROUTES_INTERNAL: Routes = [
  {
    path: '',
    redirectTo: '/matriz',
    pathMatch: 'full'
  },
  {
    path: 'home-internal/matriz',
    component: HomeComponent, canActivate: [AuthTokenGuard],
    children: [
      { path: 'escalamiento', component: MatrizEscalamientoComponent }
    ]
  },
  {
    path: 'acces-denied',
    component: AccessDeniedComponent
  },
  {
    path: 'browser-error',
    component: BrowserErrorComponent
  },
  {
    path: 'session-exp',
    component: SessionExpComponent
  }
];

const ROUTES: Routes = [
  { path: '', redirectTo: '/ediscovery/login', pathMatch: 'full' }, {
    path: '',
    children: [
      ...LOGINROUTES,
      ...HOMEROUTES,
      ...ADMINISTRADOR_ROUTES,
      ...PROYECTO_ROUTES,
      ...MATRIZ_ESCALAMIENTO_ROUTES,
      {
        path: '',
        component: LoginComponent
      }
    ],

  }, { path: 'login', component: LoginComponent }
];

const ROUTES_INTERNAL: Routes = [
  { path: '', redirectTo: '/ediscovery-internal/login', pathMatch: 'full' }, {
    path: '',
    children: [
      ...LOGINROUTES_INTERNAL,
      ...HOMEROUTES_INTERNAL,
      ...ADMINISTRADOR_ROUTES_INTERNAL,
      ...PROYECTO_ROUTES_INTERNAL,
      ...MATRIZ_ESCALAMIENTO_ROUTES_INTERNAL,
      {
        path: '',
        component: LoginComponent
      }
    ],

  }, { path: 'login', component: LoginInternalComponent }
];

let ROUTES_APP: Routes = [];

if (environment.ROUTES.includes('INTERNAL')) {
  ROUTES_APP = ROUTES_INTERNAL;
} else {
  ROUTES_APP = ROUTES;
}

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES_APP, { useHash: true }),
    RouterModule.forRoot(ROUTES_APP, { onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }