import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { TcAreasUsuario } from 'src/app/interfaces/tcAreasUsuario';
import { UsuarioService } from './usuario.service';
import { TcAreasUsuarioPk } from 'src/app/interfaces/tcAreasUsuarioPk.interface';
import { CatalogoService } from '../catalogos/catalogo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { Rol } from 'src/app/interfaces/rol.interface';
import { City } from 'src/app/interfaces/mfa-info';
import { MfaService } from 'src/app/services/mfa.service';


@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent implements OnInit {

  usuarioForm!: FormGroup;
  altaJefe!: FormGroup;
  usuario: Usuario;
  editar!: boolean;
  paises!: Catalogo[];
  lobs: Catalogo[] = [];
  roles!: Rol[];
  tblUsuarios: Usuario[] = [];
  totalElements!: number;
  items!: MenuItem[];
  dlgBitacora!: boolean;
  dlgUsrRel!: boolean;
  usuarioSlc: any;
  bitacora!: any[];
  results: any;
  relacionarUsr!: any;
  usrrels!: any;
  proyectos!: any;
  checked: boolean = false;
  checked1: boolean = false;
  checked2: boolean = false;
  datosUsuario: boolean = false;
  addJefesUsuario: boolean = true;
  btnActualizar: boolean = false;

  jefes: Usuario[] = [];
  niveles: SelectItem[];
  selectedNivel: any;
  selectedRol!: string;

  displayAreas!: boolean;
  displayAltaJefes!: boolean;
  displayProyectos!: boolean;

  catalogoAreas: Catalogo[] = [];
  backup!: string;

  selectedJefe: Usuario;
  jefeSegundo: Usuario;
  jefeTercero: Usuario;
  selectedJefeBoolean!: boolean;

  phoneNumber: string;
  verifyCode: string;
  cities: City[];
  selectedCity: string;
  displayModalMFA: boolean = false;
  mfaLoader: boolean = false;
  editarMFA: boolean = false;
  errorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private usuarioService: UsuarioService,
    private catalogoService: CatalogoService,
    private messageService: MessageService,
    public usuarioSesionService: UsuarioSesionService,
    public rolModuloService: RolModuloService,
    private confirmationService: ConfirmationService,
    private mfaService: MfaService
  ) {
    this.iniciaform();
    this.formAltaJefe();
    this.niveles = [
      { label: 'Nivel 2', value: 2 },
      { label: 'Nivel 3', value: 3 }
    ];
    this.selectedJefeBoolean = false;
    this.selectedNivel = null;
  }

  async ngOnInit() {
    this.getUsuarios();
    this.getCatalogos();
    this.items = [
      {
        label: 'Actualizar número telefonico del MFA', icon: 'pi pi-edit', command: () => {
          this.showEditMFa(this.usuarioSlc);
        }
      }
    ];
  }

  iniciaform() {
    this.editar = false;
    this.usuarioForm = this.fb.group({
      'numReg': new FormControl('', [Validators.required, Validators.maxLength(18)]),
      'nombre': new FormControl('', Validators.required),
      'correo': new FormControl('', [Validators.email, Validators.required]),
      'lob': new FormControl('', Validators.required),
      'pais': new FormControl('', Validators.required),
      'perfil': new FormControl('', Validators.required),
      'externo': new FormControl(''),
      'status': new FormControl('')
    });
  }

  formAltaJefe() {
    this.altaJefe = this.fb.group({
      'num_reg_jefe': new FormControl('', [Validators.required, Validators.maxLength(18)]),
      'nombre_jefe': new FormControl('', Validators.required),
      'correo_jefe': new FormControl('', [Validators.email, Validators.required])
    });
  }
  async searchJefes(event: any) {
    this.results = await this.usuarioService.getJefes(event.query).toPromise();
  }

  seleccionarJefe(jefe: any) {
    this.selectedNivel;
    this.selectedJefe = jefe;
    this.selectedJefeBoolean = true;
  }

  clearJefe() {
    this.selectedJefe = {} as Usuario;
    this.selectedJefeBoolean = false;
  }

  openModalAltaJefe() {
    this.formAltaJefe();
    this.displayAltaJefes = true;
  }

  closeModalAltaJefe() {
    this.displayAltaJefes = false;
  }

  addJefeToUser() {
    const currentObj = this;
    this.selectedJefe.nivel = this.selectedNivel.label;
    var valor = this.selectedNivel.value;
    if (valor === 2) {
      this.jefeSegundo = this.selectedJefe;
    } else {
      this.jefeTercero = this.selectedJefe;
    }
    this.niveles = this.niveles.filter(function (value, index, arr) {
      return value.value !== valor;
    });
    this.jefes.push(this.selectedJefe);
    this.displayAltaJefes = false;
    this.selectedNivel = null;
    this.selectedJefe = {} as Usuario;

  }

  eliminarJefe(jefe: Usuario) {
    var valor = jefe.numReg;
    var item;
    if (jefe.nivel === 'Nivel 2') {
      item = { label: 'Nivel 2', value: 2 };
    } else {
      item = { label: 'Nivel 3', value: 3 };
    }
    this.niveles.push(item);
    this.jefes = this.jefes.filter(function (value, index, arr) {
      return value.numReg !== valor;
    });
  }

  getUsuarios() {
    this.usuarioService.getUsuarioPage(0, 10, 'none').toPromise().then(
      (response: any) => {
        this.tblUsuarios = response.content;
        this.totalElements = response.totalElements;
      }
    );
  }

  async loadLazy(event: LazyLoadEvent) {
    let filter = 'none';
    if (event.filters && event.filters['global'] && event.filters['global'].value) {
      filter = event.filters['global'].value;
    }
    let page = event.first! / event.rows!;
    if (this.backup !== null && this.backup !== filter) {
      page = 0;
    }
    this.usuarioService.getUsuarioPage(page, event.rows!, filter).subscribe(
      (response: any) => {
        this.tblUsuarios = response.content;
        this.totalElements = response.totalElements;
        this.backup = filter;
      },
      error => {
        console.error('Error--->', error);
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Registros no cargados.', life: 6000 });
      }
    );
  }

  getCatalogos() {
    this.catalogoService.getCatalogos().toPromise().then(
      (response: any) => {
        this.paises = response.pais;
        this.lobs = response.lob;
        this.roles = response.rol;
      }
    );
  }

  altaUsuario() {
    this.datosUsuario = true;
    this.iniciaform();
    this.catalogoAreas = [];
  }

  async onSubmit() {
    this.datosUsuario = true;
    this.getValueUsuario();

    var usuario = await this.usuarioService.getUsuario(this.usuario.numReg).toPromise();
    if ((usuario !== null && this.btnActualizar) || (usuario === null && !this.btnActualizar)) {
      this.usuarioService.guardarActualizarUsuario(this.usuario).subscribe(
        (usuario: any) => {
          if (this.btnActualizar) {
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Usuario actualizado', life: 3000 });
          } else {
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Usuario agregado', life: 3000 });
          }
          this.datosUsuario = false;
          this.getUsuarios();
          this.cancelarOperacion();
          this.addJefesUsuario = true;
          this.usuario as {};
          this.jefeSegundo as {};
          this.jefeTercero as {};
        }
      );
    } else if (usuario !== null && !this.btnActualizar) {
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'No. de Registro, ya existe', life: 6000 });
    }
  }


  async getCatalogAreas() {
    this.catalogoAreas = await this.catalogoService.getCatalogoAreas().toPromise() || [];
  }

  async showProyectos() {
    this.displayProyectos = true;
  }

  async editarUsuario(numReg: string) {
    window.scroll({ top: 100, left: 100, behavior: 'smooth' });
    this.btnActualizar = true;
    this.datosUsuario = true;
    this.selectedNivel = null;
    this.clearJefe();

    await this.usuarioService.getUsuario(numReg).toPromise().then(
      (user: any) => {
        this.usuario = user;
        this.setValueUsuario(this.usuario);
        this.usuario.jefeSegundo.nivel = 'Nivel 2';
        this.usuario.jefeTercero.nivel = 'Nivel 3';
        this.jefes = [];
        this.niveles = [];
        if (this.usuario.jefeSegundo.numReg) {
          this.jefes.push(this.usuario.jefeSegundo);
        } else {
          this.niveles.push({ label: 'Nivel 2', value: 2 });
        }
        if (this.usuario.jefeTercero.numReg) {
          this.jefes.push(this.usuario.jefeTercero);
        } else {
          this.niveles.push({ label: 'Nivel 3', value: 2 });
        }
      }
    );

    if (this.usuario.jefeSegundo.numReg !== null || this.usuario.jefeTercero.numReg !== null) {
      this.addJefesUsuario = false;
    } else {
      this.addJefesUsuario = true;
    }
  }

  cancelarOperacion() {
    this.usuario = {} as Usuario;
    this.jefes = [];
    this.niveles = [
      { label: 'Nivel 2', value: 2 },
      { label: 'Nivel 3', value: 3 }
    ];
    this.selectedNivel = null;
    this.usuarioForm.get(['numReg'])?.setValue('');
    this.usuarioForm.get(['nombre'])?.setValue('');
    this.usuarioForm.get(['correo'])?.setValue('');
    this.usuarioForm.get(['lob'])?.setValue('');
    this.usuarioForm.get(['pais'])?.setValue('');
    this.usuarioForm.get(['perfil'])?.setValue('');
    //this.usuarioForm.get(['proyectos'])?.setValue(user.proyectos);
    this.usuarioForm.get(['num_reg2'])?.setValue('');
    this.usuarioForm.get(['nombre2'])?.setValue('');
    this.usuarioForm.get(['correo2'])?.setValue('');
    this.usuarioForm.get(['num_reg3'])?.setValue('');
    this.usuarioForm.get(['nombre3'])?.setValue('');
    this.usuarioForm.get(['correo3'])?.setValue('');
    this.usuarioForm.reset();
    this.btnActualizar = false;
    this.addJefesUsuario = true;
    this.datosUsuario = false;
  }



  getValueUsuario() {
    this.usuario = {} as Usuario;
    this.usuario.lob = {} as Catalogo;
    this.usuario.pais = {} as Catalogo;
    this.usuario.jefeSegundo = {} as Usuario;
    this.usuario.jefeTercero = {} as Usuario;
    this.usuario.numReg = this.usuarioForm.controls['numReg'].value;
    this.usuario.nombre = this.usuarioForm.controls['nombre'].value;
    this.usuario.correo = this.usuarioForm.controls['correo'].value;
    this.usuario.lob = this.usuarioForm.controls['lob'].value;
    this.usuario.pais = this.usuarioForm.controls['pais'].value;
    this.usuario.rol = this.usuarioForm.controls['perfil'].value;
    this.usuario.externo = this.usuarioForm.controls['externo'].value === '' ? false : this.usuarioForm.controls['externo'].value;
    this.usuario.status = this.usuarioForm.controls['status'].value === '' ? false : this.usuarioForm.controls['status'].value;

    this.jefes.forEach(
      (c: Usuario) => {
        if (c.nivel === 'Nivel 2') {
          this.usuario.jefeSegundo = c;
        } else {
          this.usuario.jefeTercero = c;
        }
      }
    );
    // this.usuario.jefeSegundo = this.jefeSegundo || null;
    // this.usuario.jefeTercero = this.jefeTercero || null;

    this.usuario.areas = [];
    this.catalogoAreas.forEach(
      (c: Catalogo) => {
        var tcAreasUsuarioDTO = {} as TcAreasUsuario;
        tcAreasUsuarioDTO.tcAreasUsuarioPK = {} as TcAreasUsuarioPk;
        tcAreasUsuarioDTO.tcAreasUsuarioPK.area = {} as Catalogo;
        tcAreasUsuarioDTO.tcAreasUsuarioPK.numReg = {} as Usuario;
        if (c.seleccionar === true) {
          //tcAreasUsuarioPKDTO.area = c;
          tcAreasUsuarioDTO.tcAreasUsuarioPK.area = c;
          tcAreasUsuarioDTO.tcAreasUsuarioPK.numReg.numReg = this.usuario.numReg;
          this.usuario.areas.push(tcAreasUsuarioDTO);
        }
      }
    );
  }

  async showAreas() {
    this.displayAreas = true;
    if (!this.btnActualizar) {
      this.catalogoAreas = [];
      await this.getCatalogAreas();
    }
  }

  async setValueUsuario(user: Usuario) {
    const current = this;
    this.usuarioForm.get(['numReg'])?.setValue(user.numReg);
    this.usuarioForm.get(['nombre'])?.setValue(user.nombre);
    this.usuarioForm.get(['correo'])?.setValue(user.correo);
    this.usuarioForm.get(['lob'])?.setValue(user.lob);
    this.usuarioForm.get(['pais'])?.setValue(user.pais);
    this.usuarioForm.get(['perfil'])?.setValue(user.rol);
    this.usuarioForm.get(['externo'])?.setValue(user.externo);
    this.usuarioForm.get(['status'])?.setValue(user.status);
    this.usuarioForm.get(['num_reg2'])?.setValue(user.jefeSegundo.numReg);
    this.usuarioForm.get(['nombre2'])?.setValue(user.jefeSegundo.nombre);
    this.usuarioForm.get(['correo2'])?.setValue(user.jefeSegundo.correo);
    this.usuarioForm.get(['num_reg3'])?.setValue(user.jefeTercero.numReg);
    this.usuarioForm.get(['nombre3'])?.setValue(user.jefeTercero.nombre);
    this.usuarioForm.get(['correo3'])?.setValue(user.jefeTercero.correo);
    await this.getCatalogAreas();
    this.usuario.areas.forEach(a => {
      const element = (element) => element.id === a.tcAreasUsuarioPK.area.id;
      const index = this.catalogoAreas.findIndex(element);
      this.catalogoAreas[index].seleccionar = true;
    });
  }

  hideDlgBitacora() {
    this.dlgBitacora = false;
  }



  async eliminarUsuario(usuario: Usuario) {
    if (this.usuarioSesionService.usuario.idUsuario === usuario.numReg) {
      this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'No se permite eliminar usuario logeado', life: 6000 });
    } else {
      this.confirmationService.confirm({
        header: '¡Atención!',
        message: '¿Estás seguro de eliminar el usuario: ' + usuario.nombre + '?',
        acceptLabel: 'Continuar',
        rejectLabel: 'Cancelar',
        accept: async () => {
          this.usuarioService.deleteUsuario(usuario.numReg).toPromise()
            .then((resp: any) => {
              this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Usuario eliminado', life: 3000 });
              this.getUsuarios();
              this.iniciaform();
              // this.datosUsuario = false;
              this.addJefesUsuario = true;
              this.usuario = {} as Usuario;
              this.btnActualizar = false;
            })
            .catch(
              () => {
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Operación no permitida', life: 6000 });
              }
            );
        }, reject: () => {
          this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
        }
      });
    }
  }

  async bitacoraUsuario(usuario: any) {
    await this.usuarioService.bitacoraUsuario(usuario).toPromise().then(
      (data: any) => {
        this.dlgBitacora = true;
        this.bitacora = data;
      }
    )
  }

  async bitacoraGeneralUsuario() {
    await this.usuarioService.bitacora().toPromise().then(
      (data: any) => {
        this.dlgBitacora = true;
        this.bitacora = data;
      }
    )
  }

  async enabledUser(usuario: Usuario) {
    this.confirmationService.confirm({
      message: 'Estas seguro de cambiar el número telefonico de este usuario?',
      accept: async () => {
      }
    });

  }

  setUserSlc(usuario: Usuario) {
    this.usuarioSlc = usuario;
  }

  async showEditMFa(usuario: Usuario) {
    this.selectedCity = '';
    this.phoneNumber = '';
    this.cities = [
      { name: 'México +52', code: '+52' },
      { name: 'Argentina +54', code: '+54' }
    ];
    this.displayModalMFA = true;

    await this.mfaService.getMfaOptions(usuario.numReg).subscribe(res => {
      let phoneVerified = false;
      let phoneMfa: string = '';

      const userAttributes = res.userAttributes;
      userAttributes.forEach(attr => {
        if (attr.name === 'phone_number') {
          phoneMfa = attr.value.substring(0, 3);
          this.selectedCity = "" + phoneMfa;
          this.phoneNumber = attr.value.substring(3, attr.value.length);
        }
        if (attr.name === 'phone_number_verified' && attr.value === 'true') {
          phoneVerified = true;
        }
      });
    });

  }

  editarCampos() {
    this.editarMFA = true;
  }

  cancelarEditMfa() {
    this.displayModalMFA = false;
    this.editarMFA = false;

    this.selectedCity = '';
    this.phoneNumber = '';
  }

  /**
  * Esta funcion permite obtener el codigo del pais y el numero de telefono para poder enviarlo a cognito y desencadenar la verificacion del numero telefonico
  * @param code Codigo del país +52 para México, +54 para argentina 
  * @param phone numero de telefono
  * @returns void
  */
  async validarTelefono(code: string, phone: string) {

    if (code == null || phone == null || phone.length == 0) {
      this.errorMessage = 'Todos los campos son obligatorios';
      return;
    } else {
      this.errorMessage = '';
      const number = code.concat(phone);

      this.confirmationService.confirm({
        message: '¿El número de teléfono <b>' + phone + '</b> es correcto?',
        header: 'Advertencia',
        icon: 'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptLabel: 'Aceptar',
        accept: async () => {

          const usuarioCognito = {
            userID: this.usuarioSlc.numReg,
            name: '',
            email: '',
            phone: number
          };
          await this.mfaService.updateMfaOptions(usuarioCognito).subscribe(
            (response: any) => {
              this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Número telefonico actualizado.', life: 6000 });
              this.displayModalMFA = false;
              this.editarMFA = false;

              this.selectedCity = '';
              this.phoneNumber = '';
            }
          );

        }
      });



    }

  }

}
