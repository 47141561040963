import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { api } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Usuario } from '../interfaces/usuario_session.interface';
import { PerfilFuncion } from '../interfaces/perfiles.interface';
import { PerfilMod } from '../interfaces/perfil_mod.interface';

@Injectable({
  providedIn: 'root'
})
export class PerfilesService {

  URL = `${api.urlAPI}`;

  constructor(private http: HttpClient) { }

  getPerfilesById(cvePerfil: number) {
    return this.http.get(this.URL + 'api/admin/perfiles/' + cvePerfil);
  }

  updatePerfiles(listaPerfiles: PerfilFuncion[]) {
    return this.http.put(this.URL + '/perfiles/', listaPerfiles);
  }

  getPerfilUsuario(idUsuario: string): Observable<Usuario> {
    return this.http.get<Usuario>(this.URL + 'api/sesion/usuario/' + idUsuario);
  }

  getModulosByPerfil(idPerfil: string): Observable<PerfilMod[]> {
    return this.http.get<PerfilMod[]>(this.URL + 'api/admin/perfil/' + idPerfil);
  }

  saveModulos(modulos: PerfilMod[]) {
    return this.http.put(this.URL + 'api/admin/perfil_modulos/', modulos);
  }

}
