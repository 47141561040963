import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-documentos-solicitud',
  templateUrl: './documentos-adjuntos.component.html'
})
export class DocumentosAdjuntosComponent implements OnInit {

  archivos: any[] = [];
  archivosInformation: Archivo[] = [];
  homePath: string;



  constructor(private router: Router,
    private messageService: MessageService,
    public proyectoInformacion: ProyectoInformacionService,
    private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService) { }

  ngOnInit(): void {
    this.homePath = this.homePathService.getHomePath();
    this.archivosInformation = this.proyectoInformacion.datosProyecto.archivosProyecto;

    if (this.archivosInformation != null) {
      this.archivosInformation.forEach(arch => {
        const archivo: any = {
          nombre: arch.nombre,
          file: arch.documento,
        }
        this.archivos.push(archivo);
      });
    } else {
      this.archivosInformation = [];
    }

  }

  myUploader(event, formUpload): void {

    if (event.files.length !== 0) {
      for (let file of event.files) {
        const archivo = {
          nombre: file.name,
          file: file
        };

        if (this.archivos.length > 0) {
          let existeTemp = this.archivos.find(arch => arch.nombre === archivo.nombre);
          let existeGlobal = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);

          if (existeTemp == null && existeGlobal == null) {
            this.archivos.push(archivo);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos cargados.' });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya fue agregado un documento con el mismo nombre', life: 6000 });
          }

        } else {
          this.archivos.push(archivo);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos cargados.' });
        }


        formUpload.clear();

      }


      /*    this.archivos.forEach( x => {
           console.log("nombre archivos: " + x.nombre);
           console.log("tamaño: " + x.file.size);
          })  */


    }
  }

  eliminaArch(arch: any) {
    this.archivos = this.archivos.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });

    this.archivosInformation = this.archivosInformation.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }


  prevPage() {
    // console.log("tamaño docmuentos: " + this.archivos.length)
    if (this.archivos.length > 0) {

      this.archivos.forEach(arch => {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: arch.file,
          fechaCreacion: null,
          nombre: arch.nombre,
          origenDoc: 'ORDOC-1',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: '0'
        }
        let existe = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);
        if (existe == null) {
          this.archivosInformation.push(archivo);
        }

      })


      this.proyectoInformacion.datosProyecto.archivosProyecto = this.archivosInformation;
      this.router.navigate([this.homePath + '/proyecto/proyecto/responsables']);

    } else {

      this.proyectoInformacion.datosProyecto.archivosProyecto = []
      this.router.navigate([this.homePath + '/proyecto/proyecto/responsables']);

    }


  }

  nextPage() {

    if (this.archivos.length > 0) {

      this.archivos.forEach(arch => {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: arch.file,
          fechaCreacion: null,
          nombre: arch.nombre,
          origenDoc: 'ORDOC-1',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: '0'
        }
        let existe = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);
        if (existe == null) {
          this.archivosInformation.push(archivo);
        }
      })

      this.proyectoInformacion.datosProyecto.archivosProyecto = this.archivosInformation;
      this.router.navigate([this.homePath + '/proyecto/proyecto/confirmacion'])

    } else {

      this.proyectoInformacion.datosProyecto.archivosProyecto = []
      this.router.navigate([this.homePath + '/proyecto/proyecto/confirmacion'])
    }
  }


}
