import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { api } from 'src/environments/environment';
import { MatrizEscalamiento } from '../interfaces/matrizEscalamiento.interface';

@Injectable({
  providedIn: 'root'
})
export class MatrizService {

  private urlEndPoint: string = `${api.urlAPI}` + 'api/escalamiento/designados/';

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  getEscalamientoPage(page: number, size: number, filters: string): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + 'page/' + page + '/' + size + '/' + filters);
  }

  getEscalamiento(search: string): Observable<MatrizEscalamiento[]> {
    return this.http.get<MatrizEscalamiento[]>(this.urlEndPoint + '/' + search);
  }

  getEscalamientos(): Observable<MatrizEscalamiento[]> {
    return this.http.get<MatrizEscalamiento[]>(this.urlEndPoint.concat('escalados/'));
  }

  delEscalamiento(escalamiento: MatrizEscalamiento) {
    return this.http.delete(this.urlEndPoint + 'eliminarEscalamiento/' + escalamiento);
  }

  updtEscalamiento(escalamiento: any): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + 'actualizarEscalado/', escalamiento, { headers: this.httpHeaders });
  }
}
