import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HomePathService } from 'src/app/services/home-path.service';
import { Callback, CognitoUtil, LoggedInCallback } from '../services/cognito.service';
import { UserLoginService } from '../services/user-login.service';

export class Stuff {
    public accessToken: string;
    public idToken: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor, LoggedInCallback {

    public stuff: Stuff = new Stuff();

    constructor(private router: Router,
        private homePathService: HomePathService,
        public cognitoUtil: CognitoUtil,
        public userService: UserLoginService) {
        if (!this.homePathService.getHomePath().includes('-internal')) {
            this.userService.isAuthenticated(this);
        }
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!req.url.includes('-internal') && !req.url.includes('.s3.') && !req.url.includes('cloud.hsbc') && !req.url.includes('upload/') && !req.url.includes('download/')) {

            const headers = new HttpHeaders({
                'Authorization': this.stuff.idToken,
                'tokenHeader': localStorage.getItem('tokenHeader')!
            });

            const reqClone = req.clone({ headers });

            return next.handle(reqClone).pipe(
                tap(
                    () => { },
                    (error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            if ((error.status === 403 || error.status === 401) && !req.url.includes('/login')) {
                                this.router.navigate(['/ediscovery/logout']);
                            } else {
                                this.router.navigate([this.homePathService.getHomePath() + '/error']);
                            }
                        }
                    }
                )
            );
        } else {
            return next.handle(req).pipe(
                tap(
                    () => { },
                    (error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            if (error.status === 403 && !req.url.includes('/adamLogin')) {
                                this.router.navigate(['/home-internal/logout']);
                            } else {
                                this.router.navigate([this.homePathService.getHomePath() + '/error']);
                            }
                        }
                    }
                )
            );
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (!isLoggedIn) {
            this.router.navigate(['/login']);
        } else {
            this.cognitoUtil.getAccessToken(new AccessTokenCallback(this));
            this.cognitoUtil.getIdToken(new IdTokenCallback(this));
        }
    }

    manejarError(e: HttpErrorResponse) {
        console.log(e);
        if (e.error) {
            // A client-side or network error occurred.
            console.log('An error occurred:', e.message);
        } else {
            // Backend returns unsuccessful response codes such as 404, 500 etc.
            console.log('Backend returned status code: ', e.status);
            console.log('Response body:', e.error);
        }
        return throwError(e);
    }
}

export class AccessTokenCallback implements Callback {
    constructor(public jwt: AuthTokenInterceptor) {

    }

    callback() {

    }

    callbackWithParam(result) {
        this.jwt.stuff.accessToken = result;
    }
}

export class IdTokenCallback implements Callback {
    constructor(public jwt: AuthTokenInterceptor) {

    }

    callback() {

    }

    callbackWithParam(result) {
        this.jwt.stuff.idToken = result;
    }
}
