import { Component, OnInit } from '@angular/core';
import { MessageService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { SearchUsuario } from 'src/app/interfaces/search_usuario.interface';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { lastValueFrom } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { HomePathService } from 'src/app/services/home-path.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';



@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html'
})
export class OwnersComponent implements OnInit {

  tpOwnerRel: SelectItem[] = [];
  tpOwnerRelSlc: any;
  ownersProyecto: SearchUsuario[] = [];
  results: any;
  relacionar: any;
  ownersInformation: OwnersOrResponsables[] = [];
  responsablesInformation: OwnersOrResponsables[] = [];
  tipoRol: string = 'TIPRO-1';
  ownerRegistro: any;
  numRegistroInput: any;
  homePath: string;
  perfilesNoPermitidosOwner: number[] = [];


  constructor(private router: Router,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private proyectoService: ProyectoService,
    public proyectoInformacionService: ProyectoInformacionService,
    private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService) {

  }

  ngOnInit(): void {
    // console.log("valor pais usuario details: " + this.usuarioSesionService.usuario.pais)
    this.homePath = this.homePathService.getHomePath();

    this.tpOwnerRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpOwnerRelSlc = 'nombre'



    this.perfilesNoPermitidosOwner.push(6);
    this.perfilesNoPermitidosOwner.push(3);
    this.perfilesNoPermitidosOwner.push(4);


    this.ownersInformation = this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto
    this.responsablesInformation = this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto

    if (this.responsablesInformation == null) {
      this.responsablesInformation = [];
    }

    if (this.ownersInformation != null) {
      this.ownersInformation.forEach(ow => {
        const owner: SearchUsuario = {
          numRegistro: ow.cveUsuario,
          nombre: ow.nombre,
          indNotificacion: ow.indNotificacion,
          correo: ow.correo,
          lob: ow.lob
        }
        this.ownersProyecto.push(owner);
      });
    } else {
      this.ownersInformation = [];
    }

  }

  async searchOwners(event: any) {
    // console.log("entrando a search " + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosOwner, this.usuarioSesionService.usuario.pais).subscribe(
      owners => this.results = owners
    );

  }




  async agregarOwner() {

    if (this.tpOwnerRelSlc === 'nombre') {
      if (this.relacionar != null && this.relacionar.numRegistro != null) {
        const ownerTemp = {
          numRegistro: this.relacionar.numRegistro,
          nombre: this.relacionar.nombre,
          indNotificacion: this.relacionar.indNotificacion,
          correo: this.relacionar.correo,
          lob: this.relacionar.lob
        }
        this.relacionar = null;

        let existeOwner = this.ownersProyecto.find(num => num.numRegistro === ownerTemp.numRegistro);
        let existeRepo = this.responsablesInformation.find(num => num.cveUsuario === ownerTemp.numRegistro)
        if (ownerTemp.numRegistro == this.usuarioSesionService.usuario.idUsuario) {
          // console.log("valor usuario es responsable");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario creador es seleccionado como responsable por default', life: 6000 });
        }
        else if (existeOwner != null || existeRepo != null) {
          //console.log("valor existeOwner es verdadero");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue seleccionado como owner o responsable', life: 6000 });
        } else {
          //console.log("agregamos al owner el array ya fue inicializado");
          this.ownersProyecto.push(ownerTemp);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'owner agregado.', life: 6000 });
        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un owner.', life: 6000 });
      }
    } else {
      if (this.numRegistroInput != null && this.numRegistroInput.length > 0) {
        this.loaderService.isLoading.next(true);

        let existe = this.ownersProyecto.find(num => num.numRegistro === this.numRegistroInput);
        let existeRepo = this.responsablesInformation.find(num => num.cveUsuario === this.numRegistroInput)
        if (this.numRegistroInput == this.usuarioSesionService.usuario.idUsuario) {
          //console.log("valor usuario es responsable");
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario creador es seleccionado como responsable por default', life: 6000 });
        }
        else if (existe != null || existeRepo != null) {
          // console.log("valor existeOwner es verdadero desde registro");
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue seleccionado como owner o responsable', life: 6000 });
        } else {
          const ownerRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInput, this.perfilesNoPermitidosOwner, this.usuarioSesionService.usuario.pais);
          this.ownerRegistro = await lastValueFrom(ownerRegistro$).catch((e) => {
            // console.log("cacheando error en component :")
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
          });

          //console.log("agregamos al owner el array ya fue inicializado estamos en modo registro num " + this.ownerRegistro.numRegistro);
          this.ownersProyecto.push(this.ownerRegistro);
          this.ownerRegistro = null;
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Owner agregado.', life: 6000 });

        }

      }
      else {
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el número de registro', life: 6000 });
      }
    }
  }

  eliminaOw(usuario: SearchUsuario) {
    this.ownersProyecto = this.ownersProyecto.filter(function (value, index) {
      // console.log("entro a eliminarlo en local");
      return value.numRegistro !== usuario.numRegistro;
    });

    this.ownersInformation = this.ownersInformation.filter(function (value, index) {
      //  console.log("entro a eliminarlo en global");
      return value.cveUsuario !== usuario.numRegistro
    });

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario eliminado.', life: 6000 });
  }

  prevPage() {
    if (this.ownersProyecto.length > 0) {

      this.ownersProyecto.forEach(ow => {
        const owner: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: ow.nombre,
          cveUsuario: ow.numRegistro,
          tipoRol: this.tipoRol,
          indNotificacion: ow.indNotificacion,
          correo: ow.correo,
          lob: ow.lob
        }
        let existe = this.ownersInformation.find(num => num.cveUsuario === owner.cveUsuario);
        if (existe != null) {
          //console.log("ya existia antes de regresar");
          this.ownersInformation.forEach(cam => {
            if (cam.cveUsuario == owner.cveUsuario) {
              if (cam.indNotificacion != owner.indNotificacion) {
                cam.indNotificacion = owner.indNotificacion
              }
            }
          })
        } else { this.ownersInformation.push(owner); }
      });
      this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto = this.ownersInformation;
      this.router.navigate([this.homePath + '/proyecto/proyecto/datos']);

    } else {
      this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto = [];
      this.router.navigate([this.homePath + '/proyecto/proyecto/datos']);
    }
  }

  nextPage() {
    if (this.ownersProyecto.length > 0) {

      this.ownersProyecto.forEach(ow => {
        const owner: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: ow.nombre,
          cveUsuario: ow.numRegistro,
          tipoRol: this.tipoRol,
          indNotificacion: ow.indNotificacion,
          correo: ow.correo,
          lob: ow.lob
        }
        let existe = this.ownersInformation.find(num => num.cveUsuario === owner.cveUsuario);
        if (existe != null) {
          // console.log("ya existia antes de regresar");
          this.ownersInformation.forEach(cam => {
            if (cam.cveUsuario == owner.cveUsuario) {
              if (cam.indNotificacion != owner.indNotificacion) {
                cam.indNotificacion = owner.indNotificacion
              }
            }
          })
        } else { this.ownersInformation.push(owner); }
      });
      this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto = this.ownersInformation;
      this.router.navigate([this.homePath + '/proyecto/proyecto/responsables'])

    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar al menos 1 owner para el proyecto', life: 6000 });
    }
  }
}


