import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { BandejaSolicitud } from 'src/app/interfaces/bandeja-solicitud.interface';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { BandejaService } from 'src/app/services/bandeja.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { HomePathService } from 'src/app/services/home-path.service';
import { LoaderService } from 'src/app/services/loader.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-bandeja-solicitud',
  templateUrl: './bandeja-solicitud.component.html'
})
export class BandejaSolicitudComponent implements OnInit {

  catEstadoSolicitud: Catalogo[] = [];
  bandeja: BandejaSolicitud[];
  tpBandeja: string;
  slcTipo: string;
  totalElements: number;
  homePath: string;
  tipo: SelectItem[] = [];



  constructor(private router: Router,
    private catalogoService: CatalogoService,
    private route: ActivatedRoute,
    private bandejaService: BandejaService,
    private messageService: MessageService,
    private homePathService: HomePathService,
    private loaderService: LoaderService,
    public usuarioSesionService: UsuarioSesionService) {
    this.route.params.subscribe(parametros => {
      this.tpBandeja = parametros['tipo'];
    });
  }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();

    this.catalogoService.getCatalogosNemonico('EDSOL', true)
      .subscribe(
        (response: any) => {
          this.catEstadoSolicitud = response;

          for (const item of this.catEstadoSolicitud) {
            const temp = {
              label: item.desDetcat,
              value: item.desDetcat,
              icon: 'fa fa-fw fa-check'
            }

            this.tipo.push(temp);
          }

          //   console.log('valor que viaja: ' + this.tpBandeja);
          if (this.tpBandeja) {
            switch (this.tpBandeja) {
              case '1': {
                this.slcTipo = 'EN PROGRESO';
                break;
              }
              case '2': {
                this.slcTipo = 'CONTESTADA';
                break;
              }
              case '3': {
                this.slcTipo = 'EN REVISION';
                break;
              }
              case '4': {
                this.slcTipo = 'DEVUELTA';
                break;
              }
              case '6': {
                this.slcTipo = 'ESCALADA';
                break;
              }
              case '8': {
                this.slcTipo = 'CERRADA';
                break;
              }
              case '9': {
                this.slcTipo = 'RECHAZADA';
                break;
              }
            }
          } else {
            this.slcTipo = 'EN PROGRESO';
          }

          //  console.log('valor filtro: ' + this.slcTipo);

          this.onFiltro();
          this.loaderService.isLoading.next(false);

        });

  }

  onFiltro() {
    this.loaderService.isLoading.next(true);
    this.bandejaService.getBandejaSolicitud(this.usuarioSesionService.usuario.idUsuario, this.slcTipo, 0, 5).subscribe(
      (data: any) => {
        this.bandeja = data.content;
        this.totalElements = data.totalElements;
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Consulta realizada.' });
      },
      error => {
        console.error('Error--->', error);
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
      }
    )
  }

  loadLazy(event: LazyLoadEvent) {


    this.loaderService.isLoading.next(true);

    let filter = 'none';

    if (event.filters && event.filters.global && event.filters.global.value) {
      filter = event.filters.global.value;
    }

    if (filter != 'none') {
      //  console.log("valor filter:::" + filter)

      this.bandejaService.getBandejaSolicitudUsuarioM(filter, this.usuarioSesionService.usuario.idUsuario, event.first! / event.rows!, event.rows!).subscribe(
        (data: any) => {
          this.bandeja = data.content;
          this.totalElements = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
    } else {


      this.bandejaService.getBandejaSolicitud(this.usuarioSesionService.usuario.idUsuario, this.slcTipo, event.first! / event.rows!, event.rows!).subscribe(
        (data: any) => {
          this.bandeja = data.content;
          this.totalElements = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )

    }



  }


  routeRespuestaSolicitud(id: number) {

    this.router.navigate([this.homePath + '/proyecto/respuestaSolicitud', id]);
  }


}
