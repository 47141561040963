import { Component, OnInit } from '@angular/core';
import { CheckList } from 'src/app/interfaces/checklist.interface';
import { Solicitud } from 'src/app/interfaces/solicitud.interface';
import { Comentarios } from 'src/app/interfaces/comentarios.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { lastValueFrom } from 'rxjs';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { UsuarioSolicitud } from 'src/app/interfaces/usuario-solicitud.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { ArchivoService } from 'src/app/services/archivo.service';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { PersonaSolicitud } from 'src/app/interfaces/personaSolicitud.interface';
import { MimeType } from '../../../services/mimeType.service';
import * as fileSaver from 'file-saver';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';
import { BitacoraPage } from 'src/app/interfaces/bitacora-page.interface';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { DestinatarioComentarios } from 'src/app/interfaces/destinatario-comentarios.interface';
import { Proyecto } from 'src/app/interfaces/proyecto.interface';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { Usuario } from 'src/app/interfaces/usuario_session.interface';

@Component({
  selector: 'app-detalle-solicitud',
  templateUrl: './detalle-solicitud.component.html'
})
export class DetalleSolicitudComponent implements OnInit {

  entrada!: string;
  idProyec!: string;
  loading: boolean;
  datosSolicitud!: Solicitud;
  datosUsuarioSolicitante!: UsuarioSolicitud;
  comentarios: Comentarios[] = [];
  homePath: string;
  archivosSolicitud: Archivo[] = [];
  archivosSolicitudRespuesta: Archivo[] = [];
  archivosProyectoVisible: Archivo[] = [];
  totalElementsB: number;
  bitacoraP: BitacoraPage[];
  dlgBitacora: boolean;
  checkListSolicitud: CheckList[] = [];
  personaSolicitud: PersonaSolicitud[] = [];

  dlgComentario!: boolean;
  dlgComentarioRetirar!: boolean;
  dlgComentarioDevolver!: boolean;
  tipCom!: string;
  textComentario: string;
  textComentarioRetiro: string;
  textComentarioDevolver: string;
  destinatarios: DestinatarioComentarios[] = [];
  guardarFlag: boolean = false;
  listaCorreosNot: string[] = [];
  displayRetirar: boolean = false;
  displayDevolver: boolean = false;
  displayAceptar: boolean = false;
  datosProyecto!: Proyecto;
  usuariosNotificar: OwnersOrResponsables[] = [];
  isPermisoSol: boolean;
  isVisible: boolean;
  usuarioSesion: Usuario;
  isPermisoEdicion: boolean;
  personasRel: OwnersOrResponsables[] = [];
  personasOwner: OwnersOrResponsables[] = [];
  personasExternas: OwnersOrResponsables[] = [];
  dlgSubirArchivo: boolean;
  dlgVisibleArchivo: boolean;
  archivosSubida: any[] = [];
  dlgConfirmSubida: boolean;
  dlgConfirmVisible: boolean;
  archivoEliminar: any;
  dlgConfirmDocDelete: boolean;
  archivosProyecto: Archivo[] = [];
  archivosProGestion: Archivo[] = [];


  constructor(private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private solicitudS: SolicitudService,
    private proyectoService: ProyectoService,
    private homePathService: HomePathService,
    private archivoService: ArchivoService,
    private mimeType: MimeType,
    private messageService: MessageService,
    public usuarioSesionService: UsuarioSesionService,
    private bitacoraPSService: BitacoraProyectoSolService,
    private notificacionesService: NotificacionService) {

    this.loading = true;
    this.route.params.subscribe(p => {

      this.entrada = (p['id']);
      this.idProyec = (p['idProyecto']);
    });

  }

  async ngOnInit() {

    this.homePath = this.homePathService.getHomePath();

    if (!this.datosSolicitud) {
      this.datosSolicitud = {} as Solicitud;
      this.datosSolicitud.checkList = {} as CheckList[];
      this.datosSolicitud.asignados = {} as PersonaSolicitud[];
      this.datosSolicitud.archivosSolicitud = {} as Archivo[];
      this.datosSolicitud.archivosProyectoVisible = {} as Archivo[];
      this.datosUsuarioSolicitante = {} as UsuarioSolicitud;
      this.usuarioSesion = {} as Usuario;

    }

    if (!this.datosProyecto) {
      this.datosProyecto = {} as Proyecto;
      this.datosProyecto.tipoProyecto = {} as Catalogo;
      this.datosProyecto.cveInfClasificacion = {} as Catalogo;
      this.datosProyecto.estadoProyecto = {} as Catalogo;
      this.datosProyecto.usuariosOwnerProyecto = {} as OwnersOrResponsables[];
      this.datosProyecto.usuariosResponsablesProyecto = {} as OwnersOrResponsables[];

    }

    // console.log('valor de isPermiso:::' + this.isPermisoSol)

    this.proyectoService.isPermiso(this.usuarioSesionService.usuario.idUsuario, Number(this.idProyec))
      .subscribe((response: any) => {
        this.isPermisoSol = response;
        this.usuarioSesion = this.usuarioSesionService.usuario;
        //  console.log('valor de isPermiso 2:::' + this.isPermisoSol)
        //   console.log("valor rol user logeado: " + this.usuarioSesion.rol);
        //   console.log("valor numRegistro user logeado: " + this.usuarioSesion.idUsuario);
        if (this.isPermisoSol || this.usuarioSesion.rol == 'ADMINISTRADOR' || this.usuarioSesion.rol == 'COORDINADOR') {
          //   console.log('tengo acceso:::' + this.isPermisoSol)
          this.homePath = this.homePathService.getHomePath();

          this.solicitudS.obtenerSolicitud(this.entrada)
            .subscribe((response: any) => {
              this.datosSolicitud = response;
              this.checkListSolicitud = this.datosSolicitud.checkList;
              this.personaSolicitud = this.datosSolicitud.asignados;
              // this.usuarioSesion = this.usuarioSesionService.usuario;


              //console.log("valor rol user logeado: " + this.usuarioSesion.rol);
              //console.log("valor numRegistro user logeado: " + this.usuarioSesion.idUsuario);

              this.proyectoService.obtenerProyecto(this.datosSolicitud.idProyecto + '')
                .subscribe((response: any) => {
                  this.datosProyecto = response;
                  this.personasOwner = this.datosProyecto.usuariosOwnerProyecto;
                  this.personasRel = this.datosProyecto.usuariosResponsablesProyecto;

                  this.isEdicion();
                  //       console.log("valor ispermisoEdicion::: " + this.isPermisoEdicion);
                });

              this.proyectoService.obtenerDatosUsuario(this.datosSolicitud.usuarioCreador)
                .subscribe((response: any) => {
                  this.datosUsuarioSolicitante = response;

                });



              this.archivoService.listFiles(this.datosSolicitud.idSolicitud + '', 'ORDOC-2')
                .subscribe((response: any) => {
                  this.archivosSolicitud = response;
                  this.archivoService.listFilesProyectoVisible(this.datosSolicitud.idSolicitud + '')
                    .subscribe((response: any) => {
                      this.archivosProyectoVisible = response;

                      this.archivoService.listFiles(this.datosSolicitud.idSolicitud + '', 'ORDOC-3').subscribe((response: any) => {
                        this.archivosSolicitudRespuesta = response;
                        this.archivosSolicitudRespuesta = this.archivosSolicitudRespuesta.concat(this.archivosSolicitud, this.archivosProyectoVisible);

                      });

                    });


                });



              for (const ds of this.personaSolicitud) {
                this.proyectoService.datosUsuario(ds.cveUsuario)
                  .subscribe((response: any) => {
                    let responsableRegistro = response;
                    ds.nombre = responsableRegistro.nombre
                    ds.lob.desDetcat = responsableRegistro.lob
                    ds.correo = responsableRegistro.correo
                  });

              }
            });


          this.solicitudS.obtenerComentariosSol(+this.entrada).subscribe((response: any) => {
            this.comentarios = response;

          });




          if (this.datosSolicitud.idSolicitud != null) {
            const bitacora = {
              cveBitacora: null,
              descAccion: 'Consulta de solicitud folio: ' + this.datosSolicitud.numFolio,
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosSolicitud.idSolicitud,
              tipoMovimiento: {
                id: 'TIPMO-4',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.datosSolicitud.idProyecto
            };

            const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora).subscribe();


          }

          this.loading = false;
        } else {
          const bitacora = {
            cveBitacora: null,
            descAccion: 'Usuario sin autorización a la solicitud',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: Number(this.entrada),
            tipoMovimiento: {
              id: 'TIPMO-4',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-2',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: Number(this.idProyec)
          };

          const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora).subscribe();


          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sin permisos para ver la solicitud' });

          this.router.navigate([this.homePath + '/proyecto/inicio'])
          // console.log("termino el else:::");
        }

      });


  }

  isEdicion() {
    let existeRespo = this.personasRel.find(num => num.cveUsuario === this.usuarioSesion.idUsuario);
    if (existeRespo != null) {
      //  console.log("si me encuentro en responsables" + this.usuarioSesion.rol)
    }
    //  console.log("VALOR ESTADO:::" + this.datosProyecto.estadoProyecto.desDetcat)
    if (this.usuarioSesion.rol == 'ADMINISTRADOR' || this.usuarioSesion.rol == 'COORDINADOR') {
      //     console.log("paso validacion administrador")
      this.isPermisoEdicion = true;
    } else if (existeRespo != null) {
      //     console.log("paso validacion responsable")
      this.isPermisoEdicion = true
    } else {
      //     console.log("no paso validacion no soy admin ni responsable")
      this.isPermisoEdicion = false
    }

  }

  showSubirArchivos() {
    this.dlgSubirArchivo = true;
  }

  async showVisibleArchivos() {
    this.archivoService.listFilesSolVisible(this.datosSolicitud.idSolicitud + '').subscribe((response: any) => {
      this.archivosProyecto = response;
      this.archivosProyecto.forEach(x => {
        //   console.log("es visible o no: " + "nombre del documento " + x.nombre + "clave doc" + x.cveDoc + " Visible: " + x.visible);
      })

      this.archivoService.listFiles(this.datosProyecto.id + '', 'ORDOC-1').subscribe(
        (response: any) => {
          this.archivosProGestion = response;
          this.archivosProGestion.forEach(x => {
            let existe = this.archivosProyecto.find(num => num.cveDoc === x.cveDoc);
            if (existe == null) {
              this.archivosProyecto.push(x);
            }
          })
        }
      );

    });




    /*    this.archivoService.listFilesProyectoVisible(this.datosSolicitud.idSolicitud + '')
        .subscribe( (response: any) => {
         this.archivosProyecto = response;
         this.archivosProyecto.forEach(x => {
          console.log("es visible o no: " + "nombre del documento " + x.nombre + "clave doc" + x.cveDoc + " Visible: " + x.visible);
         })
      
        }); */
    this.dlgVisibleArchivo = true;
  }

  myUploader(event, formUpload): void {

    if (event.files.length !== 0) {
      for (let file of event.files) {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: file,
          fechaCreacion: null,
          nombre: file.name,
          origenDoc: 'ORDOC-2',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: this.datosSolicitud.idProyecto + ''
        }
        let existeGlobal = this.archivosSolicitudRespuesta.find(arch => arch.nombre === archivo.nombre);
        let existeTemp = this.archivosSubida.find(arch => arch.nombre === archivo.nombre);

        if (existeTemp == null && existeGlobal == null) {
          this.archivosSubida.push(archivo);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos listos para subir.' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya fue agregado un documento con el mismo nombre', life: 6000 });
        }
        formUpload.clear();

      }

    }
  }

  async subirArchivos() {

    for await (const arch of this.archivosSubida) {
      arch.cveElemento = this.datosSolicitud.idSolicitud + "";
      if (this.homePath.includes('-internal')) {
        await this.archivoService.uploadFile(arch.documento, "SOLICITUD-" + arch.cveElemento).toPromise().catch(() => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
        }).finally(async () => {
          this.archivoService.saveFile(arch).toPromise();
          const bitacoraSubida = {
            cveBitacora: null,
            descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosSolicitud.idSolicitud!,
            tipoMovimiento: {
              id: 'TIPMO-7',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-2',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: this.datosSolicitud.idProyecto
          };

          const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
          await lastValueFrom(bitacora$);
        });
      } else {
        await this.archivoService.uploadFiles2(arch.documento, "SOLICITUD/" + arch.cveElemento).promise().then(async () => {
          await lastValueFrom(this.archivoService.saveFile(arch));
          const bitacoraSubida = {
            cveBitacora: null,
            descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosSolicitud.idSolicitud!,
            tipoMovimiento: {
              id: 'TIPMO-7',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-2',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: this.datosSolicitud.idProyecto
          };
          await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
        }).catch(() => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
        });
      }
    }

    this.archivosSubida = [];
    /*    const bitacora = {
          cveBitacora: null,
          descAccion: 'Subida de Archivos',
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud!,
          tipoMovimiento: {
              id: 'TIPMO-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };
    
        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
            await lastValueFrom(bitacora$); */

    this.cancel();
    this.ngOnInit();

  }

  async visibleArchivos() {

    for await (const archProyec of this.archivosProyecto) {
      //   console.log("entro a actualizar archivos proyecto para hacerlos visibles" + archProyec.nombre + " visible: " + archProyec.visible)
      if (archProyec.visible == null) {
        archProyec.visible = false;
      }
      //   console.log("entro a actualizar a: " + archProyec.nombre)
      //    console.log("entro a actualizar id doc: " + archProyec.cveDoc)
      if (archProyec.cveDoc != null) {
        //    console.log("entro a actualizar id doc: " + archProyec.cveDoc)
        const archivoP$ = this.archivoService.updateVisible(archProyec.cveDoc, this.datosSolicitud.idSolicitud + "", archProyec.visible!)
        await lastValueFrom(archivoP$);
      }


    }

    this.archivosProyecto = [];

    const bitacora = {
      cveBitacora: null,
      descAccion: 'Se editan los archivos visibles',
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosSolicitud.idSolicitud!,
      tipoMovimiento: {
        id: 'TIPMO-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-2',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: this.datosSolicitud.idProyecto
    };

    const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
    await lastValueFrom(bitacora$);

    this.cancel();
    this.ngOnInit();

  }

  async download(nombre: string, path: string) {
    this.loaderService.isLoading.next(true);
    if (this.homePath.includes('-internal')) {
      const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(nombre));
      this.archivoService.downloadFile(nombre, path)
        .subscribe(
          file => {
            const data: Blob = new Blob([file], { type: mime });
            fileSaver.saveAs(data, nombre);
            this.loaderService.isLoading.next(false);
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Descarga archivo, nombre: ' + nombre + ' peso ' + data.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosSolicitud.idSolicitud!,
              tipoMovimiento: {
                id: 'TIPMO-6',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.datosSolicitud.idProyecto
            };

            this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo no descargado.', life: 6000 });
          });
    } else {
      this.archivoService.downloadExternal(path, nombre);
      const bitacoraSubida = {
        cveBitacora: null,
        descAccion: 'Descarga archivo, nombre: ' + nombre + ' ',
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosSolicitud.idSolicitud!,
        tipoMovimiento: {
          id: 'TIPMO-6',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: this.datosSolicitud.idProyecto
      };

      this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
    }
  }

  getFileExtension3(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  async showBitacora() {
    if (this.datosSolicitud.idSolicitud != null) {
      this.loaderService.isLoading.next(true);
      this.bitacoraPSService.getBitacoraSolicitud(0, 5, this.datosSolicitud.idSolicitud).subscribe(
        (data: any) => {
          this.bitacoraP = data.content;
          this.totalElementsB = data.totalElements;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Consulta realizada.' });
        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
      this.dlgBitacora = true;
    }

  }

  showEliminarDoc(arch: any) {
    this.archivoEliminar = arch;
    this.dlgConfirmDocDelete = true;

  }

  loadLazyB(event: LazyLoadEvent) {

    if (this.datosSolicitud.idSolicitud != null) {
      this.loaderService.isLoading.next(true);

      this.bitacoraPSService.getBitacoraSolicitud(event.first! / event.rows!, event.rows!, this.datosSolicitud.idSolicitud).subscribe(
        (data: any) => {
          this.bitacoraP = data.content;
          this.totalElementsB = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
    }
  }

  async showAgregarComentario(tipo: any) {

    this.tipCom = tipo;
    this.dlgComentario = true;
    this.textComentario = '';
    if (this.destinatarios.length == 0) {

      for await (const cm of this.personaSolicitud) {

        const destinatario: DestinatarioComentarios = {
          cveSolicitud: this.datosSolicitud.idSolicitud!,
          comentario: '',
          correo: cm.correo,
          cveUsuarioCreacion: cm.cveUsuario,
          fechaCreacion: null,
          indEnvio: false,
          lob: cm.lob.desDetcat,
          nombre: cm.nombre!,
          rol: 'DESIGNADO SOLICITUD'
        }

        this.destinatarios.push(destinatario);

      }
    }

  }

  validaMaxCom1($event) {
    if ($event.textValue.length >= 4000) {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Máximo de 4000 caracteres.', life: 6000 });
      this.textComentario = $event.textValue.substring(0, 3999);
      //   console.log('Comentario: ', this.textComentario);
    }
  }

  validaMaxComRetiro($event) {
    if ($event.textValue.length >= 4000) {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Máximo de 4000 caracteres.', life: 6000 });
      this.textComentarioRetiro = $event.textValue.substring(0, 3999);
      //   console.log('Comentario: ', this.textComentarioRetiro);
    }
  }

  validaMaxComDevolver($event) {
    if ($event.textValue.length >= 4000) {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Máximo de 4000 caracteres.', life: 6000 });
      this.textComentarioDevolver = $event.textValue.substring(0, 3999);
      //    console.log('Comentario: ', this.textComentarioDevolver);
    }
  }

  async guardarComentario() {
    this.guardarFlag = true;
    this.loaderService.isLoading.next(true);
    if (this.textComentario == null || this.textComentario.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El comentario no puede ser vacio', life: 6000 });
    } else {
      this.destinatarios.forEach(ds => {
        if (ds.indEnvio == true) {
          this.listaCorreosNot.push(ds.correo);
        }

      });



      if (this.listaCorreosNot.length > 0) {



        this.usuariosNotificar = this.usuariosNotificar.concat(this.datosProyecto.usuariosOwnerProyecto, this.datosProyecto.usuariosResponsablesProyecto);

        for await (const cm of this.usuariosNotificar) {

          if (cm.indNotificacion) {
            const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
            let responsableRegistro = await lastValueFrom(responsableRegistro$);
            this.listaCorreosNot.push(responsableRegistro.correo);
          }

        }


        const mailDTO = {
          usuariosMail: this.listaCorreosNot,
          subject: "Notificación de comentarios solicitud " + this.datosSolicitud.numFolio,
          infoMailDTO: {
            numFolio: this.datosSolicitud.numFolio!,
            titulo: "",
            descripcion: this.textComentario,
            fechaLimite: "",
            usuariosTramite: [],
            url: document.location.href

          }
        }

        const sendEmail$ = this.notificacionesService.notificarComentarioSolicitud(mailDTO);
        await lastValueFrom(sendEmail$);

        const comentario: Comentarios = {
          idComentarios: '',
          comentarios: this.textComentario,
          cveSolicitud: this.datosSolicitud.idSolicitud!,
          fechaCreacion: null,
          nombreUsuarioCreador: "",
          numRegUsuarioCreador: this.usuarioSesionService.usuario.idUsuario


        }

        const guardarComentario$ = this.solicitudS.saveComentariosSolicitud(comentario);
        await lastValueFrom(guardarComentario$);

        const bitacoraEmail = {
          cveBitacora: null,
          descAccion: 'Envío correo electrónico comentarios solicitud número de folio: ' + this.datosSolicitud.numFolio +
            ' Comentario enviado: ' + this.textComentario +
            ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud!,
          tipoMovimiento: {
            id: 'TIPMO-5',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmail);
        await lastValueFrom(bitacoraEmail$);
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El comentario fue enviado exitosamente a los usuarios seleccionados: ', life: 6000 });
        this.loaderService.isLoading.next(false);
        const datosComentarios$ = this.solicitudS.obtenerComentariosSol(+this.entrada);
        this.comentarios = await lastValueFrom(datosComentarios$);
        this.dlgComentario = false;
      }
      else {

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un usuario para enviar los comentarios', life: 6000 });
      }



    }
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;

  }

  eliminaArchSubidaTemp(arch: any) {
    this.archivosSubida = this.archivosSubida.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }

  cancel() {
    this.displayRetirar = false;
    this.displayDevolver = false;
    this.displayAceptar = false;
    this.dlgSubirArchivo = false;
    this.dlgVisibleArchivo = false;
    this.dlgConfirmSubida = false;
    this.dlgConfirmVisible = false;
    this.dlgConfirmDocDelete = false;
    this.archivosSubida = [];
    this.archivosProyecto = [];
  }



  showDialogRetirar() {
    this.displayRetirar = true;
  }

  showDialogDevolver() {
    this.displayDevolver = true;
  }

  showDialogAceptar() {
    this.displayAceptar = true;
  }

  async retirar() {

    if (this.textComentarioRetiro == null || this.textComentarioRetiro.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Favor de ingresar el motivo del retiro de la solicitud', life: 6000 });
      this.cancel();
    } else {
      this.loaderService.isLoading.next(true);
      this.guardarFlag = true;

      const comentario: Comentarios = {
        idComentarios: '',
        comentarios: this.textComentarioRetiro,
        cveSolicitud: this.datosSolicitud.idSolicitud!,
        fechaCreacion: null,
        nombreUsuarioCreador: "",
        numRegUsuarioCreador: this.usuarioSesionService.usuario.idUsuario


      }

      const guardarComentario$ = this.solicitudS.saveComentariosSolicitud(comentario);
      await lastValueFrom(guardarComentario$);

      const datosSolicitud = {
        idSolicitud: this.datosSolicitud.idSolicitud,
        estado: "EDSOL-10"
      }

      const comentarioCierre$ = this.solicitudS.updateEstados(datosSolicitud);
      await lastValueFrom(comentarioCierre$);

      if (this.datosSolicitud.idSolicitud != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Retiro de solicitud folio: ' + this.datosSolicitud.numFolio,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud,
          tipoMovimiento: {
            id: 'TIPMO-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);
      }



      this.usuariosNotificar = this.usuariosNotificar.concat(this.datosProyecto.usuariosOwnerProyecto,
        this.datosProyecto.usuariosResponsablesProyecto, this.datosProyecto.usuariosFirmaExterna);

      for await (const cm of this.usuariosNotificar) {


        if (cm.indNotificacion) {
          const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
          let responsableRegistro = await lastValueFrom(responsableRegistro$);
          this.listaCorreosNot.push(responsableRegistro.correo);
        }

      }

      const mailDTO = {
        usuariosMail: this.listaCorreosNot,
        subject: "Notificación de retiro de solicitud " + this.datosSolicitud.numFolio,
        infoMailDTO: {
          numFolio: this.datosSolicitud.numFolio!,
          titulo: "",
          descripcion: this.textComentarioRetiro,
          fechaLimite: "",
          usuariosTramite: [],
          url: document.location.href

        }
      }

      const sendEmail$ = this.notificacionesService.notificarRetiroSolicitud(mailDTO);
      await lastValueFrom(sendEmail$);

      this.dlgComentarioRetirar = false;
      this.ngOnInit();
      this.cancel();

      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue retirada', life: 6000 });
    }

  }


  async eliminaArch() {

    const eliminarArch$ = this.archivoService.eliminarArchivoProyecto(this.archivoEliminar.cveDoc);
    await lastValueFrom(eliminarArch$);

    const bitacora = {
      cveBitacora: null,
      descAccion: 'Archivo con Id: ' + this.archivoEliminar.cveDoc + 'nombre: ' + this.archivoEliminar.nombre + ' eliminado',
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosProyecto.id!,
      tipoMovimiento: {
        id: 'TIPMO-3',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-2',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: this.datosSolicitud.idProyecto
    };

    const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
    await lastValueFrom(bitacora$);

    this.archivoEliminar = [];
    this.cancel();
    this.ngOnInit();

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }

  async devolver() {


    if (this.textComentarioDevolver == null || this.textComentarioDevolver.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Favor de ingresar el motivo para devolver la solicitud', life: 6000 });
      this.cancel();
    } else {
      this.loaderService.isLoading.next(true);
      this.guardarFlag = true;

      const comentario: Comentarios = {
        idComentarios: '',
        comentarios: this.textComentarioDevolver,
        cveSolicitud: this.datosSolicitud.idSolicitud!,
        fechaCreacion: null,
        nombreUsuarioCreador: "",
        numRegUsuarioCreador: this.usuarioSesionService.usuario.idUsuario


      }

      const guardarComentario$ = this.solicitudS.saveComentariosSolicitud(comentario);
      await lastValueFrom(guardarComentario$);

      const datosSolicitud = {
        idSolicitud: this.datosSolicitud.idSolicitud,
        estado: "EDSOL-4"
      }

      const comentarioCierre$ = this.solicitudS.updateEstados(datosSolicitud);
      await lastValueFrom(comentarioCierre$);

      if (this.datosSolicitud.idSolicitud != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Se devuelve a los designados la solicitud: ' + this.datosSolicitud.numFolio,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud,
          tipoMovimiento: {
            id: 'TIPMO-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);
      }


      this.usuariosNotificar = this.usuariosNotificar.concat(this.datosProyecto.usuariosOwnerProyecto,
        this.datosProyecto.usuariosResponsablesProyecto, this.datosProyecto.usuariosFirmaExterna);

      for await (const cm of this.usuariosNotificar) {


        if (cm.indNotificacion) {
          const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
          let responsableRegistro = await lastValueFrom(responsableRegistro$);
          this.listaCorreosNot.push(responsableRegistro.correo);
        }

      }

      for await (const cm of this.personaSolicitud) {

        this.listaCorreosNot.push(cm.correo);

      }


      const mailDTO = {
        usuariosMail: this.listaCorreosNot,
        subject: "Se notifica que fue devuelta la solicitud" + this.datosSolicitud.numFolio,
        infoMailDTO: {
          numFolio: this.datosSolicitud.numFolio!,
          titulo: "",
          descripcion: this.textComentarioDevolver,
          fechaLimite: "",
          usuariosTramite: [],
          url: document.location.href

        }
      }

      const sendEmail$ = this.notificacionesService.notificarDevueltaSolicitud(mailDTO);
      await lastValueFrom(sendEmail$);

      this.dlgComentarioDevolver = false;
      this.ngOnInit();
      this.cancel();

      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue devuelta al usuario designado', life: 6000 });
    }

  }

  async aceptar() {

    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;

    const datosSolicitud = {
      idSolicitud: this.datosSolicitud.idSolicitud,
      estado: "EDSOL-5"
    }

    const comentarioCierre$ = this.solicitudS.updateEstados(datosSolicitud);
    await lastValueFrom(comentarioCierre$);

    if (this.datosSolicitud.idSolicitud != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'La solicitud fue aceptada, número de folio: ' + this.datosSolicitud.numFolio,
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosSolicitud.idSolicitud,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: this.datosSolicitud.idProyecto
      };

      const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacora$);
    }

    const datosSolicitudCerrar = {
      idSolicitud: this.datosSolicitud.idSolicitud,
      estado: "EDSOL-8"
    }

    const cierre$ = this.solicitudS.updateEstados(datosSolicitudCerrar);
    await lastValueFrom(cierre$);

    if (this.datosSolicitud.idSolicitud != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'Se cierra la solicitud: ' + this.datosSolicitud.numFolio,
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosSolicitud.idSolicitud,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: this.datosSolicitud.idProyecto
      };

      const bitacoraC$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacoraC$);
    }

    this.usuariosNotificar = this.usuariosNotificar.concat(this.datosProyecto.usuariosOwnerProyecto,
      this.datosProyecto.usuariosResponsablesProyecto, this.datosProyecto.usuariosFirmaExterna);

    for await (const cm of this.usuariosNotificar) {


      if (cm.indNotificacion) {
        const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
        let responsableRegistro = await lastValueFrom(responsableRegistro$);
        this.listaCorreosNot.push(responsableRegistro.correo);
      }

    }

    const mailDTO = {
      usuariosMail: this.listaCorreosNot,
      subject: "Notificación de cierre de solicitud " + this.datosSolicitud.numFolio,
      infoMailDTO: {
        numFolio: this.datosSolicitud.numFolio!,
        titulo: this.datosSolicitud.nombreSolicitud,
        descripcion: this.datosSolicitud.comentarioCierre,
        fechaLimite: "",
        usuariosTramite: [],
        url: document.location.href

      }
    }

    const sendEmail$ = this.notificacionesService.notificarCierreSolicitud(mailDTO);
    await lastValueFrom(sendEmail$);

    const bitacoraEmail = {
      cveBitacora: null,
      descAccion: 'Envío correo electrónico cierre de solicitud número de folio: ' + this.datosSolicitud.numFolio +
        ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosSolicitud.idSolicitud!,
      tipoMovimiento: {
        id: 'TIPMO-5',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-2',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: this.datosSolicitud.idProyecto
    };

    const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmail);
    await lastValueFrom(bitacoraEmail$);
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue aceptada con éxito', life: 6000 });
    this.loaderService.isLoading.next(false);
    this.ngOnInit();
    this.cancel();

  }

  prevPage() {
    this.router.navigate([this.homePath + '/proyecto/gestion', this.datosSolicitud.idProyecto]);
  }


}
