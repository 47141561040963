//Angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TooltipModule } from 'primeng/tooltip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { environment } from 'src/environments/environment';

//pages
import { ProyectoComponent } from './components/pages/proyecto/proyecto.component';
import { SolicitudComponent } from './components/pages/solicitud/solicitud.component';
import { CatalogosComponent } from './components/pages/admin/catalogos/catalogos.component';
import { RolesComponent } from './components/pages/admin/roles/roles.component';
import { BandejaComponent } from './components/pages/bandeja/bandeja.component';
import { RespuestaSolicitudComponent } from './components/pages/respuesta-solicitud/respuesta-solicitud.component';
import { DatosProyectoComponent } from './components/pages/proyecto/steps/datos-proyecto/datos-proyecto.component';
import { ReportesComponent } from './components/pages/reportes/reportes.component';

// PrimeNG
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PanelModule } from 'primeng/panel';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ContextMenuModule } from 'primeng/contextmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToolbarModule } from 'primeng/toolbar';
import { DataViewModule } from 'primeng/dataview';
import { TreeModule } from 'primeng/tree';
import { TabViewModule } from 'primeng/tabview';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChartModule } from 'primeng/chart';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CaptchaModule } from 'primeng/captcha';
import { OrderListModule } from 'primeng/orderlist';
import { CardModule } from 'primeng/card';
import { MenuModule } from 'primeng/menu';
import { StepsModule } from 'primeng/steps';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MultiSelectModule } from 'primeng/multiselect';

// Shared Components
import { HomeComponent } from './components/shared/template/home/home.component';
import { NavbarComponent } from './components/shared/template/navbar/navbar.component';
import { SidebarComponent } from './components/shared/template/sidebar/sidebar.component';
import { FooterComponent } from './components/shared/template/footer/footer.component';
import { LoaderComponent } from './components/shared/template/loader/loader.component';

import { OwnersComponent } from './components/pages/proyecto/steps/owners/owners.component';
import { ResponsablesComponent } from './components/pages/proyecto/steps/responsables/responsables.component';
import { ConfirmacionComponent } from './components/pages/proyecto/steps/confirmacion/confirmacion.component';
import { GestionComponent } from './components/pages/proyecto/gestion/gestion.component';
import { DatosSolicitudComponent } from './components/pages/solicitud/steps/datos-solicitud/datos-solicitud.component';
import { ChecklistComponent } from './components/pages/solicitud/steps/checklist/checklist.component';
import { PersonaSolicitudComponent } from './components/pages/solicitud/steps/persona-solicitud/persona-solicitud.component';
import { DocumentosSolicitudComponent } from './components/pages/solicitud/steps/documentos-solicitud/documentos-solicitud.component';
import { ConfirmacionSolicitudComponent } from './components/pages/solicitud/steps/confirmacion-solicitud/confirmacion-solicitud.component';
import { DetalleSolicitudComponent } from './components/pages/detalle-solicitud/detalle-solicitud.component';
import { InicioComponent } from './components/pages/inicio/inicio.component';
import { BandejaSolicitudComponent } from './components/pages/bandeja_solicitud/bandeja-solicitud/bandeja-solicitud.component';
import { UsuariosComponent } from './components/pages/admin/usuarios/usuarios.component';
import { UsuarioService } from './components/pages/admin/usuarios/usuario.service';
import { EditarEscalamientoComponent } from './components/pages/matriz-escalamiento/editar-escalamiento/editar-escalamiento.component';

// Services
import { LoaderService } from './services/loader.service';
import { ProyectoInformacionService } from './services/proyecto-Informacion.service';
import { ProyectoService } from './services/proyecto.service';
import { ReportesService } from './services/reportes.service';
import { DocumentosAdjuntosComponent } from './components/pages/proyecto/steps/adjuntos/documentos-adjuntos.component';
import { SolicitudInformacionService } from './services/solicitud-informacion.service';
import { CatalogoService } from './services/catalogo.service';
import { MatrizEscalamientoComponent } from './components/pages/matriz-escalamiento/matriz-escalamiento.component';
import { TablaEscalamientoComponent } from './components/pages/matriz-escalamiento/tabla-escalamiento/tabla-escalamiento.component';
import { MatrizService } from './services/matriz.service';
import { LoginInternalComponent } from './components/shared/login-internal/login.component';
import { AuthTokenService } from './services/authToken.service';
import { PerfilesService } from './services/perfiles.service';
import { AccessDeniedComponent } from './components/shared/access-denied/access-denied.component';
import { BrowserErrorComponent } from './components/shared/browser-error/browser-error.component';
import { LoginComponent } from './components/shared/login/login.component';
import { UserLoginService } from './services/user-login.service';
import { PasswordOlvidadoPaso1Component, PasswordOlvidadoPaso2Component } from './components/shared/password-olvidado/password-olvidado.component';
import { NewPasswordComponent } from './components/shared/new-password/new-password.component';
import { UserRegistrationService } from './services/user-registration.service';
import { ReenviarCodigoComponent } from './components/shared/reenviar-codigo/reenviar-codigo.component';
import { SessionExpComponent } from './components/shared/session-exp/session-exp.component';
import { LogoutComponent } from './components/shared/logout/logout.component';
import { LoaderInterceptor } from './components/shared/interceptors/loader.interceptor';
import { TokenInterceptor } from './components/shared/interceptors/token.interceptor';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { HttpErrorInterceptor } from './components/shared/interceptors/http-error.interceptor';
import { LogoutInternalComponent } from './components/shared/logout-internal/logout.component';
import { RolModuloService } from './services/rol_modulo.service';

import { SolicitudService } from './services/solicitud.service';
import { BandejaService } from './services/bandeja.service';
import { ArchivoService } from './services/archivo.service';
import { GestionChecklistComponent } from './components/pages/admin/gestion-checklist/gestion-checklist.component';
import { BitacoraProyectoSolService } from './services/bitacora-p-s-service';
import { NotificacionService } from './services/notificacion.service';
import { AwsUtil } from './services/aws.service';
import { AuthService } from './services/auth.service';
import { UsuarioSesionService } from './services/usuario-sesion.service';
import { UsuarioParametrosService } from './services/usuario-parametros.service';
import { CognitoUtil } from './services/cognito.service';
import { CurrencyPipe, DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BuscarComponent } from './components/pages/buscar/buscar.component';
import { BuscarService } from './services/buscar.service';
import { MfaConfiguracionComponent } from './components/pages/mfa-configuracion/mfa-configuracion.component';
import { MfaComponent } from './components/pages/mfa/mfa.component';

const internalProvider = [{
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
}];

const externalProvider = [{
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenInterceptor,
  multi: true
}];

@NgModule({
  declarations: [
    HomeComponent,
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    ProyectoComponent,
    LoaderComponent,
    CatalogosComponent,
    UsuariosComponent,
    RolesComponent,
    SolicitudComponent,
    BandejaComponent,
    RespuestaSolicitudComponent,
    DatosProyectoComponent,
    OwnersComponent,
    ResponsablesComponent,
    DocumentosAdjuntosComponent,
    ConfirmacionComponent,
    GestionComponent,
    DatosSolicitudComponent,
    ChecklistComponent,
    PersonaSolicitudComponent,
    DocumentosSolicitudComponent,
    ConfirmacionSolicitudComponent,
    DetalleSolicitudComponent,
    InicioComponent,
    BandejaSolicitudComponent,
    MatrizEscalamientoComponent,
    EditarEscalamientoComponent,
    TablaEscalamientoComponent,
    EditarEscalamientoComponent,
    LoginInternalComponent,
    AccessDeniedComponent,
    BrowserErrorComponent,
    LoginComponent,
    LogoutInternalComponent,
    PasswordOlvidadoPaso1Component,
    NewPasswordComponent,
    PasswordOlvidadoPaso2Component,
    ReenviarCodigoComponent,
    SessionExpComponent,
    LogoutComponent,
    SessionExpComponent,
    GestionChecklistComponent,
    ReportesComponent,
    BuscarComponent,
    MfaConfiguracionComponent,
    MfaComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    TooltipModule,
    ScrollPanelModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule,
    InputTextareaModule,
    PanelModule,
    SplitButtonModule,
    DialogModule,
    TableModule,
    DropdownModule,
    ToastModule,
    ButtonModule,
    CalendarModule,
    EditorModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    ContextMenuModule,
    RadioButtonModule,
    ToolbarModule,
    DataViewModule,
    TreeModule,
    TabViewModule,
    FieldsetModule,
    FileUploadModule,
    ChipsModule,
    AutoCompleteModule,
    ChartModule,
    SelectButtonModule,
    CheckboxModule,
    CaptchaModule,
    OrderListModule,
    CardModule,
    MenuModule,
    StepsModule,
    ConfirmPopupModule,
    MultiSelectModule
  ],

  providers: [
    LoaderService,
    MessageService,
    CognitoUtil,
    AuthService,
    AwsUtil,
    UsuarioService,
    LoaderService,
    SolicitudService,
    CatalogoService,
    ProyectoInformacionService,
    SolicitudInformacionService,
    ProyectoService,
    ReportesService,
    MessageService,
    ConfirmationService,
    CatalogoService,
    UsuarioService,
    MatrizService,
    AuthTokenService,
    PerfilesService,
    UserLoginService,
    UserRegistrationService,
    LoaderService,
    RolModuloService,
    BandejaService,
    ArchivoService,
    BitacoraProyectoSolService,
    NotificacionService,
    UsuarioParametrosService,
    UsuarioSesionService,
    BuscarService,
    DatePipe,
    CurrencyPipe,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    environment.ROUTES.includes('INTERNAL') ? internalProvider : externalProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }