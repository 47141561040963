import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class HomePathService {

    constructor(private router: Router) { }

    getHomePath() {
        if (this.router.url.includes('home-internal')) {
            return '/home-internal';
        } else {
            return '/home';
        }
    }

    getUrl() {
        return this.router.url;
    }

}
