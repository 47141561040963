import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { HomePathService } from 'src/app/services/home-path.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {

  buscarform: FormGroup;
  homePath: string;
  usr_img: string;


  constructor(
    private fb: FormBuilder,
    public usuariSesionService: UsuarioSesionService,
    private router: Router,
    private homePathService: HomePathService
  ) {
    this.homePath = this.homePathService.getHomePath();

    this.buscarform = this.fb.group({
      'numero': new FormControl('')
    });
  }

  buscar() {
    this.router.navigate([this.homePath + '/buscar', this.buscarform.value.numero]);
  }

}
