import { Component, OnInit } from '@angular/core';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { BandejaProyecto } from 'src/app/interfaces/bandeja-proyecto.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { BandejaService } from 'src/app/services/bandeja.service';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { LoaderService } from 'src/app/services/loader.service';
import { HomePathService } from 'src/app/services/home-path.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-bandeja',
  templateUrl: './bandeja.component.html'
})
export class BandejaComponent implements OnInit {

  catEstadoProyecto: Catalogo[] = [];
  bandeja: BandejaProyecto[];
  tpBandeja: string;
  slcTipo: string;
  totalElements: number;
  loading: boolean;
  homePath: string;
  tipo: SelectItem[] = [];


  constructor(private router: Router,
    private catalogoService: CatalogoService,
    private route: ActivatedRoute,
    private bandejaService: BandejaService,
    private messageService: MessageService,
    private homePathService: HomePathService,
    private loaderService: LoaderService,
    public usuarioSesionService: UsuarioSesionService) {
    this.route.params.subscribe(parametros => {
      this.tpBandeja = parametros['tipo'];
    });
  }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.loaderService.isLoading.next(true);
    this.catalogoService.getCatalogosNemonico('EDPRO', true)
      .subscribe(
        (response: any) => {
          this.catEstadoProyecto = response;

          for (const item of this.catEstadoProyecto) {
            const temp = {
              label: item.desDetcat,
              value: item.desDetcat,
              icon: 'fa fa-fw fa-check'
            }

            this.tipo.push(temp);
          }

          //    console.log('valor que viaja: ' + this.tpBandeja);
          if (this.tpBandeja) {
            switch (this.tpBandeja) {
              case '1': {
                this.slcTipo = 'EN PROGRESO';
                break;
              }
              case '2': {
                this.slcTipo = 'RETRASADO';
                break;
              }
              case '3': {
                this.slcTipo = 'EN TIEMPO';
                break;
              }
              case '4': {
                this.slcTipo = 'CERRADO';
                break;
              }
              case '5': {
                this.slcTipo = 'CANCELADO';
                break;
              }
            }
          } else {
            this.slcTipo = 'EN PROGRESO';
          }

          //   console.log('valor filtro: ' + this.slcTipo);

          this.onFiltro();
          this.loaderService.isLoading.next(false);

        });


  }

  onFiltro() {
    if (this.usuarioSesionService.usuario.rol == 'ADMINISTRADOR' || this.usuarioSesionService.usuario.rol == 'COORDINADOR') {
      //  console.log("soy admin o coordinador:::")
      this.bandejaService.getBandejaProyectoAdminEstatus(this.usuarioSesionService.usuario.pais, this.slcTipo, 0, 5).subscribe(
        (data: any) => {
          this.bandeja = data.content;
          this.totalElements = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
    } else {
      //     console.log("soy mortal:::")
      this.bandejaService.getBandejaProyecto(this.usuarioSesionService.usuario.idUsuario, this.slcTipo, 0, 5).subscribe(
        (data: any) => {
          this.bandeja = data.content;
          this.totalElements = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
    }
    /*  this.bandejaService.getBandejaProyecto(this.usuarioSesionService.usuario.idUsuario, this.slcTipo, 0, 5).subscribe(
        (data: any) => {
          this.bandeja = data.content;
          this.totalElements = data.totalElements;
         
          this.messageService.add({ severity: 'success', summary: 'Exito!', detail: 'Consulta realizada.' });
        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Registros no cargados.' });
        }
      ) */
  }

  loadLazy(event: LazyLoadEvent) {


    this.loaderService.isLoading.next(true);

    let filter = 'none';

    if (event.filters && event.filters.global && event.filters.global.value) {
      filter = event.filters.global.value;
    }

    //     console.log("valor filter:::" + filter)


    if (filter != 'none') {
      if (this.usuarioSesionService.usuario.rol == 'ADMINISTRADOR' || this.usuarioSesionService.usuario.rol == 'COORDINADOR') {
        this.bandejaService.getBandejaProyectoAdmin(filter, this.usuarioSesionService.usuario.pais, event.first! / event.rows!, event.rows!).subscribe(
          (data: any) => {
            this.bandeja = data.content;
            this.totalElements = data.totalElements;
            this.loaderService.isLoading.next(false);

          },
          error => {
            console.error('Error--->', error);
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
          }
        )
      } else {
        this.bandejaService.getBandejaProyectoUsuarioM(filter, this.usuarioSesionService.usuario.idUsuario, event.first! / event.rows!, event.rows!).subscribe(
          (data: any) => {
            this.bandeja = data.content;
            this.totalElements = data.totalElements;
            this.loaderService.isLoading.next(false);

          },
          error => {
            console.error('Error--->', error);
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
          }
        )
      }
    }
    else {

      if (this.usuarioSesionService.usuario.rol == 'ADMINISTRADOR' || this.usuarioSesionService.usuario.rol == 'COORDINADOR') {
        //   console.log("soy admin o coordinador:::")
        this.bandejaService.getBandejaProyectoAdminEstatus(this.usuarioSesionService.usuario.pais, this.slcTipo, event.first! / event.rows!, event.rows!).subscribe(
          (data: any) => {
            this.bandeja = data.content;
            this.totalElements = data.totalElements;
            this.loaderService.isLoading.next(false);

          },
          error => {
            console.error('Error--->', error);
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
          }
        )
      } else {
        //   console.log("soy mortal:::")
        this.bandejaService.getBandejaProyecto(this.usuarioSesionService.usuario.idUsuario, this.slcTipo, event.first! / event.rows!, event.rows!).subscribe(
          (data: any) => {
            this.bandeja = data.content;
            this.totalElements = data.totalElements;
            this.loaderService.isLoading.next(false);

          },
          error => {
            console.error('Error--->', error);
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
          }
        )
      }

    }
  }

  routeDetalleProyecto(id: number) {
    this.router.navigate([this.homePath + '/proyecto/gestionProyecto', id]);
  }

}
