import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { SafeResourceUrl } from '@angular/platform-browser';
import { HomePathService } from 'src/app/services/home-path.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {

  homePath: string;
  urlInstructivo: SafeResourceUrl;


  constructor(
    private homePathService: HomePathService) {
    this.homePath = this.homePathService.getHomePath();
  }


  ngOnInit(): void {
    (function ($) {
      'use strict'; // Start of use strict

      // Toggle the side navigation
      $('#sidebarToggle').on('click', function (e) {
        e.preventDefault();
        $('body').toggleClass('sidebar-toggled');
        $('.sidebar').toggleClass('toggled');
      });

      // Scroll to top button appear
      $(document).on('scroll', function () {
        const scrollDistance = $(this).scrollTop();
        if (scrollDistance! > 100) {
          $('.scroll-to-top').fadeIn();
        } else {
          $('.scroll-to-top').fadeOut();
        }
      });

      // Smooth scrolling using jQuery easing
      // $(document).on('click', 'a.scroll-to-top', function (event) {
      //   const $anchor = $(this);
      //   $('html, body').stop().animate({
      //     scrollTop: ($($anchor.attr('href')).offset().top)
      //   }, 1000, 'easeInOutExpo');
      //   event.preventDefault();
      // });

    })(jQuery); // End of use strict

    this.urlInstructivo = 'https://alm-confluence.systems.uk.hsbc/confluence/pages/viewpage.action?spaceKey=SLSs&title=Soporte+Legal+Services';

  }


}
