import { Injectable } from '@angular/core';
import { cognito } from '../../environments/environment';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { Callback, CognitoUtil } from './cognito.service';

@Injectable({
  providedIn: 'root'
})
export class MfaUserService implements Callback {

  public static _REGION = cognito.region;
  public static _IDENTITY_POOL_ID = cognito.identityPoolId;
  public static _USER_POOL_ID = cognito.userPoolId;
  public static _CLIENT_ID = cognito.clientId;

  accessToken: string;

  constructor(public cognitoUtil: CognitoUtil) { }

  callback(): void {
    throw new Error('Method not implemented.');
  }

  callbackWithParam(result: any): void {
    if (result !== null) {
      this.accessToken = result;
    }
  }

  /**
   * 
   * @param phoneNumber 
   * @returns Promise
   */
  sendPhoneNumberP(phoneNumber: string) {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);

    return new Promise((resolve, reject) => {
      const params = {
        UserAttributes: [{
          Name: 'phone_number',
          Value: phoneNumber
        }],
        AccessToken: this.accessToken
      }

      //console.log('sendPhoneNumberP Promise: ', params);
      cognito.updateUserAttributes(params, (err, data) => {
        if (err) {
          console.error("Error al agregar el numero de telefo al usuario: ", err);
        } else {
          resolve(data);
        }
      });
    });
  }

  /**
   * 
   * @param code 
   * @returns Promise<boolean>
   */
  verifyPhone(code: string): Promise<boolean> {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);

    return new Promise((resolve, reject) => {
      const paramsV = {
        AccessToken: this.accessToken,
        AttributeName: 'phone_number',
        Code: code
      }

      cognito.verifyUserAttribute(paramsV, (err, data) => {
        if (err) {
          console.error("Error al verificar el numero de telefo: ", err);
          resolve(false);
        } else {
          //console.log('Data: ', data);
          resolve(true);
        }
      });
    });
  }

  /**
   * 
   * @returns Promise<boolean>
   */
  enableMfaSms(): Promise<boolean> {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);

    return new Promise((resolve, reject) => {
      const params = {
        AccessToken: this.accessToken,
        SMSMfaSettings: {
          Enabled: true,
          PreferredMfa: true
        }
      }

      cognito.setUserMFAPreference(params, (err, data) => {
        if (err) {
          console.error("Error al activar el MFA para el usuario: ", err);
          resolve(false);
        } else {
          //console.log(" MFA activado para el usuario");
          resolve(true);
        }
      });

    });

  }

  /**
   * 
   * @returns boolean
   */
  disableMfaSms(): boolean {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);
    var blnmMfa: boolean = false;

    const params = {
      AccessToken: this.accessToken,
      SMSMfaSettings: {
        Enabled: false,
        PreferredMfa: false
      }
    }

    cognito.setUserMFAPreference(params, (err, data) => {
      if (err) {
        console.error("Error al desactivar el MFA para el usuario: ", err);
        blnmMfa = false;
      } else {
        //console.log("MFA desactivado para el usuario");
        blnmMfa = true;
      }
    });
    return blnmMfa;
  }

  /**
   * obtener las opciones de MFA registradas para el usuario
   * @returns Promise<any>
   */
  getMfaOptions(): Promise<any> {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);

    return new Promise((resolve, reject) => {

      const params = {
        AccessToken: this.accessToken,
      }

      cognito.getUser(params, (err, data) => {
        if (err) {
          console.error("Error al obtener los detalles del usuario: ", err);
          resolve([]);
        } else {
          resolve(data.UserAttributes);
        }
      });
    });

  }

  /**
   * 
   * @returns Promise
   */
  isMfaOptions() {
    const cognito = new CognitoIdentityServiceProvider();
    this.cognitoUtil.getAccessToken(this);

    return new Promise((resolve, reject) => {

      const params = {
        AccessToken: this.accessToken,
      }

      cognito.getUser(params, (err, data) => {
        if (err) {
          console.error("Error al obtener los detalles del usuario, para verificar el uso del MFA", err);
        } else {
          if (data.hasOwnProperty("PreferredMfaSetting") || data.hasOwnProperty("UserMFASettingList")) {
            // si el usuario tiene activas las opciones de MFA no se muestra la opción de capturar el teléfono y activar el MFA
            resolve(false);
          } else {
            resolve(true);
          }
        }
      });
    });

  }

}

