import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { api } from "src/environments/environment";
import { BitacoraPS } from "../interfaces/bitacora-ps.interface";


const HEADERS = new HttpHeaders({
    'Content-Type': 'application/json;charset=UTF-8'
});

@Injectable({
    providedIn: 'root'
})
export class BitacoraProyectoSolService {

    URL = `${api.urlAPI}` + 'api/bitacora/';

    constructor(private http: HttpClient) { }


    saveBitacora(bitacora: BitacoraPS) {
        return this.http.post(this.URL + 'save', bitacora, { headers: HEADERS });
    }

    getBitacoraProyecto(page: number, size: number, id: number): Observable<any[]> {
        return this.http.get<any[]>(this.URL + 'list/bitacora/proyecto/' + id + '/page/' + page + '/size/' + size);
    }

    getBitacoraSolicitud(page: number, size: number, id: number): Observable<any[]> {
        return this.http.get<any[]>(this.URL + 'list/bitacora/solicitud/' + id + '/page/' + page + '/size/' + size);
    }

}