import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { TcEdNombreCatalogo } from 'src/app/interfaces/tcEdNombreCatalogo.interface';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogoService {

  private urlNombreCat: string = `${api.urlAPI}` + 'api/admin/nombre/catalogo';
  private urlElementoCat: string = `${api.urlAPI}` + 'api/admin/elementos/catalogo';

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  pageNombreCatalogo(page: number, size: number, filters: string): Observable<any> {
    return this.http.get<any>(this.urlNombreCat + '/page/' + page + '/' + size + '/' + filters);
  }

  saveNombreCatalogo(nombreCatalogo: TcEdNombreCatalogo): Observable<TcEdNombreCatalogo> {
    return this.http.post<TcEdNombreCatalogo>(this.urlNombreCat + '/save', nombreCatalogo, { headers: this.httpHeaders })
  }

  deleteNombreCatalogo(nombreCatalogo: TcEdNombreCatalogo): Observable<any> {
    return this.http.post<any>(this.urlNombreCat + '/delete/nombre', nombreCatalogo, { headers: this.httpHeaders });
  }

  getNombreCatalogo(nemonico: string): Observable<TcEdNombreCatalogo> {
    return this.http.get<TcEdNombreCatalogo>(this.urlNombreCat + '/nombre/' + nemonico);
  }

  getCatalogoAreas(): Observable<any[]> {
    return this.http.get<any[]>(this.urlElementoCat + '/area');
  }

  getCatalogoPais(): Observable<any[]> {
    return this.http.get<any[]>(this.urlElementoCat + '/pais');
  }

  getCatalogos(): Observable<any[]> {
    return this.http.get<any[]>(this.urlElementoCat + '/catalogos');
  }

  searchMaster(search: string): Observable<Catalogo> {
    const params = new HttpParams()
      .set('search', search);
    return this.http.get<Catalogo>(this.urlElementoCat + '/master', { params });
  }

  pageElementoCatalogo(page: number, size: number, filters: string): Observable<any> {
    return this.http.get<any>(this.urlElementoCat + '/page/' + page + '/' + size + '/' + filters, { headers: this.httpHeaders });
  }

  getElementoCatalogo(id: string) {
    const params = new HttpParams()
      .set('id', id);
    return this.http.get<Catalogo>(this.urlElementoCat + '/elemento', { params });
  }

  saveElementoCatalogo(catalogo: Catalogo): Observable<any> {
    return this.http.post<any>(this.urlElementoCat + '/save', catalogo, { headers: this.httpHeaders });
  }

  deleteElementoCatalogo(id: string): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.http.delete<any>(this.urlElementoCat, { params });
  }
}
