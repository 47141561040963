import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { CognitoCallback, CognitoUtil, LoggedInCallback } from './cognito.service';
import { AuthenticationDetails, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import * as STS from 'aws-sdk/clients/sts';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class UserLoginService {

    private onLoginSuccess = (callback: CognitoCallback, session: CognitoUserSession) => {

        AWS.config.credentials = this.cognitoUtil.buildCognitoCreds(session.getIdToken().getJwtToken());

        const jwtDecoded = this.getDecodedAccessToken(session.getIdToken().getJwtToken());

        localStorage.setItem('tokenHeader', jwtDecoded['tokenHeader']);

        const clientParams: any = {};
        if (environment.sts_endpoint) {
            clientParams.endpoint = environment.sts_endpoint;
        }
        const sts = new STS(clientParams);
        sts.getCallerIdentity(function (err: any, data: any) {
            callback.cognitoCallback('', session);
        });
    }

    private onLoginError = (callback: CognitoCallback, err: any) => {
        callback.cognitoCallback(err.message, null);
    }

    constructor(public cognitoUtil: CognitoUtil, public usuarioSesionService: UsuarioSesionService) {
        AWS.config.region = CognitoUtil._REGION;
    }

    authenticate(username: string, password: string, authAuxiliar: string, callback: CognitoCallback) {

        const clientMetadata = {
            authAux: authAuxiliar,
        }

        const authenticationData = {
            Username: username,
            Password: password,
            ClientMetadata: clientMetadata
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: (userAttributes, requiredAttributes) => callback.cognitoCallback(`User needs to set password.`, null),
            onSuccess: result => this.onLoginSuccess(callback, result),
            onFailure: err => this.onLoginError(callback, err),
            mfaRequired: (challengeName, challengeParameters) => {
                callback.handleMFAStep!(challengeName, challengeParameters, (confirmationCode: string) => {
                    cognitoUser.sendMFACode(confirmationCode, {
                        onSuccess: result => this.onLoginSuccess(callback, result),
                        onFailure: err => this.onLoginError(callback, err)
                    });
                });
            }
        });
    }

    forgotPassword(username: string, callback: CognitoCallback) {
        const userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.forgotPassword({
            onSuccess: function () {

            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            },
            inputVerificationCode() {
                callback.cognitoCallback('', null);
            }
        });
    }

    confirmNewPassword(email: string, verificationCode: string, password: string, callback: CognitoCallback) {
        const userData = {
            Username: email,
            Pool: this.cognitoUtil.getUserPool()
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmPassword(verificationCode, password, {
            onSuccess: function () {
                callback.cognitoCallback('', null);
            },
            onFailure: function (err) {
                callback.cognitoCallback(err.message, null);
            }
        });
    }

    logout() {
        this.cognitoUtil.getCurrentUser()!.signOut();

    }

    isAuthenticated(callback: LoggedInCallback) {
        if (callback == null) {
            throw ('UserLoginService: Callback in isAuthenticated() cannot be null');
        }

        const cognitoUser = this.cognitoUtil.getCurrentUser();


        if (!this.usuarioSesionService || !this.usuarioSesionService.usuario) {
            callback.isLoggedIn('Cant retrieve the CurrentUser', false);
        }

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err: any, session: any) {
                if (err) {
                    callback.isLoggedIn(err, false);
                } else {
                    callback.isLoggedIn(err, session.isValid());
                }
            });
        } else {
            callback.isLoggedIn('Cant retrieve the CurrentUser', false);
        }
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }
}
