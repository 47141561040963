import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginService } from '../../../services/user-login.service';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../../services/cognito.service';
import { UsuarioParametrosService } from 'src/app/services/usuario-parametros.service';
import { TokenDTO } from 'src/app/interfaces/token_dto.interface';
import { MfaUserService } from 'src/app/services/mfa-user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements CognitoCallback, LoggedInCallback, OnInit {

    loginProcess: Boolean;
    email: string;
    pw: string;
    errorMessage: string;
    mfaStep = false;
    mfaData = {
        destination: '',
        callback: (code: string) => {}
    };
    capLockOn = false;
    validarMfa = false;
    intentosMfa = 5;
    intentos = 5;
    authAuxiliar = 'sms';
    tiempoMfa = '3';
    validarCodigo: boolean = false;

    constructor(public router: Router,
        public userService: UserLoginService,
        private usuarioParametrosService: UsuarioParametrosService,
        private mfaService: MfaUserService) {
        this.loginProcess = false;
    }

    ngOnInit() {
        this.errorMessage = '';
        this.userService.isAuthenticated(this);
    }

    onLogin() {
        this.loginProcess = true;
        if (this.email == null || this.pw == null) {
            this.errorMessage = 'All fields are required';
            this.loginProcess = false;
            return;
        }
        this.errorMessage = '';
        this.userService.authenticate(this.email, this.pw, this.authAuxiliar, this);
    }

    async cognitoCallback(message: string, result: any) {
        if (message !== '') {
            this.loginProcess = false;
            this.errorMessage = message;
            if (this.errorMessage === 'User is not confirmed.') {
                this.router.navigate(['ediscovery/confirm', this.email]);
            } else if (this.errorMessage === 'User does not exist.') {
                this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
            } else if (this.errorMessage === 'Incorrect username or password.') {
                this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
            } else if (this.errorMessage === 'User needs to set password.') {
                this.router.navigate(['ediscovery-new-pass']);
            } else if (this.errorMessage === 'Invalid code or auth state for the user.') {
                this.intentos = this.intentos - 1;
                this.errorMessage = 'El token de autenticación es incorrecto. Le quedan: ' + this.intentos + ' intentos';
                this.validarIntentos();
            } else if (this.errorMessage === 'Too many invalid credentials attempts. User temporarily locked. Please try again after few seconds.') {
                this.intentos = this.intentos - 1;
                this.errorMessage = 'Demasiados intentos de credenciales no válidas. El usuario se bloqueó temporalmente. Inténtalo de nuevo después de unos segundos.';
                this.validarIntentos();
            } else if (this.errorMessage === 'Invalid session for the user, session is expired.') {
                this.errorMessage = 'Sesión no válida para el usuario, la sesión expiró. Inicie sesión nuevamente.';
            } else if (this.errorMessage === 'The server did not understand the operation that was requested') {
                this.errorMessage = 'El servidor no entendió la operación que se solicitó.';
            }
        } else {
            await this.usuarioParametrosService.getdatosGMfa().then(async (result: any) => {
                this.validarMfa = result.mfa_general;

                if (result.mfa_general) {
                    this.mfaService.isMfaOptions().then(async (resp: any) => {
                        if (resp == false) {
                            await this.obtnerParamtrosUsuario();
                        } else {
                            this.router.navigate(['ediscovery-mfa']);
                        }
                    });
                } else {
                    await this.obtnerParamtrosUsuario();
                }

            })
            .catch((err) => {
                console.error('Error al obtener los datos generales del MFA: ', err);
            });
        }
    }

    validarIntentos() {
        if (this.intentos <= 0) {
            //this.mfaStep = false;
            this.errorMessage = 'Inicie sesión nuevamente para generar un nuevo token de autenticación.';
            this.intentos = this.intentosMfa;
        }
        this.validarCodigo =  false;
    }

    async obtnerParamtrosUsuario() {
        await this.usuarioParametrosService.getParametros().then((parametros: any) => {
            if (parametros.estado && parametros.idUsuario != null || parametros.perfil != null) {
                localStorage.setItem('usuario', JSON.stringify(parametros));
                this.usuarioParametrosService.getToken(parametros.idUsuario).subscribe((resp: TokenDTO) => {
                    localStorage.setItem('tokenHeader', resp.tokenHeader);
                });
                this.router.navigate(['/home/inicio']);
            } else if (!parametros.estado) {
                this.loginProcess = false;
                this.errorMessage = 'Usuario inactivo, contacte a Mexico_LegalUSUARIOS@hsbc.com.mx';
            } else {
                this.loginProcess = false;
                this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
            }
        });
    }

    handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
        this.mfaStep = true;
        var nCadena: string = '';
        
        if (challengeParameters.CODE_DELIVERY_DESTINATION != null || challengeParameters.CODE_DELIVERY_DESTINATION != undefined) {
            const caracteres = challengeParameters.CODE_DELIVERY_DESTINATION.split('');
            if (caracteres.length >= 4) {
                caracteres[caracteres.length - 3] = '*';
                caracteres[caracteres.length - 4] = '*';
                nCadena = caracteres.join('');
            } else {
                nCadena = challengeParameters.CODE_DELIVERY_DESTINATION;
            }
        }
        
        this.mfaData.destination = nCadena;

        if (this.authAuxiliar == 'email') {
            this.mfaData.destination = 'Correo electrónico';
        }

        this.mfaData.callback = (code: string) => {
            this.validarCodigo =  true;
            if (code == null || code.length === 0) {
                this.errorMessage = 'Es necesario capturar el token de autenticación, para continuar.';
                //this.mfaData.callback = this.errorMessage;
                this.validarCodigo =  false;
                return;
            }
            this.errorMessage = '';
            callback(code);
        };
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/home/inicio']);
        }
    }

    cancelMFA(): boolean {
        this.mfaStep = false;
        //detener el spinner
        this.loginProcess = false;
        // borrar credenciales
        this.email = '';
        this.pw = '';

        this.errorMessage = '';
        this.authAuxiliar = 'sms';
        return false;
    }

    capLock(e: KeyboardEvent) {
        const kc = e.keyCode ? e.keyCode : e.which;
        const sk = e.shiftKey ? e.shiftKey : ((kc === 16) ? true : false);
        if (((kc >= 65 && kc <= 90) && !sk) || ((kc >= 97 && kc <= 122) && sk)) {
            this.capLockOn = true;
        } else {
            this.capLockOn = false;
        }
    }

    newMfaCode() {
        this.mfaStep = false;
        //detener el spinner
        this.loginProcess = false;
        // borrar credenciales
        this.email = '';
        this.pw = '';
        
        this.errorMessage = '';
        this.authAuxiliar = 'email';
    }

}
