import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { UsuariosTramite } from 'src/app/interfaces/usuarios-tramite.interface';
import { ArchivoService } from 'src/app/services/archivo.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';
import { HomePathService } from 'src/app/services/home-path.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';


@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html'
})
export class ConfirmacionComponent implements OnInit {

  proyectoInformacion: any;
  ownersInformation: any;
  responsablesInformation: any;
  usuariosExternosInformation: any;
  archivos: any;
  guardarFlag: boolean = false;
  homePath: string;
  proyectoSave: any;
  display: boolean = false;
  usuariosTramite: UsuariosTramite[] = [];
  listaCorreosNot: string[] = [];





  constructor(private loaderService: LoaderService,
    private router: Router,
    public proyectoInformacionService: ProyectoInformacionService,
    private proyectoService: ProyectoService,
    public usuarioSesionService: UsuarioSesionService,
    private messageService: MessageService,
    private archivoService: ArchivoService,
    private homePathService: HomePathService,
    private bitacoraPSService: BitacoraProyectoSolService,
    private notificacionesService: NotificacionService) { }

  ngOnInit(): void {
    this.homePath = this.homePathService.getHomePath();
    this.proyectoInformacion = this.proyectoInformacionService.datosProyecto
    this.ownersInformation = this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto
    this.responsablesInformation = this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto
    this.usuariosExternosInformation = this.proyectoInformacionService.datosProyecto.usuariosFirmaExterna
    this.archivos = this.proyectoInformacionService.datosProyecto.archivosProyecto
  }

  showDialog() {
    this.display = true;
  }

  cancel() {
    this.display = false;
  }

  prevPage() {
    this.router.navigate([this.homePath + '/proyecto/proyecto/adjuntos']);
  }

  async confirm() {
    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;
    const proyecto$ = this.proyectoService.saveProyecto(this.proyectoInformacionService.datosProyecto);
    this.proyectoSave = await lastValueFrom(proyecto$);
    if (this.proyectoInformacionService.datosProyecto.archivosProyecto.length > 0) {
      for await (const arch of this.proyectoInformacionService.datosProyecto.archivosProyecto) {
        arch.cveElemento = this.proyectoSave.id + "";
        if (this.homePath.includes('-internal')) {
          await this.archivoService.uploadFile(arch.documento, "PROYECTO-" + arch.cveElemento).toPromise().catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          }).finally(async () => {
            this.archivoService.saveFile(arch).toPromise();
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.proyectoSave.id,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-1',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: null
            };

            const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
            await lastValueFrom(bitacora$);
          });

        } else {
          await this.archivoService.uploadFiles2(arch.documento, "PROYECTO/" + arch.cveElemento).promise().then(async () => {
            await lastValueFrom(this.archivoService.saveFile(arch));
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.proyectoSave.id,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-1',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: null
            };
            await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
          }).catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          });
        }

      }
    }
    const bitacora = {
      cveBitacora: null,
      descAccion: 'Registro de Proyecto',
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.proyectoSave.id,
      tipoMovimiento: {
        id: 'TIPMO-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
    await lastValueFrom(bitacora$);

    this.ownersInformation.forEach(ow => {
      if (ow.indNotificacion) {
        this.listaCorreosNot.push(ow.correo);
      }

      const usuariosTramites = {
        numReg: ow.cveUsuario,
        nombre: ow.nombre,
        correoElectronico: ow.correo,
        lob: ow.lob,
        rol: ow.tipoRol
      }
      this.usuariosTramite.push(usuariosTramites);
    });

    this.responsablesInformation.forEach(rp => {
      if (rp.indNotificacion) {
        this.listaCorreosNot.push(rp.correo);
      }

      const usuariosTramites = {
        numReg: rp.cveUsuario,
        nombre: rp.nombre,
        correoElectronico: rp.correo,
        lob: rp.lob,
        rol: rp.tipoRol
      }
      this.usuariosTramite.push(usuariosTramites);
    });

    if (this.usuariosExternosInformation != null) {
      this.usuariosExternosInformation.forEach(ex => {
        if (ex.indNotificacion) {
          this.listaCorreosNot.push(ex.correo);
        }
      });
    }

    const mailDTO = {
      usuariosMail: this.listaCorreosNot,
      subject: "Notificación alta de proyecto",
      infoMailDTO: {
        numFolio: this.proyectoSave.numFolio,
        titulo: this.proyectoSave.nombreProyecto,
        descripcion: this.proyectoSave.descripcionProyecto,
        usuariosTramite: this.usuariosTramite
      }
    }

    const sendEmail$ = this.notificacionesService.notificarAltaProyecto(mailDTO);
    await lastValueFrom(sendEmail$);

    const bitacoraEmail = {
      cveBitacora: null,
      descAccion: 'Envío correo electrónico alta proyecto número de folio: ' + this.proyectoSave.numFolio +
        ' titulo del proyecto: ' + this.proyectoSave.nombreProyecto +
        ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.proyectoSave.id,
      tipoMovimiento: {
        id: 'TIPMO-5',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmail);
    await lastValueFrom(bitacoraEmail$);

    this.loaderService.isLoading.next(false);
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El proyecto fue creado con éxito, número de folio: ' + this.proyectoSave.numFolio + ' ya puedes crear solicitudes dando click en el botón Crear solicitud en la parte inferior de la pagina', life: 6000 });
    this.router.navigate([this.homePath + '/proyecto/gestionProyecto', this.proyectoSave.id])

  }

}


