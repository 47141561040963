import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UsuarioSesionService } from '../services/usuario-sesion.service';
import { Usuario } from '../interfaces/usuario_session.interface';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService,
        private router: Router,
        private usuarioSesion: UsuarioSesionService) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let currentUser: Usuario;
        let validate = false;
        //const roles = route.data[0].roles;

        const promise = new Promise((resolve) => {
            resolve(this.authService.isAuthenticatedPromise());
        });

        const result = await promise;

        if (result && this.usuarioSesion && this.usuarioSesion.usuario) {
            currentUser = this.usuarioSesion.usuario;
            validate = true;
        } else {
            this.router.navigate(['/ediscovery/login']);
        }

        /*if (roles) {
            if (roles.includes(currentUser.rol.trim()) === false) {
                validate = false;
                this.router.navigate(['/acces-denied']);
            }
        }*/

        const browser = this.getBrowserName();
        if (browser === 'edge' || browser === 'ie') {
            alert('Navegador no compatible...');
            this.router.navigate(['/browser-error']);
        }

        return validate;
    }

    public getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase();
        switch (true) {
            case agent.indexOf('edg') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }
}
