import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HomePathService } from 'src/app/services/home-path.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-browser-error',
  templateUrl: './browser-error.component.html'
})
export class BrowserErrorComponent implements OnInit {

  homePath: string;
  mensaje: string;

  constructor(
    private titleService: Title,
    private homePathService: HomePathService,
    private _location: Location) { }

  ngOnInit() {
    this.titleService.setTitle('HSBC eDiscovery-Navegador incompatible');
    this.homePath = this.homePathService.getHomePath();
    if (environment.ROUTES.includes('INTERNAL')) {
      this.mensaje = 'Tú navegador es incompatible, usa Chrome, si no lo tienes, solicítalo a través Service Now.';
    } else {
      this.mensaje = 'Tú navegador es incompatible, usa Chrome.';
    }
  }

}
