import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JefeUsuario } from 'src/app/interfaces/jefeUsuario.interface';
import { Usuario } from 'src/app/interfaces/usuario.interface';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private urlEndPoint: string = `${api.urlAPI}` + 'api/admin/usuario/';

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  getUsuarioPage(page: number, size: number, filters: string): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + 'page/' + page + '/' + size + '/' + filters);
  }

  getUsuario(numReg: string): Observable<Usuario> {
    return this.http.get<Usuario>(this.urlEndPoint + numReg);
  }

  getJefes(search: string): Observable<JefeUsuario[]> {
    return this.http.get<JefeUsuario[]>(this.urlEndPoint + 'jefes/' + search);
  }

  guardarActualizarUsuario(usuario: any): Observable<any> {
    return this.http.post<any>(this.urlEndPoint + 'save', usuario, { headers: this.httpHeaders });
  }

  deleteUsuario(numReg: string) {
    return this.http.delete(this.urlEndPoint + 'delete/' + numReg);
  }

  bitacoraUsuario(num_reg: string): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + 'bitacora/' + num_reg);
  }
  bitacora(): Observable<any> {
    return this.http.get<any>(this.urlEndPoint + 'bitacora');
  }

}
