import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ModuloRol } from 'src/app/interfaces/moduloRol.interface';
import { Rol } from 'src/app/interfaces/rol.interface';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { RolesService } from './roles.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html'
})
export class RolesComponent implements OnInit {

  rolMod!: any[];
  roles!: Rol[];
  rolSlc: any;
  usuarioSlc: any;
  usrMod: any[] = [];
  usuarios!: any[];
  booleanModulos!: boolean;
  modulos!: any;

  constructor(
    private rolesService: RolesService,
    private messageService: MessageService,
    public rolModuloService: RolModuloService,
    public usuarioSesionService: UsuarioSesionService,
    private confirmationService: ConfirmationService
  ) {
    this.rolSlc = {
      rolId: 0,
      descripcion: '',
      status: false

    };
  }

  ngOnInit(): void {
    this.cargarDatos();
  }

  async cargarDatos() {
    const response = await this.rolesService.getListRoles().toPromise();
    this.roles = response ? response : [];
  }

  async showDlgAddMod() {
    this.booleanModulos = true;
    this.rolMod = await this.rolesService.pageModulosByRol(this.rolSlc.rolId).toPromise();
  }

  async cargaModPerfil($event: any) {
    this.rolSlc = $event.value;
    this.modulos = await this.rolesService.getModulosByRol(this.rolSlc.rolId).toPromise();
    this.rolMod = await this.rolesService.pageModulosByRol(this.rolSlc.rolId).toPromise();
  }


  async addModuloRol(modulo: any) {
    await this.rolesService.addModuloToRol(this.rolSlc.rolId, modulo.moduloId).toPromise().then(
      data => {
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Modulo agregado al Rol', life: 4000 });
      }
    ).catch(
      error => { this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 }); }
    );
    this.modulos = await this.rolesService.getModulosByRol(this.rolSlc.rolId).toPromise();
    this.rolMod = await this.rolesService.pageModulosByRol(this.rolSlc.rolId).toPromise();

  }

  async lectura(moduloRol: ModuloRol) {
    this.rolesService.updateModuloRol(moduloRol).subscribe(
      (data: any) => {
        const element = (element) => element.moduloRolId === moduloRol.moduloRolId;
        const index = this.rolMod.findIndex(element);
        this.rolMod[index] = data;
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 4000 });
      }
    );
  }

  async delModuloRol(modulo: ModuloRol) {
    this.confirmationService.confirm({
      header: '¡Atención!',
      message: '¿Estás seguro de eliminar el módulo: ' + modulo.modulo.descripcion + '?',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        await this.rolesService.deleteModuloRol(modulo.moduloRolId).toPromise();
        this.rolMod = await this.rolesService.pageModulosByRol(this.rolSlc.rolId).toPromise();
        this.modulos = await this.rolesService.getModulosByRol(this.rolSlc.rolId).toPromise();
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 4000 });
      }, reject: () => {
        this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
      }
    });
  }


}
