import { Component, Input } from "@angular/core";

@Component({
    selector: 'awscognito-mfa',
    templateUrl: './mfa.component.html'
})
export class MfaComponent {

    @Input() destination: string;
    @Input() onSubmit: (code: string) => void;
    @Input() errorMessage: string;
    @Input() userName: string;
    @Input() tiempoMfa: string;
    @Input() validarCodigo: boolean;

    constructor() { }

}