import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Observable, throwError } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';
import { api } from '../../environments/environment';
import { PersonaAdam } from '../interfaces/persona_adam.interface';
import { Tokens } from '../model/tokens';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private loggedUser: string;

  constructor(private http: HttpClient) { }

  login(user: { usr: string, pwd: string }): Observable<PersonaAdam> {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*'
    });
    return this.http.post<PersonaAdam>(api.urlAdamAPI + 'login_ed', user, {
      headers: headers
    })
      .pipe(
        tap(adam => this.doLoginUser(user.usr, adam)),
        mapTo(200),
        catchError(error => {
          return of(error.status);
        }));
  }

  loginInt(user: { usr: string, pwd: string }): Observable<PersonaAdam> {
    return this.http.post<PersonaAdam>(api.urlAdamAPI + 'login_ed', user);
  }

  logout() {
    const body = {
      'jwt': this.getJwtToken()
    };
    return this.http.post<any>(api.urlAPI + 'api/adam/logout', body)
      .pipe(
        tap(() => this.doLogoutUser()),
        mapTo(true),
        catchError(error => {
          return of(false);
        }));
  }

  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken(): Observable<Tokens> {
    const body = {
      'token': this.getJwtToken(),
      'refreshToken': this.getRefreshToken()
    };
    return this.http.post<any>(api.urlAPI + 'api/adam/refresh_token', body).pipe(tap((tokens) => {
      if (tokens.status !== 403) {
        this.storeTokens(tokens);
      }
    }),
      catchError(error => {
        const tokenError = {} as Tokens;
        tokenError.status = 401;
        return of(tokenError);
      }));
  }

  refreshToken2() {
    const body = {
      'token': this.getJwtToken(),
      'refreshToken': this.getRefreshToken()
    };
    return this.http.post<any>(api.urlAPI + 'api/adam/refresh_token', body).pipe(tap((tokens) => {
      this.storeTokens(tokens);
    }));
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  doLoginUser(username: string, adam: any) {
    this.loggedUser = username;
    const tokens: Tokens = {
      status: 200,
      token: adam.userDetails.token,
      refreshToken: adam.userDetails.refreshToken
    };
    this.storeTokens(tokens);
    this.storeUserDetails(adam);
  }

  private doLogoutUser() {
    this.loggedUser = '';
    this.removeTokens();
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('JWT_TOKEN', jwt);
    // localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem('JWT_TOKEN', tokens.token);
    localStorage.setItem('REFRESH_TOKEN', tokens.refreshToken);
    // localStorage.setItem(this.JWT_TOKEN, tokens.token);
    // localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private storeUserDetails(adam: any) {
    const userDetails = {
      depto: adam.userDetails.cveDepto,
      telef: adam.userDetails.telefono,
      direc: adam.userDetails.direccion
    };
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem('usuario');
    localStorage.clear();
  }
}
