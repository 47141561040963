import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { LoggedInCallback } from 'src/app/services/cognito.service';

@Component({
    selector: 'app-logout',
    template: ''
})
export class LogoutComponent implements LoggedInCallback {

    constructor(private authService: AuthService, private router: Router) {
        this.authService.isAuthenticated(this);
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.authService.logout();
            this.router.navigate(['/ediscovery/login']);
        }
    }
}
