import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TemplateCheckList } from 'src/app/interfaces/templateCheckList';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TemplateChecklistService {

  private url: string = `${api.urlAPI}` + 'api/admin/template';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });
  constructor(private http: HttpClient) { }

  pageTamplateChecklist(page: number, size: number, filters: string, id: string) {
    return this.http.get<any>(this.url + '/page/' + page + '/' + size + '/' + filters + '/' + id);
  }

  getTemplateCheckList(id: string): Observable<any> {
    this.httpHeaders.append('id', 1 + '');
    return this.http.get<any>(this.url + '/get', { headers: this.httpHeaders });
  }

  saveTemplate(templateCheck: TemplateCheckList): Observable<TemplateCheckList> {
    return this.http.post<TemplateCheckList>(this.url + '/save', templateCheck);
  }

  deleteTemplete(id: number): Observable<any> {
    return this.http.delete<any>(this.url + '/delete/' + id);
  }

}
