import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoCallback } from '../../../services/cognito.service';
import { UserRegistrationService } from '../../../services/user-registration.service';

@Component({
  selector: 'app-reenviar-codigo',
  templateUrl: './reenviar-codigo.component.html',
  styleUrls: ['./reenviar-codigo.component.css']
})
export class ReenviarCodigoComponent implements OnInit, CognitoCallback {

  loginProcess: Boolean;
  errorMessage: string;
  formGroup: FormGroup;
  showError: boolean;

  constructor(public usuarioRegistroService: UserRegistrationService, public router: Router) {
    this.showError = false;
    this.loginProcess = false;
  }

  ngOnInit() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [
        Validators.required
      ])
    });
  }

  onResendCode() {
    this.loginProcess = true;
    console.log('UsuarioReenviarCodigo: onResendCode: ');
    console.log('email: ' + this.formGroup.value['email']);
    this.usuarioRegistroService.resendCode(this.formGroup.value['email'], this);
  }

  cognitoCallback(message: string, result: any): void {
    this.loginProcess = false;
    if (message != null) {
      this.showError = true;
      this.errorMessage = message;
      console.log('UsuarioConfirmacionComponent: error: ');
      console.log(message);
    } else {
      console.log('UsuarioConfirmacionComponent: Confirmacion correcta: Redireccionando...');
      this.router.navigate(['/']);
    }
  }

}
