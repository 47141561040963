import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { api } from 'src/environments/environment';
import { Proyecto } from "../interfaces/proyecto.interface";
import { catchError, Observable, throwError, map } from "rxjs";
import { SearchUsuario } from "../interfaces/search_usuario.interface";
import { MessageService } from "primeng/api";
import { UsuarioSolicitud } from "../interfaces/usuario-solicitud.interface";


const HEADERS = new HttpHeaders({
    'Content-Type': 'application/json'
});
@Injectable()
export class ProyectoService {

    URL = `${api.urlAPI}` + 'api/proyecto/';

    constructor(private http: HttpClient,
        private messageService: MessageService) { }

    saveProyecto(proyecto: Proyecto): Observable<Proyecto> {
        return this.http.post(this.URL, proyecto).pipe(
            map((response: any) => response.proyecto as Proyecto),
            catchError(error => {
                return throwError(error);
            })
        );
    }

    searchUsuariosName(nombre: string, NotPerfiles: number[], pais: string): Observable<SearchUsuario[]> {
        return this.http.post<SearchUsuario[]>(this.URL + 'userName/' + nombre + '/' + pais, NotPerfiles);
    }

    searchUsuarioRegistro(numRegistro: String, NotPerfiles: number[], pais: string): Observable<SearchUsuario> {
        return this.http.post<SearchUsuario>(this.URL + 'userRegistro/' + numRegistro + '/' + pais, NotPerfiles);
    }

    datosUsuario(numRegistro: string): Observable<SearchUsuario> {
        return this.http.get<SearchUsuario>(this.URL + 'datosUsuario/' + numRegistro).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    obtenerProyecto(id: String): Observable<Proyecto> {
        return this.http.get<Proyecto>(this.URL + id).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    obtenerDatosUsuario(numReg: String): Observable<UsuarioSolicitud> {
        return this.http.get<UsuarioSolicitud>(this.URL + 'datosUsuario/' + numReg).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    updateEstados(datosProyecto: any) {
        return this.http.put(this.URL + 'estados/', datosProyecto);
    }

    updateDatosProyecto(datosProyecto: any) {
        return this.http.put(this.URL + 'datosProyecto/', datosProyecto);
    }

    saveUserProyect(usuario: any) {
        return this.http.post(this.URL + 'userProyecto/', usuario);
    }

    updateUsuarioProyecto(usuarioProyecto: any) {
        return this.http.put(this.URL + 'usuario/', usuarioProyecto);
    }

    eliminarUsuarioProyecto(id: any) {
        return this.http.delete(this.URL + 'usuario/' + id);
    }

    isPermiso(idUsuario: String, idProyecto: number): Observable<boolean> {
        return this.http.get<boolean>(this.URL + 'isPermiso/' + idUsuario + '/' + idProyecto);
    }

}
