import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { SearchUsuario } from 'src/app/interfaces/search_usuario.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-responsables',
  templateUrl: './responsables.component.html'
})
export class ResponsablesComponent implements OnInit {

  responsablesProyecto: SearchUsuario[] = [];
  usuariosFirmaExterna: SearchUsuario[] = [];
  tpExternoUs: SelectItem[] = [];
  tpRespRel: SelectItem[] = [];
  tpUserExtlSlc: any;
  tpRespRelSlc: any;
  relacionar: any;
  relacionarExterno: any;
  numRegistroInput: any;
  numRegistroInputExterno: any;
  results: any;
  resultsExterno: any;
  ownersVerificar: OwnersOrResponsables[] = [];
  responsablesInformation: OwnersOrResponsables[] = [];
  externosInformation: OwnersOrResponsables[] = [];
  respoRegistro: any;
  extRegistro: any;
  tipoRolResp: string = 'TIPRO-2';
  tipoRolExt: string = 'TIPRO-3';
  homePath: string;
  numRegUserSesion: string;
  perfilesNoPermitidosResponsable: number[] = [];
  perfilesNoPermitidosExternos: number[] = [];



  constructor(private router: Router,
    public proyectoInformacionService: ProyectoInformacionService,
    private proyectoService: ProyectoService,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService) {

  }

  ngOnInit(): void {
    this.homePath = this.homePathService.getHomePath();
    this.tpRespRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpRespRelSlc = 'nombre'

    this.tpExternoUs = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpUserExtlSlc = 'nombre'

    this.perfilesNoPermitidosResponsable.push(6);
    this.perfilesNoPermitidosResponsable.push(3);


    this.perfilesNoPermitidosExternos.push(1);
    this.perfilesNoPermitidosExternos.push(5);
    this.perfilesNoPermitidosExternos.push(4);
    this.perfilesNoPermitidosExternos.push(3);

    this.numRegUserSesion = this.usuarioSesionService.usuario.idUsuario;
    this.ownersVerificar = this.proyectoInformacionService.datosProyecto.usuariosOwnerProyecto
    this.responsablesInformation = this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto
    this.externosInformation = this.proyectoInformacionService.datosProyecto.usuariosFirmaExterna

    if (this.responsablesInformation != null) {
      this.responsablesInformation.forEach(rp => {
        const respo: SearchUsuario = {
          numRegistro: rp.cveUsuario,
          nombre: rp.nombre,
          indNotificacion: rp.indNotificacion,
          correo: rp.correo,
          lob: rp.lob
        }
        this.responsablesProyecto.push(respo);
      });
    } else {
      this.responsablesInformation = [];
    }

    if (this.externosInformation != null) {
      this.externosInformation.forEach(rp => {
        const respo: SearchUsuario = {
          numRegistro: rp.cveUsuario,
          nombre: rp.nombre,
          indNotificacion: rp.indNotificacion,
          correo: rp.correo,
          lob: rp.lob
        }
        this.usuariosFirmaExterna.push(respo);
      });
    } else {
      this.externosInformation = [];
    }

  }

  async searchResponsables(event: any) {
    // console.log("entrando a search " + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosResponsable, this.usuarioSesionService.usuario.pais).subscribe(
      resp => this.results = resp
    );

  }

  async searchUsuarioExterno(event: any) {
    //  console.log("entrando a search externo" + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosExternos, this.usuarioSesionService.usuario.pais).subscribe(
      resp => this.resultsExterno = resp
    );

  }

  async agregarResponsable() {

    if (this.tpRespRelSlc === 'nombre') {
      if (this.relacionar != null && this.relacionar.numRegistro != null) {
        const respoTemp = {
          numRegistro: this.relacionar.numRegistro,
          nombre: this.relacionar.nombre,
          indNotificacion: this.relacionar.indNotificacion,
          correo: this.relacionar.correo,
          lob: this.relacionar.lob
        }
        this.relacionar = null;
        if (this.responsablesProyecto.length > 0) {
          let existeRespo = this.responsablesProyecto.find(num => num.numRegistro === respoTemp.numRegistro);
          let existeOwner = this.ownersVerificar.find(num => num.cveUsuario === respoTemp.numRegistro);
          if (existeOwner != null || existeRespo != null) {
            // console.log("valor existe es verdadero");
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue seleccionado como owner o responsable', life: 6000 });
          } else {
            // console.log("agregamos al owner el array ya fue inicializado");
            this.responsablesProyecto.push(respoTemp);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Owner agregado.', life: 6000 });
          }


        } else {
          let existeOwner = this.ownersVerificar.find(num => num.cveUsuario === respoTemp.numRegistro);
          if (existeOwner != null) {
            //   console.log("valor existe es verdadero");
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue seleccionado como owner o responsable', life: 6000 });
          } else {
            //  console.log("valor nuevo ")
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Responsable agregado.', life: 6000 });
            this.responsablesProyecto.push(respoTemp);
          }

        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un owner.', life: 6000 });
      }
    } else {
      if (this.numRegistroInput != null && this.numRegistroInput.length > 0) {
        this.loaderService.isLoading.next(true);
        if (this.responsablesProyecto.length > 0) {
          let existeR = this.responsablesProyecto.find(num => num.numRegistro === this.numRegistroInput);
          let existeO = this.ownersVerificar.find(num => num.cveUsuario === this.numRegistroInput);
          if (existeR != null || existeO != null) {
            //  console.log("valor existe es verdadero desde registro");
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado como Owner o Responsable', life: 6000 });
          } else {
            const respoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInput, this.perfilesNoPermitidosResponsable, this.usuarioSesionService.usuario.pais);
            this.respoRegistro = await lastValueFrom(respoRegistro$).catch((e) => {
              //   console.log("cacheando error en component :")
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
              this.numRegistroInput = null;
              this.loaderService.isLoading.next(false);
            });
            //  console.log("agregamos al responsable el array ya fue inicializado estamos en modo registro num " + this.respoRegistro.numRegistro);
            this.responsablesProyecto.push(this.respoRegistro);
            this.respoRegistro = null;
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Responsable agregado.', life: 6000 });
          }
        } else {
          let existeO = this.ownersVerificar.find(num => num.cveUsuario === this.numRegistroInput);
          if (existeO != null) {
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado como owner o responsable', life: 6000 });
          } else {
            //   console.log("voy a ir al micro responsables " + this.numRegistroInput)
            const respoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInput, this.perfilesNoPermitidosResponsable, this.usuarioSesionService.usuario.pais);
            this.respoRegistro = await lastValueFrom(respoRegistro$).catch((e) => {
              //  console.log("cacheando error en component :")
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
              this.numRegistroInput = null;
              this.loaderService.isLoading.next(false);
            });

            this.responsablesProyecto.push(this.respoRegistro);
            this.respoRegistro = null;
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Responsable agregado.', life: 6000 });
          }
        }
      }
      else {
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el numero de registro', life: 6000 });
      }
    }

  }

  async agregarExterno() {

    if (this.tpUserExtlSlc === 'nombre') {
      if (this.relacionarExterno != null && this.relacionarExterno.numRegistro != null) {
        const externoTemp = {
          numRegistro: this.relacionarExterno.numRegistro,
          nombre: this.relacionarExterno.nombre,
          indNotificacion: this.relacionarExterno.indNotificacion,
          correo: this.relacionarExterno.correo,
          lob: this.relacionarExterno.lob
        }
        this.relacionarExterno = null;
        if (this.usuariosFirmaExterna.length > 0) {
          let existeExterno = this.usuariosFirmaExterna.find(num => num.numRegistro === externoTemp.numRegistro);
          if (existeExterno != null) {
            //     console.log("valor existe es verdadero");
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado', life: 6000 });
          } else {
            //     console.log("agregamos al externo el array ya fue inicializado");
            this.usuariosFirmaExterna.push(externoTemp);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario externo agregado', life: 6000 });
          }


        } else {

          //    console.log("valor nuevo ")
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario externo agregado', life: 6000 });
          this.usuariosFirmaExterna.push(externoTemp);


        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No ha seleccionado un usuario.', life: 6000 });
      }
    } else {
      if (this.numRegistroInputExterno != null && this.numRegistroInputExterno.length > 0) {
        this.loaderService.isLoading.next(true);
        if (this.usuariosFirmaExterna.length > 0) {
          let existeE = this.usuariosFirmaExterna.find(num => num.numRegistro === this.numRegistroInputExterno);
          if (existeE != null) {
            //  console.log("valor existe es verdadero desde registro");
            this.numRegistroInputExterno = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue agregado', life: 6000 });
          } else {
            const externoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInputExterno, this.perfilesNoPermitidosExternos, this.usuarioSesionService.usuario.pais);
            this.extRegistro = await lastValueFrom(externoRegistro$).catch((e) => {
              //      console.log("cacheando error en component :")
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
              this.numRegistroInputExterno = null;
              this.loaderService.isLoading.next(false);
            });
            //     console.log("agregamos al responsable el array ya fue inicializado estamos en modo registro num " + this.respoRegistro.numRegistro);
            this.usuariosFirmaExterna.push(this.extRegistro);
            this.extRegistro = null;
            this.numRegistroInputExterno = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario externo agregado.', life: 6000 });
          }
        } else {

          const externoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInputExterno, this.perfilesNoPermitidosExternos, this.usuarioSesionService.usuario.pais);
          this.extRegistro = await lastValueFrom(externoRegistro$).catch((e) => {
            //      console.log("cacheando error en component :")
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
            this.numRegistroInputExterno = null;
            this.loaderService.isLoading.next(false);
          });

          //      console.log("agregamos al responsable el array ya fue inicializado estamos en modo registro num " + this.respoRegistro.numRegistro);
          this.usuariosFirmaExterna.push(this.extRegistro);
          this.extRegistro = null;
          this.numRegistroInputExterno = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario externo agregado.', life: 6000 });

        }
      }
      else {
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el numero de registro', life: 6000 });
      }
    }

  }

  eliminaRe(usuario: SearchUsuario) {
    this.responsablesProyecto = this.responsablesProyecto.filter(function (value, index) {
      //   console.log("entro a eliminarlo en local");
      return value.numRegistro !== usuario.numRegistro;
    });

    this.responsablesInformation = this.responsablesInformation.filter(function (value, index) {
      //   console.log("entro a eliminarlo en global");
      return value.cveUsuario !== usuario.numRegistro
    });
    //  console.log("numero de registros " + this.responsablesInformation.length)
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario eliminado.', life: 6000 });
  }

  eliminaEx(usuario: SearchUsuario) {
    this.usuariosFirmaExterna = this.usuariosFirmaExterna.filter(function (value, index) {
      // console.log("entro a eliminarlo en local");
      return value.numRegistro !== usuario.numRegistro;
    });

    this.externosInformation = this.externosInformation.filter(function (value, index) {
      //   console.log("entro a eliminarlo en global");
      return value.cveUsuario !== usuario.numRegistro
    });
    // console.log("numero de registros " + this.externosInformation.length)
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario eliminado.', life: 6000 });
  }

  prevPage() {
    if (this.responsablesProyecto.length > 0) {
      //   console.log("entro donde no deberia!!!!::")
      this.responsablesProyecto.forEach(rp => {
        const respo: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: rp.nombre,
          cveUsuario: rp.numRegistro,
          tipoRol: this.tipoRolResp,
          indNotificacion: rp.indNotificacion,
          correo: rp.correo,
          lob: rp.lob
        }
        let existe = this.responsablesInformation.find(num => num.cveUsuario === respo.cveUsuario);
        if (existe != null) {
          //      console.log("ya existia antes de regresar");
          this.responsablesInformation.forEach(cam => {
            if (cam.cveUsuario == respo.cveUsuario) {
              if (cam.indNotificacion != respo.indNotificacion) {
                cam.indNotificacion = respo.indNotificacion
              }
            }
          })
        } else { this.responsablesInformation.push(respo); }
      });
      this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto = this.responsablesInformation;

    } else {
      this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto = []
    }

    if (this.usuariosFirmaExterna.length > 0) {
      //     console.log("entro donde no deberia!!!!::")
      this.usuariosFirmaExterna.forEach(ex => {
        const externo: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: ex.nombre,
          cveUsuario: ex.numRegistro,
          tipoRol: this.tipoRolExt,
          indNotificacion: ex.indNotificacion,
          correo: ex.correo,
          lob: ex.lob
        }
        let existe = this.externosInformation.find(num => num.cveUsuario === externo.cveUsuario);
        if (existe != null) {
          //     console.log("ya existia antes de regresar");
          this.externosInformation.forEach(cam => {
            if (cam.cveUsuario == externo.cveUsuario) {
              if (cam.indNotificacion != externo.indNotificacion) {
                cam.indNotificacion = externo.indNotificacion
              }
            }
          })
        } else { this.externosInformation.push(externo); }
      });
      this.proyectoInformacionService.datosProyecto.usuariosFirmaExterna = this.externosInformation;

    } else {
      this.proyectoInformacionService.datosProyecto.usuariosFirmaExterna = []
    }

    this.router.navigate([this.homePath + '/proyecto/proyecto/owners']);
  }

  nextPage() {

    if (this.usuariosFirmaExterna.length > 0) {

      this.usuariosFirmaExterna.forEach(ex => {
        const externos: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: ex.nombre,
          cveUsuario: ex.numRegistro,
          tipoRol: this.tipoRolExt,
          indNotificacion: ex.indNotificacion,
          correo: ex.correo,
          lob: ex.lob
        }
        let existe = this.externosInformation.find(num => num.cveUsuario === externos.cveUsuario);
        if (existe != null) {
          //      console.log("ya existia antes de regresar");
          this.externosInformation.forEach(cam => {
            if (cam.cveUsuario == externos.cveUsuario) {
              if (cam.indNotificacion != externos.indNotificacion) {
                cam.indNotificacion = externos.indNotificacion
              }
            }
          })
        } else {
          this.externosInformation.push(externos);
        }
      });
      this.proyectoInformacionService.datosProyecto.usuariosFirmaExterna = this.externosInformation;


    }

    if (this.responsablesProyecto.length > 0) {

      this.responsablesProyecto.forEach(rp => {
        const respo: OwnersOrResponsables = {
          id: null,
          cveProyecto: null,
          nombre: rp.nombre,
          cveUsuario: rp.numRegistro,
          tipoRol: this.tipoRolResp,
          indNotificacion: rp.indNotificacion,
          correo: rp.correo,
          lob: rp.lob
        }
        let existe = this.responsablesInformation.find(num => num.cveUsuario === respo.cveUsuario);
        if (existe != null) {
          //     console.log("ya existia antes de regresar");
          this.responsablesInformation.forEach(cam => {
            if (cam.cveUsuario == respo.cveUsuario) {
              if (cam.indNotificacion != respo.indNotificacion) {
                cam.indNotificacion = respo.indNotificacion
              }
            }
          })
        } else {
          this.responsablesInformation.push(respo);
        }
      });
      this.proyectoInformacionService.datosProyecto.usuariosResponsablesProyecto = this.responsablesInformation;
      this.router.navigate([this.homePath + '/proyecto/proyecto/adjuntos'])

    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar al menos 1 responsable para el proyecto', life: 6000 });
    }


  }

}
