import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AwsUtil } from './services/aws.service';
import { CognitoUtil, LoggedInCallback } from './services/cognito.service';
import { HomePathService } from './services/home-path.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit, LoggedInCallback {

  homePath: string;

  constructor(
    public awsUtil: AwsUtil,
    private homePathService: HomePathService,
    public userService: AuthService,
    public cognito: CognitoUtil,
    private router: Router) { }

  ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    if (!this.homePath.includes('-internal')) {
      this.userService.isAuthenticated(this);
    }
    const browser = this.getBrowserName();
    if (browser === 'edge' || browser === 'ie') {
      alert('Navegador no compatible...');
      this.router.navigate(['/browser-error']);
    }
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    const mythis = this;
    this.cognito.getIdToken({
      callback() {

      },
      callbackWithParam(token: any) {
        mythis.awsUtil.initAwsService(null!, isLoggedIn, token);
      }
    });
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

}
