import { Injectable } from '@angular/core';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
@Injectable({
    providedIn: 'root'
})
export class RolModuloService {

    constructor(
        private usuarioSesionService: UsuarioSesionService) { }

    validaModulo(modulo: string) {
        const modlo = this.usuarioSesionService.usuario.modulos!.find(obj => obj.modulo === modulo);
        if (modlo) {
            return true;
        } else {
            return false;
        }
    }

    validaLectura(modulo: string) {
        const modlo = this.usuarioSesionService.usuario.modulos!.find(obj => obj.modulo === modulo);
        if (modlo) {
            if (modlo.lectura) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

}
