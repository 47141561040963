import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthTokenService } from 'src/app/services/authToken.service';
import { PerfilesService } from 'src/app/services/perfiles.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login-internal',
  templateUrl: './login.component.html'
})
export class LoginInternalComponent implements OnInit {

  loginFormGroup: FormGroup;
  validReCaptcha: boolean;
  loginProcess: Boolean;
  errorMessage: string;
  showError = false;
  capLockOn = false;

  fecha: Date;
  version: string;
  ambiente: string;

  constructor(
    private titleService: Title,
    private authTokenService: AuthTokenService,
    private router: Router,
    private perfilesService: PerfilesService) {
    this.validReCaptcha = false;
    this.fecha = new Date();
    this.version = environment.version;
    this.ambiente = environment.ambiente;
  }

  ngOnInit() {
    this.titleService.setTitle('HSBC REQUEST-Acceso');

    this.loginProcess = false;
    this.loginFormGroup = new FormGroup({
      user: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)])
    });
  }

  async onLogin() {
    this.loginProcess = true;
    localStorage.clear();
    this.errorMessage = '';

    this.authTokenService.loginInt(
      {
        usr: this.loginFormGroup.value.user,
        pwd: this.loginFormGroup.value.password
      }
    )
      .subscribe(
        (response: any) => {
          if (response.status === 'OK' || response.status === 200) {
            this.authTokenService.doLoginUser(this.loginFormGroup.value.user, response);
            this.perfilesService.getPerfilUsuario(this.loginFormGroup.value.user).subscribe(parametros => {
              if (parametros.estado && parametros.idUsuario != null || parametros.rol != null) {
                localStorage.setItem('usuario', JSON.stringify(parametros));
                this.router.navigate(['/home-internal/inicio']);
              } else if (!parametros.estado) {
                this.loginProcess = false;
                this.errorMessage = 'Usuario inactivo, contacte a Mexico_LegalUSUARIOS@hsbc.com.mx';
              } else {
                this.loginProcess = false;
                this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
              }
            });
          } else {
            this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
            this.loginProcess = false;
          }
        },
        error => {
          this.errorMessage = 'Usuario y/o Contraseña incorrectos.';
          this.loginProcess = false;
        });

  }

  capLock(e: KeyboardEvent) {
    const kc = e.keyCode ? e.keyCode : e.which;
    const sk = e.shiftKey ? e.shiftKey : ((kc === 16) ? true : false);
    if (((kc >= 65 && kc <= 90) && !sk) || ((kc >= 97 && kc <= 122) && sk)) {
      this.capLockOn = true;
    } else {
      this.capLockOn = false;
    }
  }

}
