import { Injectable } from '@angular/core';
import { Callback, CognitoUtil } from './cognito.service';
import * as AWS from 'aws-sdk/global';

@Injectable()
export class AwsUtil {

    public static firstLogin: Boolean = false;
    public static runningInit: Boolean = false;

    constructor(public cognitoUtil: CognitoUtil) {
        AWS.config.region = CognitoUtil._REGION;
    }

    initAwsService(callback: Callback, isLoggedIn: boolean, idToken: string) {

        if (AwsUtil.runningInit) {
            if (callback != null) {
                callback.callback();
                callback.callbackWithParam(null);
            }
            return;
        }

        AwsUtil.runningInit = true;

        const myThis = this;
        if (isLoggedIn) {
            myThis.setupAWS(isLoggedIn, callback, idToken);
        }

    }

    setupAWS(isLoggedIn: boolean, callback: Callback, idToken: string): void {
        if (isLoggedIn) {
            this.addCognitoCredentials(idToken);
        } else {
        }

        if (callback != null) {
            callback.callback();
            callback.callbackWithParam(null);
        }

        AwsUtil.runningInit = false;
    }

    addCognitoCredentials(idTokenJwt: string): void {
        const creds = this.cognitoUtil.buildCognitoCreds(idTokenJwt);

        AWS.config.credentials = creds;

        creds.get(function (err) {
            if (!err) {
                if (AwsUtil.firstLogin) {
                    AwsUtil.firstLogin = false;
                }
            }
        });
    }
}
