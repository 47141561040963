// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  version: '1.19',
  ambiente: 'Producción',
  sts_endpoint: '',
  ROUTES: 'ROUTES',
  account: '504715484607'
};

/** Interface para conectar con los servicios de AWS */
export const cognito = {
  userPoolId: 'us-east-1_2ok4uVrJE',
  clientId: '4ust1stdj2pnd9o728vdgcbhm2',
  region: 'us-east-1',
  identityPoolId: 'us-east-1:91c0758f-b97e-4579-937d-4951fac1f2fc',
  cognito_idp_endpoint: '',
  cognito_identity_endpoint: ''
};

export const api = {
  urlAPI: 'https://api.prod.us-east-1.lts.cloud1.vv1865.com/irq/',
  urlArchivo: 'https://api.prod.us-east-1.lts.cloud1.vv1865.com/irq/',
  urlAdamAPI: 'https://aa.lts.use1.prod.aws.cloud.hsbc:8003/',
  urlHoganAPI: 'https://aa.lts.use1.prod.aws.cloud.hsbc:8002/'
};

export const kms = {
  s3: 'arn:aws:kms:us-east-1:060377882555:key/d4ea1dca-0cab-4266-aa99-51ed365a8bfc'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
