import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { Router } from '@angular/router';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { Proyecto } from 'src/app/interfaces/proyecto.interface';

@Component({
  selector: 'app-proyecto',
  templateUrl: './proyecto.component.html'
})
export class ProyectoComponent implements OnInit {


  loading!: boolean;
  dlgFolio!: boolean;

  items!: MenuItem[];



  constructor(private loaderService: LoaderService,
    private messageService: MessageService,
    private router: Router,
    public proyectoInformacionService: ProyectoInformacionService
  ) {


  }

  ngOnInit(): void {
    //this.loaderService.isLoading.next(true);  
    this.items = [{
      label: 'Datos del proyecto',
      routerLink: 'datos'
    },
    {
      label: 'Owners del proyecto',
      routerLink: 'owners'
    },
    {
      label: 'Responsables del proyecto',
      routerLink: 'responsables'
    },
    {
      label: 'Documentos adjuntos',
      routerLink: 'adjuntos'
    },
    {
      label: 'Confirmación',
      routerLink: 'confirmacion'
    },
    {
      label: 'Gestión del proyecto',
      routerLink: 'gestion'
    }

    ]

    //  this.loaderService.isLoading.next(false);

  }

  ngOnDestroy() {
    this.proyectoInformacionService.datosProyecto = {} as Proyecto;
  }


  guardarSolicitud() {

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Proyecto guardado.', life: 6000 });
    this.dlgFolio = true;

  }

  cerrarAsignacion() {
    this.dlgFolio = false;
    this.router.navigate(['/home/inicio']);
  }


}
