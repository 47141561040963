import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { api } from "src/environments/environment";
import { Todo } from "../interfaces/todo.interface";

@Injectable({
    providedIn: 'root'
})
export class BandejaService {

    URL = `${api.urlAPI}` + 'api/bandeja/';

    constructor(private http: HttpClient) { }

    getBandejaSolicitud(designado: string, estatus: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/solicitud/' + designado + '/estatus/' + estatus + '/page/' + page + '/size/' + size);
    }

    getBandejaProyecto(usuario: string, estatus: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/proyecto/' + usuario + '/estatus/' + estatus + '/page/' + page + '/size/' + size);
    }

    getCountProyectos(user: string, estatus: string) {
        return this.http.get(this.URL + 'count/proyect/' + user + '/estatus/' + estatus);
    }

    getCountSolicitudes(user: string, estatus: string) {
        return this.http.get(this.URL + 'count/request/' + user + '/estatus/' + estatus);
    }

    getBandejaProyectoAdmin(query: string, pais: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/proyecto/' + query + '/pais/' + pais + '/page/' + page + '/size/' + size);
    }

    getBandejaProyectoUsuarioM(query: string, numReg: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/proyecto/' + query + '/usuarioM/' + numReg + '/page/' + page + '/size/' + size);
    }

    getBandejaProyectoAdminEstatus(pais: string, estatus: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/proyectoPais/' + pais + '/estatus/' + estatus + '/page/' + page + '/size/' + size);
    }

    getBandejaSolicitudUsuarioM(query: string, numReg: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'list/consulta/solicitud/' + query + '/usuarioM/' + numReg + '/page/' + page + '/size/' + size);
    }

}