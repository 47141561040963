import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem, MessageService } from 'primeng/api';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { lastValueFrom } from 'rxjs';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PersonaSolicitud } from 'src/app/interfaces/personaSolicitud.interface';
import { HomePathService } from 'src/app/services/home-path.service';

@Component({
  selector: 'app-persona-solicitud',
  templateUrl: './persona-solicitud.component.html'
})
export class PersonaSolicitudComponent implements OnInit {

  catAreas: Catalogo[] = [];
  catAreaIndefinida: Catalogo[] = [];
  tpNombreRegSlc: any;
  designados: PersonaSolicitud[] = [];
  results: any;
  relacionar: any;
  numRegistroInput: any;
  designadoRegistro: any;
  grupoPersonasInformacion: PersonaSolicitud[] = [];
  homePath: string;



  tpPersonaRel: SelectItem[] = [];
  asignarPersonas: boolean = false;
  asignarSolicitud: any[] = [];
  dlgReasignar!: boolean;




  constructor(private router: Router,
    private messageService: MessageService,
    private loaderService: LoaderService,
    public solicitudInformacionService: SolicitudInformacionService,
    private catalogoService: CatalogoService,
    private solicitudService: SolicitudService,
    private homePathService: HomePathService) {


  }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.grupoPersonasInformacion = this.solicitudInformacionService.datosSolicitud.asignados;

    if (this.grupoPersonasInformacion != null) {
      this.grupoPersonasInformacion.forEach(de => {

        this.designados.push(de);
      });
    } else {
      this.grupoPersonasInformacion = [];
    }

    this.asignarPersonas = false

    const p1$ = this.catalogoService.getCatalogosNemonico('CTARE', true);
    this.catAreas = await lastValueFrom(p1$);

    const p2$ = this.catalogoService.getCatalogosNemonico('TIPGA', true);
    this.catAreaIndefinida = await lastValueFrom(p2$);

    this.catAreaIndefinida = this.catAreaIndefinida.concat(this.catAreas);

    this.tpPersonaRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];

    this.tpNombreRegSlc = "nombre";


  }

  async searchDesignados(event: any) {
    //  console.log("entrando a search " + event.query);
    this.solicitudService.searchUsuariosSolicitudName(event.query).subscribe(
      users => this.results = users
    );

  }

  async cargaPersonaSol($event: any) {

    //  console.log('capturando evento ' + $event.value.id)
    if ($event.value.desDetcat == "INDEFINIDO") {
      this.asignarPersonas = true
      this.designados = []
      this.grupoPersonasInformacion = [];
    } else {
      this.asignarPersonas = false
      this.designados = []
      this.grupoPersonasInformacion = [];
      this.loaderService.isLoading.next(true);
      const p1$ = this.solicitudService.obtenerUsuariosArea($event.value.id);
      this.designados = await lastValueFrom(p1$);
      this.loaderService.isLoading.next(false);
    }

  }

  async agregarDesignado() {

    if (this.tpNombreRegSlc === 'nombre') {
      if (this.relacionar != null && this.relacionar.cveUsuario != null) {
        const designadoTemp = {
          cveUsuario: this.relacionar.cveUsuario,
          idSolicitud: null,
          nombre: this.relacionar.nombre,
          grupoArea:
          {
            id: "TIPGA-1",
            desNemonico: "",
            cveDetcat: null,
            desDetcat: "INDEFINIDO",
            desDetcor: "",
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          }, lob:
          {
            id: this.relacionar.lob.id,
            desNemonico: this.relacionar.lob.desNemonico,
            cveDetcat: this.relacionar.lob.cveDetcat,
            desDetcat: this.relacionar.lob.desDetcat,
            desDetcor: this.relacionar.lob.desDetcor,
            staRegis: this.relacionar.lob.staRegis,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          correo: this.relacionar.correo
        }
        this.relacionar = null;

        let existeUser = this.designados.find(num => num.cveUsuario === designadoTemp.cveUsuario);

        if (existeUser != null) {
          //   console.log("valor existeUser es verdadero");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue agregado como designado', life: 6000 });
        } else {
          //    console.log("agregamos al owner el array ya fue inicializado");
          this.designados.push(designadoTemp);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Designado agregado.', life: 6000 });
        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un usuario.', life: 6000 });
      }
    } else {
      if (this.numRegistroInput != null && this.numRegistroInput.length > 0) {
        this.loaderService.isLoading.next(true);

        let existe = this.designados.find(num => num.cveUsuario === this.numRegistroInput);

        if (existe != null) {
          //     console.log("valor exist Designado es verdadero desde registro");
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue agregado como designado', life: 6000 });
        } else {
          const designadoRegistro$ = this.solicitudService.searchUsuarioSolicitudRegistro(this.numRegistroInput);
          this.designadoRegistro = await lastValueFrom(designadoRegistro$);

          const designadoTemp = {
            cveUsuario: this.designadoRegistro.cveUsuario,
            idSolicitud: null,
            nombre: this.designadoRegistro.nombre,
            grupoArea:
            {
              id: "TIPGA-1",
              desNemonico: "",
              cveDetcat: null,
              desDetcat: "INDEFINIDO",
              desDetcor: "",
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            }, lob:
            {
              id: this.designadoRegistro.lob.id,
              desNemonico: this.designadoRegistro.lob.desNemonico,
              cveDetcat: this.designadoRegistro.lob.cveDetcat,
              desDetcat: this.designadoRegistro.lob.desDetcat,
              desDetcor: this.designadoRegistro.lob.desDetcor,
              staRegis: this.designadoRegistro.lob.staRegis,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            correo: this.designadoRegistro.correo
          }

          //    console.log("agregamos al designado el array ya fue inicializado estamos en modo registro num " + this.designadoRegistro.cveUsuario);
          this.designados.push(designadoTemp);
          this.designadoRegistro = null;
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Designado agregado.', life: 6000 });

        }

      }
      else {
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el numero de registro', life: 6000 });
      }
    }


  }

  eliminaDesig(usuario: PersonaSolicitud) {
    this.designados = this.designados.filter(function (value, index) {
      //  console.log("entro a eliminarlo en local");
      return value.cveUsuario !== usuario.cveUsuario;
    });

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Usuario eliminado.', life: 6000 });
  }

  prevPage() {
    if (this.designados.length > 0) {
      this.designados.forEach(de => {

        let existe = this.grupoPersonasInformacion.find(num => num.cveUsuario === de.cveUsuario);
        if (existe == null) {
          this.grupoPersonasInformacion.push(de);
        }
      });
      this.solicitudInformacionService.datosSolicitud.asignados = this.grupoPersonasInformacion;
      this.router.navigate([this.homePath + '/proyecto/solicitud/datos']);
    } else {
      this.solicitudInformacionService.datosSolicitud.asignados = [];
      this.router.navigate([this.homePath + '/proyecto/solicitud/datos']);
    }
  }

  nextPage() {
    if (this.designados.length > 0) {
      //  console.log("hay usuarios designados los meto a sesion")
      this.designados.forEach(de => {

        let existe = this.grupoPersonasInformacion.find(num => num.cveUsuario === de.cveUsuario);
        if (existe == null) {
          //   console.log("no existe lo meto a sesion")
          this.grupoPersonasInformacion.push(de);
        }
      });
      this.solicitudInformacionService.datosSolicitud.asignados = this.grupoPersonasInformacion;
      this.router.navigate([this.homePath + '/proyecto/solicitud/checklist'])
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar al menos 1 designado para la solicitud', life: 6000 });
    }
  }

}
