import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { Proyecto } from 'src/app/interfaces/proyecto.interface';
import { UsuariosTramite } from 'src/app/interfaces/usuarios-tramite.interface';
import { ArchivoService } from 'src/app/services/archivo.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';
import { HomePathService } from 'src/app/services/home-path.service';
import { LoaderService } from 'src/app/services/loader.service';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-confirmacion-solicitud',
  templateUrl: './confirmacion-solicitud.component.html'
})
export class ConfirmacionSolicitudComponent implements OnInit {

  solicitudInformacion: any;
  asignadosInformation: any;
  checkListInformation: any;
  archivos: any;
  archivosProyecto: any;
  guardarFlag: boolean = false;
  homePath: string;
  solicitudSave: any;
  datosProyecto!: Proyecto;
  display: boolean = false;
  listaCorreosNot: string[] = [];
  usuariosTramite: UsuariosTramite[] = [];



  constructor(private router: Router,
    public solicitudInformacionService: SolicitudInformacionService,
    private solicitudService: SolicitudService,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private homePathService: HomePathService,
    private archivoService: ArchivoService,
    public usuarioSesionService: UsuarioSesionService,
    private bitacoraPSService: BitacoraProyectoSolService,
    private proyectoService: ProyectoService,
    private notificacionesService: NotificacionService) { }



  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.solicitudInformacion = this.solicitudInformacionService.datosSolicitud;
    this.asignadosInformation = this.solicitudInformacionService.datosSolicitud.asignados
    this.checkListInformation = this.solicitudInformacionService.datosSolicitud.checkList
    this.archivos = this.solicitudInformacionService.datosSolicitud.archivosSolicitud
    this.archivosProyecto = this.solicitudInformacionService.datosSolicitud.archivosProyectoVisible
    // console.log("fecha limite: " + this.solicitudInformacion.fechaLimite)

    const datosProyecto$ = this.proyectoService.obtenerProyecto(this.solicitudInformacionService.datosSolicitud.idProyecto + '');
    this.datosProyecto = await lastValueFrom(datosProyecto$);

  }

  showDialog() {
    this.display = true;
  }

  cancel() {
    this.display = false;
  }

  prevPage() {
    this.router.navigate([this.homePath + '/proyecto/solicitud/documentos']);
  }

  async confirm() {
    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;

    const solicitud$ = this.solicitudService.saveSolicitud(this.solicitudInformacionService.datosSolicitud);
    this.solicitudSave = await lastValueFrom(solicitud$);

    if (this.datosProyecto.estadoProyecto.desDetcat == 'EN PROGRESO') {
      const datosProyecto = {
        id: this.datosProyecto.id,
        estadoProyecto: {
          id: "EDPRO-3"
        }
      }

      const cerrar$ = this.proyectoService.updateEstados(datosProyecto);
      await lastValueFrom(cerrar$);
    }
    if (this.solicitudInformacionService.datosSolicitud.archivosSolicitud.length > 0) {
      for await (const arch of this.solicitudInformacionService.datosSolicitud.archivosSolicitud) {
        arch.cveElemento = this.solicitudSave.idSolicitud + "";
        if (this.homePath.includes('-internal')) {
          await this.archivoService.uploadFile(arch.documento, "SOLICITUD-" + arch.cveElemento).toPromise().catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          }).finally(async () => {
            this.archivoService.saveFile(arch).toPromise();
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + + arch.documento.size + ' ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.solicitudSave.idSolicitud,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.solicitudSave.idProyecto
            };

            const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
            await lastValueFrom(bitacora$);
          });

        } else {
          await this.archivoService.uploadFiles2(arch.documento, "SOLICITUD/" + arch.cveElemento).promise().then(async () => {
            await lastValueFrom(this.archivoService.saveFile(arch));
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + + arch.documento.size + ' ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.solicitudSave.idSolicitud,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.solicitudSave.idProyecto
            };
            await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
          }).catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          });
        }
      }
    }

    for await (const archProyec of this.solicitudInformacionService.datosSolicitud.archivosProyectoVisible) {
      //    console.log("entro a actualizar archivos proyecto para hacerlos visibles" + archProyec.visible)
      //  if(archProyec.visible == true){
      if (archProyec.visible == null) {
        archProyec.visible = false;
      }
      //    console.log("entro a actualizar a: " + archProyec.nombre)
      //    console.log("entro a actualizar id doc: " + archProyec.cveDoc)
      if (archProyec.cveDoc != null) {
        //     console.log("entro a actualizar id doc: " + archProyec.cveDoc)
        const archivoP$ = this.archivoService.updateVisible(archProyec.cveDoc, this.solicitudSave.idSolicitud + "", archProyec.visible!)
        await lastValueFrom(archivoP$);
      }
    }

    //  }

    const bitacora = {
      cveBitacora: null,
      descAccion: 'Registro de solicitud folio: ' + this.solicitudSave.numFolio,
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.solicitudSave.idSolicitud,
      tipoMovimiento: {
        id: 'TIPMO-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-2',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: this.solicitudSave.idProyecto
    };

    const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
    await lastValueFrom(bitacora$);

    const datosProyecto$ = this.proyectoService.obtenerProyecto(this.solicitudInformacionService.datosSolicitud.idProyecto + "");
    this.datosProyecto = await lastValueFrom(datosProyecto$);

    for await (const ow of this.datosProyecto.usuariosOwnerProyecto) {
      const onerRegistro$ = this.proyectoService.datosUsuario(ow.cveUsuario);
      let ownerRegistro = await lastValueFrom(onerRegistro$);
      if (ow.indNotificacion) {
        this.listaCorreosNot.push(ownerRegistro.correo);
      }
    }

    for await (const rp of this.datosProyecto.usuariosResponsablesProyecto) {
      const responsableRegistro$ = this.proyectoService.datosUsuario(rp.cveUsuario);
      let responsableRegistro = await lastValueFrom(responsableRegistro$);
      if (rp.indNotificacion) {
        this.listaCorreosNot.push(responsableRegistro.correo);
      }
    }

    for await (const ex of this.datosProyecto.usuariosFirmaExterna) {
      const externoRegistro$ = this.proyectoService.datosUsuario(ex.cveUsuario);
      let externoRegistro = await lastValueFrom(externoRegistro$);
      if (ex.indNotificacion) {
        this.listaCorreosNot.push(externoRegistro.correo);
      }
    }

    this.solicitudInformacionService.datosSolicitud.asignados.forEach(ds => {
      this.listaCorreosNot.push(ds.correo);

      const usuariosTramites = {
        numReg: ds.cveUsuario,
        nombre: ds.nombre!,
        correoElectronico: ds.correo,
        lob: ds.lob.desDetcat,
        rol: ''
      }
      this.usuariosTramite.push(usuariosTramites);
    });

    const mailDTO = {
      usuariosMail: this.listaCorreosNot,
      subject: "Notificación alta de solicitud",
      infoMailDTO: {
        numFolio: this.solicitudSave.numFolio,
        titulo: this.solicitudSave.nombreSolicitud,
        descripcion: this.solicitudSave.descripcion,
        fechaLimite: this.solicitudSave.fechaLimite,
        usuariosTramite: this.usuariosTramite
      }
    }

    const sendEmail$ = this.notificacionesService.notificarAltaSolicitud(mailDTO);
    await lastValueFrom(sendEmail$);

    const bitacoraEmail = {
      cveBitacora: null,
      descAccion: 'Envío correo electrónico alta solicitud número de folio: ' + this.solicitudSave.numFolio +
        ' titulo de la solicitud: ' + this.solicitudSave.nombreSolicitud + ' descripción ' + this.solicitudSave.descripcion +
        ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.solicitudSave.idSolicitud,
      tipoMovimiento: {
        id: 'TIPMO-5',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-2',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: this.solicitudSave.idProyecto
    };

    const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmail);
    await lastValueFrom(bitacoraEmail$);

    this.router.navigate([this.homePath + '/proyecto/gestionProyecto', this.solicitudSave.idProyecto])
    this.loaderService.isLoading.next(false);
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue creada con éxito, número de folio: ' + this.solicitudSave.numFolio, life: 9000 });
  }

}
