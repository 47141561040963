import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HomePathService } from 'src/app/services/home-path.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent implements OnInit {

  homePath: string;

  constructor(
    private titleService: Title,
    private homePathService: HomePathService,
    private _location: Location) { }

  ngOnInit() {
    this.titleService.setTitle('HSBC REQUEST-Acceso Denegado');
    this.homePath = this.homePathService.getHomePath();
  }

  regresar() {
    this._location.back();
  }

}
