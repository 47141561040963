import { Injectable } from '@angular/core';
import { api } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { MailDatos } from '../interfaces/mailt-datos-ps.interface';
import { Comentarios } from '../interfaces/comentarios.interface';
import { MessageService } from 'primeng/api';
import { LoaderService } from './loader.service';
import { catchError, Observable, throwError, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class NotificacionService {

    URL = `${api.urlAPI}` + 'api/notificaciones/';

    constructor(private http: HttpClient,
        private messageService: MessageService,
        private loaderService: LoaderService) { }

    notificarAltaProyecto(datosMail: MailDatos) {
        return this.http.post(this.URL + "alta/proyecto/", datosMail);
    }

    notificarAltaSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "alta/solicitud/", datosMail);
    }

    notificarComentarioSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "comentarios/solicitud/", datosMail);
    }

    notificarRechazoSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "rechazo/solicitud/", datosMail);
    }

    notificarRetiroSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "retiro/solicitud/", datosMail);
    }

    notificarDevueltaSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "devuelta/solicitud/", datosMail);
    }

    notificarRespuestaSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "respuesta/solicitud/", datosMail);
    }

    notificarCierreSolicitud(datosMail: MailDatos) {
        return this.http.post(this.URL + "cierre/solicitud/", datosMail);
    }

    notificarCierreProyecto(datosMail: MailDatos) {
        return this.http.post(this.URL + "cierre/proyecto/", datosMail);
    }

    notificarAbrirProyecto(datosMail: MailDatos) {
        return this.http.post(this.URL + "abrir/proyecto/", datosMail);
    }

    notificarCancelarProyecto(datosMail: MailDatos) {
        return this.http.post(this.URL + "cancelar/proyecto/", datosMail);
    }

}