import { Component, Input, OnInit } from '@angular/core';
import { City } from 'src/app/interfaces/mfa-info';
import { MfaUserService } from 'src/app/services/mfa-user.service';
import { UsuarioParametrosService } from 'src/app/services/usuario-parametros.service';
import { ConfirmationService, MessageService, LazyLoadEvent } from 'primeng/api';
import { Router } from '@angular/router';

/**
 * Esta clase se encarga de enviar el numero telefónico y llamar a las funciones para activar el MFA en cognito
 * @author 45265254
 */
@Component({
  selector: 'awscognito-mfa-configuracion',
  templateUrl: './mfa-configuracion.component.html'
})
export class MfaConfiguracionComponent implements OnInit {

  @Input() errorMessage: string;
  message: string;

  phoneNumber: string;
  verifyCode: string;

  cities: City[];
  selectedCity: string;

  intentos = 0;
  intentosMfa = 0;
  showVerifyConf = false;
  showGenerateNewCode: boolean = false;
  loginProcess: Boolean;

  constructor(public router: Router,
    private mfaService: MfaUserService,
    private usuarioParametrosService: UsuarioParametrosService,
    private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.loginProcess = false;
    this.cities = [
      { name: 'México +52', code: '+52' },
      { name: 'Argentina +54', code: '+54' }
    ];
    this.iniciaform();
  }

  iniciaform() {
    this.selectedCity = '';
    this.phoneNumber = '';
  }

  /**
   * Esta funcion permite obtener el codigo del pais y el numero de telefono para poder enviarlo a cognito y desencadenar la verificacion del numero telefonico
   * @param code Codigo del país +52 para México, +54 para argentina 
   * @param phone numero de telefono
   * @returns void
   */
  async validarTelefono(code: string, phone: string) {
    this.loginProcess = true;
    if (code == null || phone == null || phone.length == 0) {
      this.errorMessage = 'Todos los campos son obligatorios';
      this.loginProcess = false;
      return;
    } else {
      const number = code.concat(phone);
      this.errorMessage = '';
      this.message = '';

      await this.usuarioParametrosService.getdatosGMfa().then((result: any) => {
        this.intentosMfa = result.intentos_codigo;
        this.intentos = result.intentos_codigo;
      });

      this.confirmationService.confirm({
        message: '¿El número de teléfono <b>' + phone + '</b> es correcto?',
        header: 'Advertencia',
        icon: 'pi pi-exclamation-triangle',
        rejectVisible: false,
        acceptLabel: 'Aceptar',
        accept: async () => {

          const userAttributes = await this.mfaService.getMfaOptions();

          let phoneVerified = false;
          let phoneMfa: string = '';

          userAttributes.forEach(attr => {
            if (attr.Name === 'phone_number') {
              phoneMfa = attr.Value;
            }
            if (attr.Name === 'phone_number_verified' && attr.Value === 'true') {
              phoneVerified = true;
            }
          });

          if (phoneMfa == number) {
            if (phoneVerified) {
              const enableMfa = await this.mfaService.enableMfaSms();
              if (enableMfa) {
                this.showVerifyConf = false;
                this.message = 'El número de teléfono ha sido verificado con anterioridad. MFA activado correctamente, sera redireccionado para iniciar sesión ';
                setTimeout(() => { this.router.navigate(['home/logout']); }, 5000);
              } else {
                this.errorMessage = 'No fue posible activar el segundo método de autenticación, intente de nuevo. ';
              }
              this.loginProcess = false;
            } else {
              await this.mfaService.sendPhoneNumberP(number)
                .then(async (parametros: any) => {
                  this.showVerifyConf = true;
                })
                .catch((err) => {
                  this.errorMessage = 'No fue posible activar el segundo método de autenticación, intente de nuevo. ';
                  console.error('Error al verificar el número telefonico: ', err);
                }).finally(() => {
                  this.loginProcess = false;
                });
            }
          } else {
            await this.mfaService.sendPhoneNumberP(number)
              .then(async (parametros: any) => {
                this.showVerifyConf = true;
              })
              .catch((err) => {
                this.errorMessage = 'No fue posible activar el segundo método de autenticación, intente de nuevo. ';
                console.error('Error al verificar el número telefonico: ', err);
              }).finally(() => {
                this.loginProcess = false;
              });
          }

        }
      });

    }

  }

  /**
   * Esta función permite envira el token de autenticación para validarlo y poder activar el MFA para el usuario
   * @param verifyCode token de autenticación  
   */
  async validarCodigo(verifyCode: string) {
    if (verifyCode == null) {
      this.errorMessage = 'Es necesario capturar el token de verificación, para continuar.';
      this.showVerifyConf = true;
    } else {
      if (this.intentos == 0) {
        this.showGenerateNewCode = true;
        this.errorMessage = 'Ingresa de nuevo tu usuario y contraseña para genera un nuevo token.';
      } else {
        const verify = await this.mfaService.verifyPhone(verifyCode);

        if (verify) {
          const enableMfa = await this.mfaService.enableMfaSms();

          if (enableMfa) {
            this.showVerifyConf = false;
            this.intentos = this.intentosMfa;
            this.message = 'MFA activado correctamente, sera redireccionado para iniciar sesión ';
            setTimeout(() => { this.router.navigate(['home/logout']); }, 5000);
          } else {
            this.errorMessage = 'No fue posible activar el Segundo método de autenticación, intente de nuevo.';
          }
        } else {
          this.intentos = this.intentos - 1;
          this.errorMessage = 'El token de autenticación es incorrecto. Le quedan: ' + this.intentos + ' intentos';
          if (this.intentos == 0) {
            this.showGenerateNewCode = true;
            this.errorMessage = 'Ingresa de nuevo tu usuario y contraseña para genera un nuevo token.';
          }
        }

      }
    }

  }

  /**
   * Esta función devuelve al usuario al inicio de sesión para generar  un nuevo token 
   */
  reSendMfaCode(): void {
    this.router.navigate(['/ediscovery/login']);
  }

}
