import { Component } from '@angular/core';
import { AuthTokenService } from 'src/app/services/authToken.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutInternalComponent {

  constructor(private authService: AuthTokenService, private router: Router) {
    this.authService.logout()
      .subscribe(success => {
        this.router.navigate(['/ediscovery-internal/login']);
      },
        (error) => {
          this.router.navigate(['/ediscovery-internal/login']);
        },
        () => {
          localStorage.clear();
        });
  }

}
