import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { MatrizEscalamiento } from 'src/app/interfaces/matrizEscalamiento.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { MatrizService } from 'src/app/services/matriz.service';

@Component({
  selector: 'app-tabla-escalamiento',
  templateUrl: './tabla-escalamiento.component.html',
  providers: [ConfirmationService, MessageService]
})
export class TablaEscalamientoComponent implements OnInit {

  matrizEscalamientos: any[];
  showDlg: boolean = false;
  matrizEscalamiento?: any;
  showEscala!: boolean;
  fechaVencimiento!: Date;
  findEscala: MatrizEscalamiento;
  results: any;
  backup!: string;
  totalElements!: number;
  paramsPage?: LazyLoadEvent;
  editarEscala$: Subject<any> = new Subject<any>();
  homePath: string;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private matrizService: MatrizService,
    private route: Router,
    private homePathService: HomePathService) { }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
  }

  async searchEscala(event: any) {
    this.results = await this.matrizService.getEscalamiento(event.query).toPromise();
  }

  async loadLazy(event: LazyLoadEvent) {
    this.paramsPage = event;
    let filter = 'none';
    if (event.filters && event.filters['global'] && event.filters['global'].value) {
      filter = event.filters['global'].value;
    }
    let page = event.first! / event.rows!;
    if (this.backup !== null && this.backup !== filter) {
      page = 0;
    }
    await this.matrizService.getEscalamientoPage(page, event.rows!, filter).toPromise().then(
      (res: any) => {
        this.totalElements = res.totalElements;
        this.matrizEscalamientos = res.content
        this.backup = filter;
      }
    );
  }

  showDlgEditar(escalamiento: any) {
    this.matrizEscalamiento = escalamiento;
    this.editarEscala$.next(this.matrizEscalamiento);
    this.showDlg = true;
  }

  closeModalDetected($event: boolean) {
    this.showDlg = $event;
    if (this.paramsPage)
      this.loadLazy(this.paramsPage);
  }

  async delEscala(event: Event, escalamiento: MatrizEscalamiento) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: '¿Estas seguro de borrar el escalamiento?',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.matrizService.delEscalamiento(escalamiento).toPromise()
          .then(() => {
            this.messageService.add({ severity: 'info', summary: 'Eliminado', detail: 'Escalamiento eliminado correctamente' });
            if (this.paramsPage)
              this.loadLazy(this.paramsPage);
          })
          .catch(
            () => {
              this.messageService.add({ severity: 'error', summary: '¡Error!', detail: 'Error al eliminar escalamiento' });
            }
          );
      },
      reject: () => {
      },
      acceptLabel: "Si",
    });
  }

  routeNumFolio(numFolio: number) {
    this.route.navigate([this.homePath + "/proyecto/respuestaSolicitud", numFolio]);
  }
}