import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HomePathService } from 'src/app/services/home-path.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-exp',
  templateUrl: './session-exp.component.html'
})
export class SessionExpComponent implements OnInit {

  homePath: string;

  constructor(
    private titleService: Title,
    private homePathService: HomePathService,
    private router: Router) { }

  ngOnInit() {
    this.titleService.setTitle('HSBC REQUEST-Sesión Expirada');
    this.homePath = this.homePathService.getHomePath();
  }

  regresar() {
    this.router.navigate(['/home-internal/logout']);
  }

}
