import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Solicitud } from "../interfaces/solicitud.interface";

@Injectable()
export class SolicitudInformacionService {

    datosSolicitud = {} as Solicitud;

    private solicitudComplete = new Subject<any>();

    SolicitudComplete$ = this.solicitudComplete.asObservable();

    getSolicitudInformacion() {
        return this.datosSolicitud;
    }

    setProyectoInformacion(solicitudInformacion: any) {
        this.datosSolicitud = solicitudInformacion;
    }

    complete() {
        this.solicitudComplete.next(this.datosSolicitud)
    }

}