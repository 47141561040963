import { Component, OnInit } from '@angular/core';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { CheckList } from 'src/app/interfaces/checklist.interface';
import { MessageService } from 'primeng/api';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { LoaderService } from 'src/app/services/loader.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { lastValueFrom } from 'rxjs';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { HomePathService } from 'src/app/services/home-path.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html'

})
export class ChecklistComponent implements OnInit {

  infoCheck: CheckList[] = [];
  itemsCheckList: CheckList[] = [];
  catCheckListAreas: Catalogo[] = [];
  catCheckListAdmin: Catalogo[] = [];
  otherCheckList: boolean = false;
  actividadInput: any;
  homePath: string;


  constructor(private messageService: MessageService,
    private fb: FormBuilder, private router: Router,
    private solicitudInformacionService: SolicitudInformacionService,
    private loaderService: LoaderService,
    private catalogoService: CatalogoService,
    private solicitudService: SolicitudService,
    private homePathService: HomePathService) {

  }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.infoCheck = this.solicitudInformacionService.datosSolicitud.checkList;

    if (this.infoCheck != null) {
      this.infoCheck.forEach(de => {

        this.itemsCheckList.push(de);
      });
    } else {
      this.infoCheck = [];
    }


    this.otherCheckList = false
    const p1$ = this.catalogoService.getCatalogosNemonico('CTARE', true);
    this.catCheckListAreas = await lastValueFrom(p1$);

    const p2$ = this.catalogoService.getCatalogosNemonico('TIPGC', true);
    this.catCheckListAdmin = await lastValueFrom(p2$);

    this.catCheckListAdmin = this.catCheckListAdmin.concat(this.catCheckListAreas);

  }


  agregarcheck() {
    if (this.actividadInput != null && this.actividadInput.length > 0) {
      const tempCheckList = {
        idCheckList: null,
        idSolicitud: null,
        descripcion: this.actividadInput,
        verificacion: false,
        tipoArea: {
          id: "TIPGC-1",
          desNemonico: "",
          cveDetcat: null,
          desDetcat: "",
          desDetcor: "",
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        }
      }
      this.actividadInput = null

      let existeUser = this.itemsCheckList.find(check => check.descripcion === tempCheckList.descripcion);

      if (existeUser != null) {
        //  console.log("valor check existe");
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La actividad ya fue agregada en el checkList', life: 6000 });
      } else {
        //  console.log("agregamos al owner el array ya fue inicializado");
        this.itemsCheckList.push(tempCheckList);
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Actividad agregada.', life: 6000 });
      }
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo actividad es obligatorio', life: 6000 });
    }


  }

  eliminaCheck(checklist: CheckList) {
    this.itemsCheckList = this.itemsCheckList.filter(function (value, index, arr) {
      return value.descripcion !== checklist.descripcion;
    });
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Actividad eliminada.', life: 6000 });
  }

  async cargaChecklist($event: any) {
    //  console.log('capturando evento ' + $event.value.id)
    if ($event.value.desDetcat == "INDEFINIDO") {
      this.otherCheckList = true
      this.itemsCheckList = []
      this.infoCheck = [];
    } else {
      this.otherCheckList = false
      this.itemsCheckList = []
      this.infoCheck = [];
      this.loaderService.isLoading.next(true);
      const p1$ = this.solicitudService.obtenerCheckListArea($event.value.id);
      this.itemsCheckList = await lastValueFrom(p1$);
      this.loaderService.isLoading.next(false);
    }
  }



  prevPage() {
    if (this.itemsCheckList.length > 0) {
      this.itemsCheckList.forEach(de => {

        let existe = this.infoCheck.find(check => check.descripcion === de.descripcion);
        if (existe == null) {
          this.infoCheck.push(de);
        }
      });
      this.solicitudInformacionService.datosSolicitud.checkList = this.infoCheck;
      this.router.navigate([this.homePath + '/proyecto/solicitud/solicitar']);
    } else {
      this.solicitudInformacionService.datosSolicitud.checkList = [];
      this.router.navigate([this.homePath + '/proyecto/solicitud/solicitar']);
    }



  }

  nextPage() {
    if (this.itemsCheckList.length > 0) {

      this.itemsCheckList.forEach(de => {

        let existe = this.infoCheck.find(inf => inf.descripcion === de.descripcion);
        if (existe == null) {
          this.infoCheck.push(de);
        }
      });
      this.solicitudInformacionService.datosSolicitud.checkList = this.infoCheck;
      this.router.navigate([this.homePath + '/proyecto/solicitud/documentos'])
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se ingresaron datos para los checklist', life: 6000 });
    }
  }

}
