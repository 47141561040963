import { Component, Inject, OnInit } from '@angular/core';
import { CheckList } from 'src/app/interfaces/checklist.interface';
import { Solicitud } from 'src/app/interfaces/solicitud.interface';
import { Comentarios } from 'src/app/interfaces/comentarios.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { lastValueFrom } from 'rxjs';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { UsuarioSolicitud } from 'src/app/interfaces/usuario-solicitud.interface';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { BitacoraPage } from 'src/app/interfaces/bitacora-page.interface';
import { PersonaSolicitud } from 'src/app/interfaces/personaSolicitud.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { ArchivoService } from 'src/app/services/archivo.service';
import { MessageService } from 'primeng/api';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';
import { MimeType } from '../../../services/mimeType.service';
import { LoaderService } from 'src/app/services/loader.service';
import * as fileSaver from 'file-saver';
import { Proyecto } from 'src/app/interfaces/proyecto.interface';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { DestinatarioComentarios } from 'src/app/interfaces/destinatario-comentarios.interface';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { DOCUMENT } from '@angular/common';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';


@Component({
  selector: 'app-respuesta-solicitud',
  templateUrl: './respuesta-solicitud.component.html'
})
export class RespuestaSolicitudComponent implements OnInit {

  entrada!: string;
  loading: boolean;
  datosSolicitud!: Solicitud;
  datosUsuarioSolicitante!: UsuarioSolicitud;
  comentarios: Comentarios[] = [];
  homePath: string;
  archivosSolicitud: Archivo[] = [];
  archivosProyectoVisible: Archivo[] = [];
  archivosSolicitudRespuesta: Archivo[] = [];
  totalElementsB: number;
  bitacoraP: BitacoraPage[];
  dlgBitacora: boolean;
  checkListSolicitud: CheckList[] = [];
  personaSolicitud: PersonaSolicitud[] = [];
  datosProyecto!: Proyecto;
  usuariosComentarios: OwnersOrResponsables[] = [];
  destinatarios: DestinatarioComentarios[] = [];
  textComentario: string;
  listaCorreosNot: string[] = [];
  displayGuardado: boolean = false;
  guardarFlag: boolean = false;
  displayRechazar: boolean = false;
  displayEnviar: boolean = false;
  usuariosNotificarRechazo: OwnersOrResponsables[] = [];
  usuariosNotificarRespuesta: OwnersOrResponsables[] = [];
  isPermisoSol: boolean;
  archivoEliminar: any;
  dlgConfirmDocDelete: boolean;


  dlgComentario!: boolean;
  tipCom!: string;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private solicitudS: SolicitudService,
    private proyectoService: ProyectoService,
    private homePathService: HomePathService,
    private archivoService: ArchivoService,
    private mimeType: MimeType,
    private messageService: MessageService,
    public usuarioSesionService: UsuarioSesionService,
    private bitacoraPSService: BitacoraProyectoSolService,
    private loaderService: LoaderService,
    private notificacionesService: NotificacionService,
    @Inject(DOCUMENT) private document: Document) {
    this.route.params.subscribe(p => {
      this.loading = true;
      this.entrada = (p['id']);


    });
    //  console.log(document.location.href);
  }

  async ngOnInit() {
    //  console.log("parametro id: " + this.entrada)
    //   console.log("ruta angular" + this.router.url);

    if (!this.datosSolicitud) {
      this.datosSolicitud = {} as Solicitud;
      this.datosSolicitud.checkList = {} as CheckList[];
      this.datosSolicitud.asignados = {} as PersonaSolicitud[];
      this.datosSolicitud.archivosSolicitud = {} as Archivo[];
      this.datosSolicitud.archivosProyectoVisible = {} as Archivo[];
      this.datosUsuarioSolicitante = {} as UsuarioSolicitud;

    }

    if (!this.datosProyecto) {
      this.datosProyecto = {} as Proyecto;
      this.datosProyecto.tipoProyecto = {} as Catalogo;
      this.datosProyecto.cveInfClasificacion = {} as Catalogo;
      this.datosProyecto.estadoProyecto = {} as Catalogo;
      this.datosProyecto.usuariosOwnerProyecto = {} as OwnersOrResponsables[];
      this.datosProyecto.usuariosResponsablesProyecto = {} as OwnersOrResponsables[];

    }

    this.homePath = this.homePathService.getHomePath();

    //  console.log('valor de isPermiso:::' + this.isPermisoSol)

    this.solicitudS.isPermiso(this.usuarioSesionService.usuario.idUsuario, Number(this.entrada))
      .subscribe((response: any) => {
        this.isPermisoSol = response;

        if (this.isPermisoSol) {

          this.solicitudS.obtenerSolicitud(this.entrada).subscribe((response: any) => {
            this.datosSolicitud = response;
            this.checkListSolicitud = this.datosSolicitud.checkList;
            this.personaSolicitud = this.datosSolicitud.asignados;

            this.proyectoService.obtenerProyecto(this.datosSolicitud.idProyecto + '')
              .subscribe((response: any) => {
                this.datosProyecto = response;
              });

            this.checkListSolicitud.sort((a, b) => parseInt(a.idCheckList!) - parseInt(b.idCheckList!));

            for (const ds of this.personaSolicitud) {
              this.proyectoService.datosUsuario(ds.cveUsuario)
                .subscribe((response: any) => {
                  let responsableRegistro = response;
                  ds.nombre = responsableRegistro.nombre
                  ds.lob.desDetcat = responsableRegistro.lob
                  ds.correo = responsableRegistro.correo
                });

            }

            this.proyectoService.obtenerDatosUsuario(this.datosSolicitud.usuarioCreador)
              .subscribe((response: any) => {
                this.datosUsuarioSolicitante = response;

              });

            this.archivoService.listFiles(this.datosSolicitud.idSolicitud + '', 'ORDOC-2')
              .subscribe((response: any) => {
                this.archivosSolicitud = response;
                this.archivoService.listFilesProyectoVisible(this.datosSolicitud.idSolicitud + '')
                  .subscribe((response: any) => {
                    this.archivosProyectoVisible = response;

                    this.archivoService.listFiles(this.datosSolicitud.idSolicitud + '', 'ORDOC-3').subscribe((response: any) => {
                      this.archivosSolicitudRespuesta = response;
                      this.archivosSolicitudRespuesta = this.archivosSolicitudRespuesta.concat(this.archivosSolicitud, this.archivosProyectoVisible);

                    });

                  });


              });


            if (this.datosSolicitud.idSolicitud != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Consulta de solicitud folio: ' + this.datosSolicitud.numFolio,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosSolicitud.idSolicitud,
                tipoMovimiento: {
                  id: 'TIPMO-4',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-2',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: this.datosSolicitud.idProyecto
              };

              const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora).subscribe();


            }

          });




          this.solicitudS.obtenerComentariosSol(+this.entrada)
            .subscribe((response: any) => {
              this.comentarios = response;

            });

          this.loading = false;

        } else {




          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sin permisos para responder la solicitud' });

          this.router.navigate([this.homePath + '/proyecto/inicio'])
          //   console.log("termino el else:::");

        }

      });




  }

  async showAgregarComentario(tipo: any) {

    this.tipCom = tipo;
    this.dlgComentario = true;
    this.textComentario = '';
    if (this.destinatarios.length == 0) {

      this.usuariosComentarios = this.usuariosComentarios.concat(this.datosProyecto.usuariosOwnerProyecto, this.datosProyecto.usuariosResponsablesProyecto);

      for await (const cm of this.usuariosComentarios) {
        const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
        let responsableRegistro = await lastValueFrom(responsableRegistro$);

        const destinatario: DestinatarioComentarios = {
          cveSolicitud: this.datosSolicitud.idSolicitud!,
          comentario: '',
          correo: responsableRegistro.correo,
          cveUsuarioCreacion: responsableRegistro.numRegistro,
          fechaCreacion: null,
          indEnvio: false,
          lob: responsableRegistro.lob,
          nombre: responsableRegistro.nombre,
          rol: cm.tipoRol
        }

        this.destinatarios.push(destinatario);

      }
    }


  }

  async guardarComentario() {
    this.guardarFlag = true;
    this.loaderService.isLoading.next(true);
    if (this.textComentario == null || this.textComentario.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El comentario no puede ser vacio', life: 6000 });
    } else {
      this.destinatarios.forEach(ds => {
        if (ds.indEnvio == true) {
          this.listaCorreosNot.push(ds.correo);
        }

      });
      if (this.listaCorreosNot.length > 0) {

        for await (const cm of this.personaSolicitud) {

          this.listaCorreosNot.push(cm.correo);

        }


        const mailDTO = {
          usuariosMail: this.listaCorreosNot,
          subject: "Notificación de comentarios solicitud " + this.datosSolicitud.numFolio,
          infoMailDTO: {
            numFolio: this.datosSolicitud.numFolio!,
            titulo: "",
            descripcion: this.textComentario,
            fechaLimite: "",
            usuariosTramite: [],
            url: document.location.href

          }
        }

        const sendEmail$ = this.notificacionesService.notificarComentarioSolicitud(mailDTO);
        await lastValueFrom(sendEmail$);

        const comentario: Comentarios = {
          idComentarios: '',
          comentarios: this.textComentario,
          cveSolicitud: this.datosSolicitud.idSolicitud!,
          fechaCreacion: null,
          nombreUsuarioCreador: "",
          numRegUsuarioCreador: this.usuarioSesionService.usuario.idUsuario


        }

        const guardarComentario$ = this.solicitudS.saveComentariosSolicitud(comentario);
        await lastValueFrom(guardarComentario$);

        const bitacoraEmail = {
          cveBitacora: null,
          descAccion: 'Envío correo electrónico comentarios solicitud número de folio: ' + this.datosSolicitud.numFolio +
            ' comentario enviado: ' + this.textComentario +
            ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud!,
          tipoMovimiento: {
            id: 'TIPMO-5',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmail);
        await lastValueFrom(bitacoraEmail$);
        this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El comentario fue enviado exitosamente a los usuarios seleccionados: ', life: 6000 });
        this.loaderService.isLoading.next(false);
        const datosComentarios$ = this.solicitudS.obtenerComentariosSol(+this.entrada);
        this.comentarios = await lastValueFrom(datosComentarios$);
        this.dlgComentario = false;
      }
      else {

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar al menos un usuario para enviar los comentarios', life: 6000 });
      }



    }
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;
  }

  validaMaxCom1($event) {
    if ($event.textValue.length >= 4000) {
      this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'Máximo de 4000 caracteres.', life: 6000 });
      this.textComentario = $event.textValue.substring(0, 3999);
      //    console.log('Comentario: ', this.textComentario);
    }
  }

  myUploader(event, formUpload): void {

    if (event.files.length !== 0) {
      for (let file of event.files) {

        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: file,
          fechaCreacion: null,
          nombre: file.name,
          origenDoc: 'ORDOC-3',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: this.datosSolicitud.idProyecto + ''
        }

        if (this.archivosSolicitudRespuesta.length > 0) {
          let existeTemp = this.archivosSolicitudRespuesta.find(arch => arch.nombre === archivo.nombre);

          if (existeTemp == null) {
            this.archivosSolicitudRespuesta.push(archivo);
            this.messageService.add({ severity: 'success', summary: 'Exito!', detail: 'Archivos cargados.' });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya fue agregado un documento con el mismo nombre', life: 6000 });
          }

        } else {
          this.archivosSolicitudRespuesta.push(archivo);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos cargados.' });
        }


        formUpload.clear();

      }


      /*    this.archivos.forEach( x => {
           console.log("nombre archivos: " + x.nombre);
           console.log("tamaño: " + x.file.size);
          })  */


    }
  }


  showEliminarDoc(arch: any) {
    this.archivoEliminar = arch;
    this.dlgConfirmDocDelete = true;

  }

  async eliminaArch() {
    let archivo = this.archivoEliminar
    if (this.archivoEliminar.cveDoc == null) {
      this.archivosSolicitudRespuesta = this.archivosSolicitudRespuesta.filter(function (value, index, arr) {
        return value.nombre !== archivo.nombre;
      });
    } else {
      this.archivosSolicitudRespuesta = this.archivosSolicitudRespuesta.filter(function (value, index, arr) {
        return value.nombre !== archivo.nombre;
      });

      const eliminarArch$ = this.archivoService.eliminarArchivo(archivo.cveDoc);
      await lastValueFrom(eliminarArch$);

      if (this.datosSolicitud.idSolicitud != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Archivo eliminado nombre: ' + archivo.nombre + ' id documento: ' + archivo.cveDoc,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud,
          tipoMovimiento: {
            id: 'TIPMO-3',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);

      }

    }

    this.archivoEliminar = [];
    this.cancel();

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }

  async download(nombre: string, path: string) {
    this.loaderService.isLoading.next(true);
    if (this.homePath.includes('-internal')) {
      const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(nombre));
      this.archivoService.downloadFile(nombre, path)
        .subscribe(
          file => {
            const data: Blob = new Blob([file], { type: mime });
            fileSaver.saveAs(data, nombre);
            this.loaderService.isLoading.next(false);
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Descarga archivo, nombre: ' + nombre + ' peso ' + data.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosSolicitud.idSolicitud!,
              tipoMovimiento: {
                id: 'TIPMO-6',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.datosSolicitud.idProyecto
            };

            this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo no descargado.', life: 6000 });
          });
    } else {
      this.archivoService.downloadExternal(path, nombre);
      const bitacoraSubida = {
        cveBitacora: null,
        descAccion: 'Descarga archivo, nombre: ' + nombre + ' ',
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosSolicitud.idSolicitud!,
        tipoMovimiento: {
          id: 'TIPMO-6',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: this.datosSolicitud.idProyecto
      };

      this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
    }
  }

  getFileExtension3(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  cancel() {
    this.displayGuardado = false;
    this.displayRechazar = false;
    this.displayEnviar = false;
    this.dlgConfirmDocDelete = false;
  }

  showDialogPreGuardado() {
    this.displayGuardado = true;
  }

  showDialogRechazar() {
    this.displayRechazar = true;
  }

  showDialogEnviar() {
    this.displayEnviar = true;
  }

  regresar() {
    this.router.navigate([this.homePath + '/proyecto/bandejaSolicitud/1']);
  }

  async enviar() {

    let checkPendiente = this.checkListSolicitud.find(indicador => indicador.verificacion == null || indicador.verificacion == false);
    let archivosEnviar = this.archivosSolicitudRespuesta.find(doc => doc.origenDoc === 'ORDOC-3' || 'RESPUESTA SOLICITUD');
    if (checkPendiente != null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'La lista de checklist no esta completa', life: 6000 });
    } else if (archivosEnviar == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar al menos un documento adjunto como respuesta', life: 6000 });
    } else if (this.datosSolicitud.comentarioCierre == null || this.datosSolicitud.comentarioCierre.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe agregar un comentario de cierre para poder responder la solicitud', life: 6000 });
    } else {

      this.loaderService.isLoading.next(true);
      this.guardarFlag = true;

      const check$ = this.solicitudS.updateChecklist(this.checkListSolicitud);
      await lastValueFrom(check$);

      for await (const arch of this.archivosSolicitudRespuesta) {
        if (arch.cveDoc == null) {
          arch.cveElemento = this.datosSolicitud.idSolicitud + "";
          if (this.homePath.includes('-internal')) {
            await this.archivoService.uploadFile(arch.documento, "SOLICITUD-" + arch.cveElemento).toPromise().catch(() => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
            }).finally(async () => {
              this.archivoService.saveFile(arch).toPromise();
              const bitacoraSubida = {
                cveBitacora: null,
                descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosSolicitud.idSolicitud!,
                tipoMovimiento: {
                  id: 'TIPMO-7',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-2',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: this.datosSolicitud.idProyecto
              };
              const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
              await lastValueFrom(bitacora$);
            });
          } else {
            await this.archivoService.uploadFiles2(arch.documento, "SOLICITUD/" + arch.cveElemento).promise().then(async () => {
              await lastValueFrom(this.archivoService.saveFile(arch));
              const bitacoraSubida = {
                cveBitacora: null,
                descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosSolicitud.idSolicitud!,
                tipoMovimiento: {
                  id: 'TIPMO-7',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-2',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: this.datosSolicitud.idProyecto
              };
              await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
            }).catch(() => {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
            });
          }
        }
      }

      const datosSolicitud = {
        idSolicitud: this.datosSolicitud.idSolicitud,
        comentarioCierre: this.datosSolicitud.comentarioCierre,
        estado: "EDSOL-2"
      }

      const comentarioCierre$ = this.solicitudS.updateRespuesta(datosSolicitud);
      await lastValueFrom(comentarioCierre$);

      if (this.datosSolicitud.idSolicitud != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Envío de respuesta solicitud folio: ' + this.datosSolicitud.numFolio,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud,
          tipoMovimiento: {
            id: 'TIPMO-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);
      }

      this.usuariosNotificarRespuesta = this.usuariosNotificarRespuesta.concat(this.datosProyecto.usuariosOwnerProyecto,
        this.datosProyecto.usuariosResponsablesProyecto, this.datosProyecto.usuariosFirmaExterna);

      for await (const cm of this.usuariosNotificarRespuesta) {
        if (cm.indNotificacion) {
          const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
          let responsableRegistro = await lastValueFrom(responsableRegistro$);
          this.listaCorreosNot.push(responsableRegistro.correo);
        }

      }

      const mailDTO = {
        usuariosMail: this.listaCorreosNot,
        subject: "Notificación de respuesta solicitud " + this.datosSolicitud.numFolio,
        infoMailDTO: {
          numFolio: this.datosSolicitud.numFolio!,
          titulo: "",
          descripcion: "",
          fechaLimite: "",
          usuariosTramite: [],
          url: document.location.href

        }
      }

      const sendEmail$ = this.notificacionesService.notificarRespuestaSolicitud(mailDTO);
      await lastValueFrom(sendEmail$);

      this.ngOnInit();
      this.cancel();
      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue enviada al responsable, se notificara al responsable de la solicitud: ', life: 6000 });
    }
  }

  async rechazar() {

    if (this.datosSolicitud.comentarioCierre == null || this.datosSolicitud.comentarioCierre.length == 0) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Favor de ingresar en la sección comentario de cierre el motivo del rechazo', life: 6000 });
    } else {
      this.loaderService.isLoading.next(true);
      this.guardarFlag = true;

      const datosSolicitud = {
        idSolicitud: this.datosSolicitud.idSolicitud,
        comentarioCierre: this.datosSolicitud.comentarioCierre,
        estado: "EDSOL-9"
      }

      const comentarioCierre$ = this.solicitudS.updateRespuesta(datosSolicitud);
      await lastValueFrom(comentarioCierre$);

      if (this.datosSolicitud.idSolicitud != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Rechazo de solicitud folio: ' + this.datosSolicitud.numFolio,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosSolicitud.idSolicitud,
          tipoMovimiento: {
            id: 'TIPMO-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: this.datosSolicitud.idProyecto
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);
      }

      this.usuariosNotificarRechazo = this.usuariosNotificarRechazo.concat(this.datosProyecto.usuariosOwnerProyecto,
        this.datosProyecto.usuariosResponsablesProyecto, this.datosProyecto.usuariosFirmaExterna);

      for await (const cm of this.usuariosNotificarRechazo) {


        if (cm.indNotificacion) {
          const responsableRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
          let responsableRegistro = await lastValueFrom(responsableRegistro$);
          this.listaCorreosNot.push(responsableRegistro.correo);
        }

      }

      const mailDTO = {
        usuariosMail: this.listaCorreosNot,
        subject: "Notificación de rechazo solicitud " + this.datosSolicitud.numFolio,
        infoMailDTO: {
          numFolio: this.datosSolicitud.numFolio!,
          titulo: "",
          descripcion: this.datosSolicitud.comentarioCierre,
          fechaLimite: "",
          usuariosTramite: [],
          url: document.location.href

        }
      }

      const sendEmail$ = this.notificacionesService.notificarRechazoSolicitud(mailDTO);
      await lastValueFrom(sendEmail$);

      this.ngOnInit();
      this.cancel();
      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'La solicitud fue rechazada se notificara al responsable de la solicitud: ', life: 6000 });
    }

  }

  async guardar() {

    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;

    const check$ = this.solicitudS.updateChecklist(this.checkListSolicitud);
    await lastValueFrom(check$);

    for await (const arch of this.archivosSolicitudRespuesta) {
      if (arch.cveDoc == null) {
        arch.cveElemento = this.datosSolicitud.idSolicitud + "";
        if (this.homePath.includes('-internal')) {
          //    console.log("entro a guardar archivo internal")
          await this.archivoService.uploadFile(arch.documento, "SOLICITUD-" + arch.cveElemento).toPromise().catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          }).finally(async () => {
            this.archivoService.saveFile(arch).toPromise();
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosSolicitud.idSolicitud!,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.datosSolicitud.idProyecto
            };

            const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
            await lastValueFrom(bitacora$);
          });
        } else {
          await this.archivoService.uploadFiles2(arch.documento, "SOLICITUD/" + arch.cveElemento).promise().then(async () => {
            await lastValueFrom(this.archivoService.saveFile(arch));
            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Subida archivo, nombre: ' + arch.nombre + + arch.documento.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosSolicitud.idSolicitud!,
              tipoMovimiento: {
                id: 'TIPMO-7',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-2',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: this.datosSolicitud.idProyecto
            };
            await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
          }).catch(() => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
          });
        }
      }
    }

    const datosSolicitud = {
      idSolicitud: this.datosSolicitud.idSolicitud,
      comentarioCierre: this.datosSolicitud.comentarioCierre,
      estado: "EDSOL-3"
    }

    const comentarioCierre$ = this.solicitudS.updateRespuesta(datosSolicitud);
    await lastValueFrom(comentarioCierre$);


    if (this.datosSolicitud.idSolicitud != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'Preguardado de solicitud folio: ' + this.datosSolicitud.numFolio,
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosSolicitud.idSolicitud,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: this.datosSolicitud.idProyecto
      };

      const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacora$);
    }

    this.ngOnInit();
    this.cancel();
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;
    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Se guardo éxitosamente el avance capturado en la solicitud: ', life: 6000 });
  }

}
