import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomePathService } from 'src/app/services/home-path.service';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';



@Component({
  selector: 'app-datos-solicitud',
  templateUrl: './datos-solicitud.component.html'
})
export class DatosSolicitudComponent implements OnInit {

  solicitudInfo: any;
  submitted: boolean = false;
  minDate!: Date;
  datePipe = new DatePipe('en-US');
  fechaFormateada: any;
  homePath: string;
  invalidDates: Array<Date>;


  constructor(private router: Router,
    public solicitudInformacionService: SolicitudInformacionService,
    private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService) { }

  ngOnInit(): void {
    this.homePath = this.homePathService.getHomePath();
    this.solicitudInfo = this.solicitudInformacionService.datosSolicitud;
    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month === 0) ? 11 : month - 1;
    let prevYear = (prevMonth === 11) ? year - 1 : year;

    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);

    this.invalidDates = [];
    for (let index: number = 0; index < 10000; index++) {
      const invalidDate = new Date();
      invalidDate.setDate(today.getDate() - index);
      this.invalidDates.push(invalidDate);
    }
    //  console.log("id proyecto: " + this.solicitudInformacionService.datosSolicitud.idProyecto)
  }


  prevPage() {
    this.router.navigate([this.homePath + '/proyecto/gestionProyecto', this.solicitudInformacionService.datosSolicitud.idProyecto])

  }

  nextPage() {
    if (this.solicitudInfo.nombreSolicitud && this.solicitudInfo.fechaLimite && this.solicitudInfo.descripcion) {

      this.fechaFormateada = this.datePipe.transform(this.solicitudInfo.fechaLimite, 'dd/MM/yyyy');

      //  console.log("fecha formateada:::::" + this.fechaFormateada)

      this.solicitudInformacionService.datosSolicitud.nombreSolicitud = this.solicitudInfo.nombreSolicitud;
      this.solicitudInformacionService.datosSolicitud.fechaLimite = this.fechaFormateada;
      this.solicitudInformacionService.datosSolicitud.descripcion = this.solicitudInfo.descripcion;
      this.solicitudInformacionService.datosSolicitud.usuarioCreador = this.usuarioSesionService.usuario.idUsuario;
      this.router.navigate([this.homePath + '/proyecto/solicitud/solicitar'])

    } else {
      this.submitted = true;
    }

  }

}
