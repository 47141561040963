import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Proyecto } from "../interfaces/proyecto.interface";

@Injectable()
export class ProyectoInformacionService {

    datosProyecto = {} as Proyecto;

    private proyectoComplete = new Subject<Proyecto>();

    proyectoComplete$ = this.proyectoComplete.asObservable();

    getProyectoInformacion() {
        return this.datosProyecto;
    }

    setProyectoInformacion(proyectoInformacion: any) {
        this.datosProyecto = proyectoInformacion;
    }

    complete() {
        this.proyectoComplete.next(this.datosProyecto)
    }

}