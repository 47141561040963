import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Modulo } from 'src/app/interfaces/modulo.interface';
import { ModuloRol } from 'src/app/interfaces/moduloRol.interface';
import { Rol } from 'src/app/interfaces/rol.interface';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private url: string = `${api.urlAPI}` + 'api/admin/modulo_rol';

  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(private http: HttpClient) { }

  getListRoles(): Observable<Rol[]> {
    return this.http.get<Rol[]>(this.url + '/list');
  }

  getModulosByRol(rolId: number): Observable<Modulo[]> {
    let params = new HttpParams()
      .set('rolId', rolId);
    return this.http.get<Modulo[]>(this.url + '/modulos', { params })
  }

  addModuloToRol(rolId: number, moduloId: number): Observable<Modulo> {
    return this.http.post<Modulo>(this.url + '/relacionar/' + rolId + '/' + moduloId, { headers: this.httpHeaders });
  }

  pageModulosByRol(rolId: string): Observable<any> {
    let params = new HttpParams()
      .set('rolId', rolId);
    return this.http.get<any>(this.url + '/modulos_rol/', { params });
  }

  deleteModuloRol(moduloRolId: number): Observable<any> {
    let params = new HttpParams()
      .set('moduloRolId', moduloRolId);
    return this.http.delete<any>(this.url, { params })
  }

  updateModuloRol(modulo: ModuloRol): Observable<ModuloRol> {
    return this.http.post<ModuloRol>(this.url + '/update', modulo);
  }
}
