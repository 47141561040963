import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatrizEscalamiento } from 'src/app/interfaces/matrizEscalamiento.interface';
import { MatrizService } from 'src/app/services/matriz.service';

@Component({
  selector: 'app-matriz-escalamiento',
  templateUrl: './matriz-escalamiento.component.html'
})

export class MatrizEscalamientoComponent implements OnInit {

  escalamientos?: MatrizEscalamiento[];

  constructor(private matrizService: MatrizService) {
  }

  ngOnInit() {
    this.matrizService.getEscalamientos().subscribe(
      escalamientos => this.escalamientos = escalamientos
    );
  }

}