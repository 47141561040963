import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { TcEdNombreCatalogo } from 'src/app/interfaces/tcEdNombreCatalogo.interface';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { CatalogoService } from './catalogo.service';

@Component({
  selector: 'app-catalogos',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogosComponent implements OnInit {

  @ViewChild('elementoTbl') elementoTbl!: Table;

  catalogoForm!: FormGroup;
  nombreCatalogos!: any[];
  nombreElementos!: any[];
  elementoForm!: FormGroup;
  totalElements!: number;
  nemonicos: any[] = [];
  masters!: any[];
  catPais!: any[];
  elementos!: any[];
  totalElements2!: number;
  backup!: string;
  backup2!: string;
  nombreCatalogo!: TcEdNombreCatalogo;
  btnUpdate!: boolean
  elementoCatalogo!: Catalogo;

  constructor(private fb: FormBuilder,
    public rolModuloService: RolModuloService,
    private catalogoService: CatalogoService,
    public usuarioSesionService: UsuarioSesionService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
    this.iniciaFormCat();
    this.iniciaFormElm();
    this.getPageNombreCatalogo();
    this.getPageElementoCatalogo();
  }

  loadLazy(event: LazyLoadEvent) {
    let filter = 'none';
    if (event.filters && event.filters['global'] && event.filters['global'].value) {
      filter = event.filters['global'].value;
    }
    let page = event.first! / event.rows!;
    if (this.backup !== undefined && this.backup !== filter) {
      page = 0;
    }
    this.catalogoService.pageNombreCatalogo(page, event.rows!, filter).toPromise().then(
      (response: any) => {
        this.nombreCatalogos = response.content;
        this.totalElements = response.totalElements;
      }
    );
  }

  getPageNombreCatalogo() {
    this.catalogoService.pageNombreCatalogo(0, 10, 'none').toPromise().then(
      (response: any) => {
        this.nombreCatalogos = response.content;
        this.nombreElementos = response.content;
        this.totalElements = response.totalElements;
      }
    );
  }

  ngOnInit(): void {
    this.catalogoService.getCatalogoPais().subscribe(
      (response: any) => {
        this.catPais = response;
      }
    );
  }

  async onSubmitCatalogo() {
    this.btnUpdate = false;
    this.nombreCatalogo = {} as TcEdNombreCatalogo;
    this.nombreCatalogo.cveNemonico = this.catalogoForm.controls['cveNemonico'].value;
    this.nombreCatalogo.cveNemonico = this.nombreCatalogo.cveNemonico.toLocaleUpperCase();
    this.nombreCatalogo.descripcion = this.catalogoForm.controls['descripcion'].value;
    this.nombreCatalogo.descripcion = this.nombreCatalogo.descripcion.toLocaleUpperCase();
    this.nombreCatalogo.staRegistro = this.catalogoForm.controls['staRegistro'].value;
    this.catalogoService.saveNombreCatalogo(this.nombreCatalogo).subscribe(
      (response: any) => {
        this.nombreCatalogo = response;
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación exitosa', life: 3000 });
        this.getPageNombreCatalogo();
        this.nombreCatalogo = {} as TcEdNombreCatalogo;
        this.iniciaFormCat();
      }
    );

  }


  iniciaFormCat() {
    this.catalogoForm = new FormGroup({
      'cveNemonico': new FormControl('', [Validators.required, Validators.maxLength(20)]),
      'descripcion': new FormControl('', Validators.required),
      'staRegistro': new FormControl(true, Validators.required),
    });
  }



  cancelarCat() {
    this.btnUpdate = false;
    this.iniciaFormCat();
  }

  valueEditCatalogo!:string;
  async editarCatalogo(cat: string) {
    window.scroll({ top: 100, left: 100, behavior: 'smooth' });
    this.catalogoService.getNombreCatalogo(cat).toPromise().then(
      (data: any) => {
        this.nombreCatalogo = data;
        this.valueEditCatalogo = this.nombreCatalogo.cveNemonico;
        this.catalogoForm.get(['cveNemonico'])?.setValue(this.nombreCatalogo.cveNemonico);
        this.catalogoForm.get(['descripcion'])?.setValue(this.nombreCatalogo.descripcion);
        this.catalogoForm.get(['staRegistro'])?.setValue(this.nombreCatalogo.staRegistro);
        this.btnUpdate = true;
      }
    );

  }

  async eliminarCatalogo(catalogo: TcEdNombreCatalogo) {
    this.confirmationService.confirm({
      header: '¡Atención!',
      message: '¿Estás seguro de eliminar el catálogo?',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        this.catalogoService.deleteNombreCatalogo(catalogo).toPromise().then(
          (resp: any) => {
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Catálogo eliminado', life: 3000 });
            this.getPageNombreCatalogo();
            this.nombreCatalogo = {} as TcEdNombreCatalogo;
            this.iniciaFormCat();
          }
        ).catch(
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
          }
        );
      }, reject: () => {
        this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
      }
    });

  }

  iniciaFormElm() {
    this.elementoForm = new FormGroup({
      'id': new FormControl(null),
      'desDetcat': new FormControl('', Validators.required),
      'desDetcor': new FormControl('', Validators.required),
      'elDesNemonico': new FormControl('', Validators.required),
      'staRegis': new FormControl(true),
      'pais': new FormControl('', Validators.required),
      'catalogo': new FormControl(null)
    });
  }

  async searchMaster(event: any) {
    await this.catalogoService.searchMaster(event.query).subscribe(
      (response: any) => {
        this.masters = response;
      }
    );
  }

  async onSubmitElemento() {
    this.elementoCatalogo = {} as Catalogo;
    this.elementoCatalogo.desNemonico = this.elementoForm.controls['elDesNemonico'].value.cveNemonico;
    // this.elementoCatalogo.id = cat.id;
    this.elementoCatalogo.desDetcat = this.elementoForm.controls['desDetcat'].value.toLocaleUpperCase();
    this.elementoCatalogo.desDetcor = this.elementoForm.controls['desDetcor'].value.toLocaleUpperCase();
    this.elementoCatalogo.catalogo = {} as Catalogo;
    this.elementoCatalogo.catalogo = this.elementoForm.controls['catalogo'].value;
    this.elementoCatalogo.pais = this.elementoForm.controls['pais'].value.cveDetcat;
    this.elementoCatalogo.staRegis = this.elementoForm.controls['staRegis'].value;
    this.catalogoService.saveElementoCatalogo(this.elementoCatalogo).toPromise().then(
      data => {
        if (data.code === '00') {
          this.btnUpdate = false;
          this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 3000 });
          this.cancelarElm();
          this.iniciaFormElm();
          this.getPageElementoCatalogo();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
        }
      }
    );
  }

  cancelarElm() {
    this.elementoForm.get(['elDesNemonico'])?.setValue('');
    this.elementoForm.get(['desDetcat'])?.setValue('');
    this.elementoForm.get(['desDetcor'])?.setValue('');
    this.elementoForm.get(['catalogo'])?.setValue(null);
    this.elementoForm.get(['pais'])?.setValue('');
    this.elementoForm.get(['staRegis'])?.setValue(true);
    this.btnUpdate = false;
  }

  getPageElementoCatalogo() {
    this.catalogoService.pageElementoCatalogo(0, 10, 'none').toPromise().then(
      (response: any) => {
        this.elementos = response.content;
        this.totalElements2 = response.totalElements;
      }
    );
  }

  loadLazyElemento(event: LazyLoadEvent) {
    let filter = 'none';
    if (event.filters && event.filters['global'] && event.filters['global'].value) {
      filter = event.filters['global'].value;
    }
    let page = event.first! / event.rows!;
    if (this.backup !== undefined && this.backup !== filter) {
      page = 0;
    }
    this.catalogoService.pageElementoCatalogo(page, event.rows!, filter).toPromise().then(
      (response: any) => {
        this.elementos = response.content;
        this.totalElements2 = response.totalElements;
      }
    );
  }

  getTablaElemento(nemonico: any) {
    let param = 'none';
    if (nemonico) {
      param = nemonico.cveNemonico;
    }
    this.catalogoService.pageElementoCatalogo(0, 10, param).toPromise().then(
      (response: any) => {
        this.elementos = response.content;
        this.totalElements2 = response.totalElements;
      }
    );
  }

  exportexcel(): void {

  }

  async editarElemento(id: string) {
    this.catalogoService.getElementoCatalogo(id).subscribe(
      (response: any) => {
        this.elementoCatalogo = response;
        this.elementoForm.get(['id'])?.setValue(this.elementoCatalogo.id);
        this.elementoForm.get(['desDetcat'])?.setValue(this.elementoCatalogo.desDetcat);
        this.elementoForm.get(['desDetcor'])?.setValue(this.elementoCatalogo.desDetcor);

        const element = (element) => element.cveNemonico === this.elementoCatalogo.desNemonico;
        const index = this.nombreElementos.findIndex(element);
        this.elementoForm.get(['elDesNemonico'])?.setValue(this.nombreElementos[index]);


        this.elementoForm.get(['staRegis'])?.setValue(this.elementoCatalogo.staRegis);

        console.log(this.catPais);
        const elementP = (element) => element.cveDetcat === response.pais;
        const index2 = this.catPais.findIndex(elementP);
        this.elementoForm.get(['pais'])?.setValue(this.catPais[index2]);

        this.elementoForm.get(['catalogo'])?.setValue(this.elementoCatalogo.catalogo);
        this.btnUpdate = true;
      }
    );
  }

  async eliminarElemento(elemento: any) {
    this.confirmationService.confirm({
      header: '¡Atención!',
      message: '¿Estás seguro de eliminar el elemento: ' + elemento.desDetcat + ', nemónico: ' + elemento.desNemonico + ' ?',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        this.catalogoService.deleteElementoCatalogo(elemento.id).toPromise().then(
          data => {
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 3000 });
            if (this.elementos.length > 0) {
              this.elementos = this.elementos.filter(function (value, index, arr) {
                return value.id !== elemento.id;
              });
            } else {
              this.getPageElementoCatalogo();
            }
            this.cancelarElm();
          }
        ).catch(
          error => { this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 }); }
        );
      }, reject: () => {
        this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
      }
    });

  }
}
