import { Component, OnInit } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { CheckList } from 'src/app/interfaces/checklist.interface';
import { TemplateCheckList } from 'src/app/interfaces/templateCheckList';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { CatalogoService } from '../catalogos/catalogo.service';
import { TemplateChecklistService } from './template-checklist.service';

@Component({
  selector: 'app-gestion-checklist',
  templateUrl: './gestion-checklist.component.html',
  styleUrls: ['./gestion-checklist.component.css']
})
export class GestionChecklistComponent implements OnInit {

  checklist: any;
  templateCheckLits: TemplateCheckList[];
  clonedCheck: any;
  totalElements!: number;
  backup!: string;
  valueCheck: Catalogo;
  areas: any;
  checkList: TemplateCheckList;

  constructor(
    private catalogoService: CatalogoService,
    private templateChecklistService: TemplateChecklistService,
    private messageService: MessageService,
    public usuarioSesionService: UsuarioSesionService,
    public rolModuloService: RolModuloService,
    private confirmationService: ConfirmationService
  ) {
    this.checkList = {} as TemplateCheckList;
    this.valueCheck = {} as Catalogo;
  }

  ngOnInit(): void {
    this.precarga();
  }

  async precarga() {
    this.areas = await this.catalogoService.getCatalogoAreas().toPromise();
  }

  async cargaChecklist(event: any) {
    this.pageCargaChecklist(event.value.id);
  }

  async pageCargaChecklist(id: string) {
    await this.templateChecklistService.pageTamplateChecklist(0, 10, 'none', id).toPromise().then(
      (response: any) => {
        this.templateCheckLits = response.content;
        this.totalElements = response.totalElements;
      }
    );
  }

  loadLazy(event: LazyLoadEvent) {
    let filter = 'none';
    if (event.filters && event.filters['global'] && event.filters['global'].value) {
      filter = event.filters['global'].value;
    }
    let page = event.first! / event.rows!;
    if (this.backup !== undefined && this.backup !== filter) {
      page = 0;
    }
    this.templateChecklistService.pageTamplateChecklist(page, event.rows!, filter, this.valueCheck.id).toPromise().then(
      (response: any) => {
        this.templateCheckLits = response.content;
        this.totalElements = response.totalElements;
      }
    );
  }

  async showAgregarCheck() {
    let currentObj = this;
    this.checkList.grupoArea = {} as Catalogo;
    this.checkList.grupoArea = this.valueCheck;
    await this.templateChecklistService.saveTemplate(this.checkList).toPromise().then(
      response => {
        this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 3000 });
      }
    ).catch(
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
      }
    );
    await this.pageCargaChecklist(currentObj.valueCheck.id);
    this.checkList = {} as TemplateCheckList;
  }

  lectura(id: any) {

  }

  editarTemplate(id: any) {
    this.templateChecklistService.getTemplateCheckList(id).toPromise().then(
      (response: any) => {
        this.checkList = response;
      }
    ).catch(
      error => {
        this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
      }
    );
  }


  async deleteTemplate(check: any) {
    this.confirmationService.confirm({
      header: '¡Atención!',
      message: '¿Estás seguro de eliminar el checklist: ' + check.descripcion + '?',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        await this.templateChecklistService.deleteTemplete(check.cveCheckList).toPromise().then(
          (response: any) => {
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 3000 });
            this.templateCheckLits = this.templateCheckLits.filter(temp => temp !== check)
          }
        ).catch(
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
          }
        );
        await this.pageCargaChecklist(this.valueCheck.id);
      }, reject: () => {
        this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
      }
    });
  }

  async onRowEditSave(check: TemplateCheckList) {
    this.confirmationService.confirm({
      header: '¡Atención!',
      message: '¿Estás seguro de realizar los cambios?',
      acceptLabel: 'Continuar',
      rejectLabel: 'Cancelar',
      accept: async () => {
        await this.templateChecklistService.saveTemplate(check).toPromise().then(
          response => {
            let element = (element) => element.cveCheckList === check.cveCheckList;
            let index = this.templateCheckLits.findIndex(element);
            this.templateCheckLits[index] = response!;
            this.messageService.add({ severity: 'success', summary: 'Éxito!', detail: 'Operación Exitosa', life: 3000 });
          }
        ).catch(
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Error en la operación', life: 6000 });
          }
        );
      }, reject: () => {
        this.onRowEditCancel(check);
        this.messageService.add({ severity: 'warn', summary: 'Cancelado!', detail: 'Operación Cancelada', life: 6000 });
      }
    });
  }

  onRowEditInit(check: TemplateCheckList) {
    let element = (element) => element.cveCheckList === check.cveCheckList;
    let index = this.templateCheckLits.findIndex(element);
    let b = this.templateCheckLits[index];
    this.clonedCheck = { 'cveCheckList': b.cveCheckList, 'descripcion': b.descripcion, 'grupoArea': b.grupoArea, 'fechaCreacion': b.fechaCreacion, 'fechaMod': b.fechaMod, 'indActivo': b.indActivo };
  }

  onRowEditCancel(check: TemplateCheckList) {
    let element = (element) => element.cveCheckList === check.cveCheckList;
    let index = this.templateCheckLits.findIndex(element);
    this.templateCheckLits[index] = this.clonedCheck;
  }

}
