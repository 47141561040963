import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BandejaService } from 'src/app/services/bandeja.service';
import { HomePathService } from 'src/app/services/home-path.service';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  homePath: string;
  data: any;
  dataSolicitud: any;
  proPendientes: any;
  proCancelado: any;
  proEnTiempo: any;
  proRetrasado: any;
  proCerradas: any;

  solPendiente: any;
  solRetirada: any;
  solContestada: any;
  solEnRevision: any;
  solDevuelta: any;

  solEscalada: any;

  solCerrada: any;
  solRechazada: any;


  constructor(private homePathService: HomePathService,
    private router: Router,
    private bandejaService: BandejaService,
    public usuarioSesionService: UsuarioSesionService,
    public rolModuloService: RolModuloService) { }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();

    const p1 = this.bandejaService.getCountProyectos(this.usuarioSesionService.usuario.idUsuario, 'EN PROGRESO').toPromise();
    const p2 = this.bandejaService.getCountProyectos(this.usuarioSesionService.usuario.idUsuario, 'RETRASADO').toPromise();
    const p3 = this.bandejaService.getCountProyectos(this.usuarioSesionService.usuario.idUsuario, 'EN TIEMPO').toPromise();
    const p4 = this.bandejaService.getCountProyectos(this.usuarioSesionService.usuario.idUsuario, 'CANCELADO').toPromise();
    const p5 = this.bandejaService.getCountProyectos(this.usuarioSesionService.usuario.idUsuario, 'CERRADO').toPromise();
    [this.proPendientes, this.proRetrasado, this.proEnTiempo, this.proCancelado, this.proCerradas] = await Promise.all([p1, p2, p3, p4, p5]);

    const p1S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'EN PROGRESO').toPromise();
    const p2S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'RETIRADA').toPromise();
    const p3S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'CONTESTADA').toPromise();
    const p4S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'EN REVISION').toPromise();
    const p5S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'DEVUELTA').toPromise();
    const p7S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'ESCALADA').toPromise();
    const p9S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'CERRADA').toPromise();
    const p10S = this.bandejaService.getCountSolicitudes(this.usuarioSesionService.usuario.idUsuario, 'RECHAZADA').toPromise();
    [this.solPendiente, this.solRetirada, this.solContestada, this.solEnRevision, this.solDevuelta, this.solEscalada, this.solCerrada, this.solRechazada] = await Promise.all([p1S, p2S, p3S, p4S, p5S, p7S, p9S, p10S]);


    this.data = {
      labels: ['Estado de Consultas'],
      datasets: [
        {
          label: 'EN PROGRESO',
          backgroundColor: '#2561D3',
          borderColor: '#1E88E5',
          data: [this.proPendientes]
        },
        {
          label: 'RETRASADO',
          backgroundColor: '#DF0C25',
          borderColor: '#DF0C25',
          data: [this.proRetrasado]
        },
        {
          label: 'EN TIEMPO',
          backgroundColor: '#49A41C',
          borderColor: '#49A41C',
          data: [this.proEnTiempo]
        },
        {
          label: 'CERRADO',
          backgroundColor: '#1CA495',
          borderColor: '#1CA495',
          data: [this.proCerradas]
        },
        {
          label: 'CANCELADO',
          backgroundColor: '#621CA4',
          borderColor: '#621CA4',
          data: [this.proCancelado]
        }
      ]
    };


    this.dataSolicitud = {
      labels: ['Estado de Consultas'],
      datasets: [
        {
          label: 'EN PROGRESO',
          backgroundColor: '#2561D3',
          borderColor: '#1E88E5',
          data: [this.solPendiente]
        },
        {
          label: 'RETIRADA',
          backgroundColor: '#DF0C25',
          borderColor: '#DF0C25',
          data: [this.solRetirada]
        },
        {
          label: 'CONTESTADA',
          backgroundColor: '#49A41C',
          borderColor: '#49A41C',
          data: [this.solContestada]
        },
        {
          label: 'EN REVISION',
          backgroundColor: '#1CA495',
          borderColor: '#1CA495',
          data: [this.solEnRevision]
        },
        {
          label: 'DEVUELTA',
          backgroundColor: '#621CA4',
          borderColor: '#621CA4',
          data: [this.solDevuelta]
        },
        {
          label: 'ESCALADA',
          backgroundColor: '#621CA4',
          borderColor: '#621CA4',
          data: [this.solEscalada]
        },
        {
          label: 'CERRADA',
          backgroundColor: '#621CA4',
          borderColor: '#621CA4',
          data: [this.solCerrada]
        }
      ]
    };



  }



  irBandejaPro() {
    this.router.navigate([this.homePath + '/proyecto/bandeja', 1]);
  }

  irEscalamientos() {
    this.router.navigate([this.homePath + '/matriz/escalamiento']);
  }

  irSolicitudes() {
    this.router.navigate([this.homePath + '/proyecto/bandejaSolicitud/', 1]);
  }

  irReportes() {
    this.router.navigate([this.homePath + '/proyecto/reportes']);
  }

  irAdminUser() {
    this.router.navigate([this.homePath + '/admin/usuarios']);
  }

}
