import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { api, environment } from "src/environments/environment";
import { LoaderService } from "./loader.service";
import * as AWS from 'aws-sdk';
import { Observable } from "rxjs";
import { kms } from '../../environments/environment';
import { Archivo } from "../interfaces/archivo.interface";
import { MimeType } from "./mimeType.service";
import * as fileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ArchivoService {

    URL = `${api.urlAPI}` + 'api/archivo/';
    URLARCHIVO = `${api.urlArchivo}` + 'ediscovery/';

    constructor(private http: HttpClient, private loaderService: LoaderService, private mimeType: MimeType) { }

    uploadFile(file: File, cveElemento: string): Observable<HttpEvent<any>> {
        const formdata: FormData = new FormData();
        formdata.append('file', file);
        let headers = new HttpHeaders();
        headers = headers.set('Access-Control-Allow-Origin', '*');

        const req = new HttpRequest('POST', `${this.URLARCHIVO}upload/` + cveElemento, formdata, {
            headers: headers,
            reportProgress: true,
            responseType: 'json',
        });
        return this.http.request(req);
    }

    /*  uploadFile(file: File, cveElemento: string, usuarioCreacion: string, origen: string, cveIdProyecto: string): Observable<HttpEvent<any>> {
          const formdata: FormData = new FormData();
          formdata.append('file', file);
          let headers = new HttpHeaders();
          headers = headers.set('Access-Control-Allow-Origin', '*');
  
          const req = new HttpRequest('POST', `${this.URLARCHIVO}upload/internos/` + cveElemento + '/' +
          usuarioCreacion + '/' + origen + '/' + cveIdProyecto, formdata, {
              headers: headers,
              reportProgress: true,
              responseType: 'json',
          });
          return this.http.request(req);
      }
  
      */

    uploadFiles2(file: File, path: String) {
        const bucket = new AWS.S3({
            apiVersion: '2006-03-01'
        });
        const params = {
            'Body': file,
            'Key': path + '/' + file.name,
            'Bucket': environment.account + '-lts-ed-files',
            'ServerSideEncryption': 'aws:kms',
            'SSEKMSKeyId': kms.s3
        };
        return bucket.upload(params);
    }

    uploadFiles(file: File, path: String) {

        const bucket = new AWS.S3({
            apiVersion: '2006-03-01'
        });

        const respuesta = new Observable(observer => {

            const params = {
                'Body': file,
                'Key': path + '/' + file.name,
                'Bucket': environment.account + '-lts-ed-files',
                'ServerSideEncryption': 'aws:kms',
                'SSEKMSKeyId': kms.s3
            };

            bucket.upload(params, function (err, data) {

                if (err) {
                    console.log('Hubo un error en la carga');
                    observer.error(err);
                    observer.complete();
                } else {
                    observer.complete();
                }

            }).on('httpUploadProgress', event => {
                observer.next(event);

            }).send((err, data) => {
                if (err != null) {
                    observer.error(err);
                    observer.complete();
                }
            });

        });

        return respuesta;

    }

    saveFile(archivo: Archivo) {
        return this.http.post(this.URL + 'externos', archivo);
    }


    listFiles(id: string, origen: string): Observable<Archivo[]> {
        return this.http.get<Archivo[]>(this.URL + 'list/' + id + '/origen/' + origen);
    }

    updateVisible(id: string, cveSol: string, visible: boolean): Observable<any> {
        return this.http.post(this.URL + 'visible/id/' + id + '/cveSol/' + cveSol + '/visible/' + visible, null);
    }

    listFilesProyectoVisible(id: string): Observable<Archivo[]> {
        return this.http.get<Archivo[]>(this.URL + 'list/' + id);
    }

    listFilesSolVisible(id: string): Observable<Archivo[]> {
        return this.http.get<Archivo[]>(this.URL + 'listDocSolPro/' + id);
    }

    downloadFile(archivo: string, path: string): Observable<any> {
        return this.http.get(this.URLARCHIVO + 'download/' + archivo + '/' + path, { responseType: 'blob' });
    }

    async downloadExternal(path: string, archivo: string) {
        const replace = path.toString().replace('-', '/');
        this.loaderService.isLoading.next(true);
        const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(archivo));
        const params = { Bucket: environment.account + '-lts-ed-files', Key: replace + '/' + archivo };
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01'
        });
        const body: any = await s3.getObject(params).promise();
        const blob = new Blob([body.Body], { type: mime });
        fileSaver.saveAs(blob, archivo);
        this.loaderService.isLoading.next(false);
    }

    getFileExtension3(filename) {
        return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
    }

    eliminarArchivo(consecutivo) {
        return this.http.delete(this.URL + consecutivo);
    }

    eliminarArchivoProyecto(cveDoc) {
        return this.http.delete(this.URL + 'docProyecto/' + cveDoc);
    }
}
