import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { api } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MfaInfoAdm } from '../interfaces/mfa-info';
import { UsuarioCognito } from '../interfaces/usuario_cognito.interface';

const HEADERS = new HttpHeaders({
  'Content-Type': 'application/json'
});
@Injectable({
  providedIn: 'root'
})
export class MfaService {

  URL = `${api.urlAPI}` + 'api/sesion/';

  constructor(private http: HttpClient) { }

  /**
   * 
   * @returns Observable<any>
   */
  getdatosMfaAdm(): Observable<any> {
    return this.http.get<any>(this.URL + 'cognito/infomfa');
  }

  getMfaOptions(user: string): Observable<any> {
    return this.http.get<any>(this.URL + 'cognito/get_usr_options/' + user);
  }

  updateMfaOptions(usuario: UsuarioCognito): Observable<any> {
    return this.http.put(this.URL + 'cognito/update_mfa_p', usuario, { headers: HEADERS });
  }

}
