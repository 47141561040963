import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { api } from 'src/environments/environment';
import { catchError, Observable, throwError, map } from "rxjs";
import { MessageService } from "primeng/api";
import { PersonaSolicitud } from "../interfaces/personaSolicitud.interface";
import { LoaderService } from "./loader.service";
import { CheckList } from "../interfaces/checklist.interface";
import { Solicitud } from "../interfaces/solicitud.interface";
import { Todo } from "../interfaces/todo.interface";
import { Comentarios } from "../interfaces/comentarios.interface";

const HEADERS = new HttpHeaders({
    'Content-Type': 'application/json'
});
@Injectable()
export class SolicitudService {

    URL = `${api.urlAPI}` + 'api/solicitud/';

    constructor(private http: HttpClient,
        private messageService: MessageService,
        private loaderService: LoaderService) { }

    obtenerUsuariosArea(area: String): Observable<PersonaSolicitud[]> {
        return this.http.get<PersonaSolicitud[]>(this.URL + 'designados/' + area).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.loaderService.isLoading.next(false);
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }


    searchUsuariosSolicitudName(nombre: String): Observable<PersonaSolicitud[]> {
        return this.http.get<PersonaSolicitud[]>(this.URL + 'designados/userName/' + nombre);
    }

    searchUsuarioSolicitudRegistro(numRegistro: String): Observable<PersonaSolicitud> {
        return this.http.get<PersonaSolicitud>(this.URL + 'designados/userRegistro/' + numRegistro).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.loaderService.isLoading.next(false);
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }


    obtenerCheckListArea(area: String): Observable<CheckList[]> {
        return this.http.get<CheckList[]>(this.URL + 'checklist/' + area).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.loaderService.isLoading.next(false);
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    saveSolicitud(solicitud: Solicitud): Observable<Solicitud> {
        return this.http.post(this.URL, solicitud).pipe(
            map((response: any) => response.solicitud as Solicitud),
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    getSolicitudes(idProyecto: string, page: number, size: number): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.URL + 'solicitudes/' + idProyecto + '/page/' + page + '/size/' + size);
    }

    obtenerSolicitud(id: String): Observable<Solicitud> {
        return this.http.get<Solicitud>(this.URL + id).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    obtenerComentariosSol(cveSolicitud: number): Observable<Comentarios[]> {
        return this.http.get<Comentarios[]>(this.URL + 'comentarios/' + cveSolicitud).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.loaderService.isLoading.next(false);
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    saveComentariosSolicitud(comentarios: Comentarios) {
        return this.http.post(this.URL + 'comentarios/', comentarios);
    }

    updateChecklist(checkList: CheckList[]) {
        return this.http.put(this.URL + 'checklist/', checkList);
    }

    updateRespuesta(datosSolicitud: any) {
        return this.http.put(this.URL + 'respuesta/', datosSolicitud);
    }

    updateEstados(datosSolicitud: any) {
        return this.http.put(this.URL + 'estados/', datosSolicitud);
    }

    validarCierreProyecto(cveProyecto: String): Observable<boolean> {
        return this.http.get<boolean>(this.URL + 'validarCerrado/' + cveProyecto).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    getSolicitudesAll(idProyecto: string): Observable<number[]> {
        return this.http.get<number[]>(this.URL + 'solicitudes/' + idProyecto);
    }

    isPermiso(idUsuario: String, idProyecto: number): Observable<boolean> {
        return this.http.get<boolean>(this.URL + 'isPermiso/' + idUsuario + '/' + idProyecto);
    }

}