import { Component, OnInit } from '@angular/core';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { Router } from '@angular/router';
import { ProyectoInformacionService } from 'src/app/services/proyecto-Informacion.service';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { lastValueFrom } from 'rxjs';
import { HomePathService } from 'src/app/services/home-path.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { ProyectoService } from 'src/app/services/proyecto.service';

@Component({
  selector: 'app-datos-proyecto',
  templateUrl: './datos-proyecto.component.html'
})
export class DatosProyectoComponent implements OnInit {

  proyectoInfo: any;
  submitted: boolean = false;
  homePath: string;
  catTipoProyecto: Catalogo[] = [];
  responsablesInformation: OwnersOrResponsables[] = [];
  responsableRegistro: any;

  catTipoClasificacion: Catalogo[] = [];

  constructor(private router: Router,
    public proyectoInformacion: ProyectoInformacionService,
    private catalogoService: CatalogoService,
    private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService,
    private proyectoService: ProyectoService) { }

  async ngOnInit() {

    this.homePath = this.homePathService.getHomePath();
    this.proyectoInfo = this.proyectoInformacion.getProyectoInformacion();
    this.responsablesInformation = this.proyectoInformacion.datosProyecto.usuariosResponsablesProyecto;

    if (this.responsablesInformation == null) {
      const responsableRegistro$ = this.proyectoService.datosUsuario(this.usuarioSesionService.usuario.idUsuario);
      this.responsableRegistro = await lastValueFrom(responsableRegistro$);
      this.responsablesInformation = [];
      const respo: OwnersOrResponsables = {
        id: null,
        cveProyecto: null,
        nombre: this.responsableRegistro.nombre,
        cveUsuario: this.usuarioSesionService.usuario.idUsuario,
        tipoRol: 'TIPRO-2',
        indNotificacion: true,
        correo: this.responsableRegistro.correo,
        lob: this.responsableRegistro.lob
      }

      this.responsablesInformation.push(respo)
      this.proyectoInformacion.datosProyecto.usuariosResponsablesProyecto = this.responsablesInformation;

    }


    const p1$ = this.catalogoService.getCatalogosNemonico('TIPPO', true);
    const p2$ = this.catalogoService.getCatalogosNemonico('CLASF', true);

    this.catTipoProyecto = await lastValueFrom(p1$);
    this.catTipoClasificacion = await lastValueFrom(p2$);


  }



  nextPage() {
    if (this.proyectoInfo.nombreProyecto && this.proyectoInfo.tipoProyecto && this.proyectoInfo.descripcion && this.proyectoInfo.cveInfClasificacion) {
      this.proyectoInformacion.datosProyecto.nombreProyecto = this.proyectoInfo.nombreProyecto
      this.proyectoInformacion.datosProyecto.tipoProyecto = this.proyectoInfo.tipoProyecto
      this.proyectoInformacion.datosProyecto.descripcionProyecto = this.proyectoInfo.descripcion
      this.proyectoInformacion.datosProyecto.cveInfClasificacion = this.proyectoInfo.cveInfClasificacion
      this.proyectoInformacion.datosProyecto.cveUsuarioCreador = this.usuarioSesionService.usuario.idUsuario;


      this.router.navigate([this.homePath + '/proyecto/proyecto/owners']);

      return;
    }
    this.submitted = true;
  }

}