import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BuscarService {

  private URL = `${api.urlAPI}` + 'api/bandeja/';

  constructor(
    private http: HttpClient
  ) { }

  findProyectoByNumFolio(numFolio: string) {
    return this.http.get(this.URL + `buscar/proyecto/byNumFolio/${numFolio}`);
  }

  findProyectoByNomProyecto(nomProyecto: string) {
    return this.http.get(this.URL + `buscar/proyecto/byNomProyecto/${nomProyecto}`);
  }

  findSolicitudByNumFolio(numFolio: string) {
    console.log("Aqui el servico de busqueda de Solicitudes");
    return this.http.get(this.URL + `buscar/solicitud/byNumFolio/${numFolio}`);
  }
}
