import { Component } from '@angular/core';
import { HomePathService } from 'src/app/services/home-path.service';
import { RolModuloService } from 'src/app/services/rol_modulo.service';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {

  homePath: string;

  constructor(private homePathService: HomePathService,
    public usuarioSesionService: UsuarioSesionService,
    public rolModuloService: RolModuloService) {
    this.homePath = this.homePathService.getHomePath();
  }


}
