import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ProyectoService } from 'src/app/services/proyecto.service';
import { Proyecto } from 'src/app/interfaces/proyecto.interface';
import { lastValueFrom } from 'rxjs';
import { Catalogo } from 'src/app/interfaces/catalogo.interface';
import { OwnersOrResponsables } from 'src/app/interfaces/owners-responsables.interface';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { SolicitudService } from 'src/app/services/solicitud.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SolicitudesProyecto } from 'src/app/interfaces/solicitudes-proyecto.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { ArchivoService } from 'src/app/services/archivo.service';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { MimeType } from 'src/app/services/mimeType.service';
import * as fileSaver from 'file-saver';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';
import { BitacoraProyectoSolService } from 'src/app/services/bitacora-p-s-service';
import { BitacoraPage } from 'src/app/interfaces/bitacora-page.interface';
import { NotificacionService } from 'src/app/services/notificacion.service';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CatalogoService } from 'src/app/services/catalogo.service';
import { SearchUsuario } from 'src/app/interfaces/search_usuario.interface';
import { Usuario } from 'src/app/interfaces/usuario_session.interface';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html'
})
export class GestionComponent implements OnInit {

  datePipe = new DatePipe('en-US');
  fechaCreacion: any;
  fechaModificacion: any;
  entrada!: string;
  dlgBitacora: boolean;
  datosProyecto!: Proyecto;
  datosProyectoEditart = {} as Proyecto;
  solicitudes: SolicitudesProyecto[];
  totalElements: number;
  totalElementsB: number;
  bitacoraP: BitacoraPage[];
  archivos: Archivo[] = [];
  homePath: string;
  dlgCerrar: boolean;
  dlgAbrir: boolean;
  dlgCancelar: boolean;
  dlgEditar: boolean;
  dlgAgregarUsuario: boolean;
  dlgAgregarUsuarioRespo: boolean;
  dlgAgregarUsuarioExterno: boolean;
  dlgEditarUsuario: boolean;
  dlgConfirmEditar: boolean;
  dlgConfirmSubida: boolean;
  dlgConfirmDocDelete: boolean;
  dlgEliminarUsuario: boolean;
  dlgEliminarUsuarioRespo: boolean;
  dlgEliminarUsuarioExt: boolean;
  dlgSubirArchivo: boolean;
  listaCorreosNot: string[] = [];
  tipoP: Catalogo[] = [];
  tipoC: Catalogo[] = [];
  personasRel: OwnersOrResponsables[] = [];
  personasOwner: OwnersOrResponsables[] = [];
  personasExternas: OwnersOrResponsables[] = [];
  usuarioMod: OwnersOrResponsables;
  solicitudesProyect!: any[];
  loading: boolean;
  guardarFlag: boolean = false;
  dlgEditPro!: boolean;
  submitted: boolean = false;
  tpOwnerRel: SelectItem[] = [];
  tpOwnerRelSlc: any;
  ownersProyecto: SearchUsuario[] = [];
  results: any;
  relacionar: any;
  perfilesNoPermitidosOwner: number[] = [];
  numRegistroInput: any;
  ownerRegistro: any;
  tpRespoRel: SelectItem[] = [];
  tpRespoRelSlc: any;
  respoProyecto: SearchUsuario[] = [];
  resultsRespo: any;
  relacionarRespo: any;
  perfilesNoPermitidosRespo: number[] = [];
  numRegistroInputRespo: any;
  respoRegistro: any;
  tpExtRel: SelectItem[] = [];
  tpExtRelSlc: any;
  ExternoProyecto: SearchUsuario[] = [];
  resultsExt: any;
  relacionarExt: any;
  perfilesNoPermitidosExt: number[] = [];
  numRegistroInputExt: any;
  extRegistro: any;
  tituloRol: string;
  rol: string;
  idUsuario: string;
  numRegDelete: string;
  usuarioSesion: Usuario;
  isPermisoEdicion: boolean;
  isPermisoCerrar: boolean;
  archivosSubida: any[] = [];
  archivoEliminar: any;
  isPermisoProyec: boolean;

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private proyectoService: ProyectoService,
    private solicitudS: SolicitudService,
    private solicitudService: SolicitudInformacionService,
    private messageService: MessageService,
    private homePathService: HomePathService,
    private archivoService: ArchivoService,
    private loaderService: LoaderService,
    private mimeType: MimeType,
    public usuarioSesionService: UsuarioSesionService,
    private bitacoraPSService: BitacoraProyectoSolService,
    private notificacionesService: NotificacionService,
    private catalogoService: CatalogoService) {
    this.loading = true;
    this.route.params.subscribe(p => {

      this.entrada = (p['entrada']);

    });

  }

  async ngOnInit() {

    this.homePath = this.homePathService.getHomePath();

    this.tpOwnerRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpOwnerRelSlc = 'nombre'

    this.tpRespoRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpRespoRelSlc = 'nombre'

    this.tpExtRel = [
      { label: 'Nombre', value: 'nombre' },
      { label: 'No. registro', value: 'registro' }
    ];
    this.tpExtRelSlc = 'nombre'

    this.datosProyecto = {} as Proyecto;
    this.datosProyecto.tipoProyecto = {} as Catalogo;
    this.datosProyecto.cveInfClasificacion = {} as Catalogo;
    this.datosProyecto.estadoProyecto = {} as Catalogo;
    this.datosProyecto.usuariosOwnerProyecto = {} as OwnersOrResponsables[];
    this.datosProyecto.usuariosResponsablesProyecto = {} as OwnersOrResponsables[];
    this.datosProyecto.usuariosFirmaExterna = {} as OwnersOrResponsables[];
    this.usuarioSesion = {} as Usuario;

    this.datosProyectoEditart.tipoProyecto = {} as Catalogo;
    this.datosProyectoEditart.cveInfClasificacion = {} as Catalogo;
    this.datosProyectoEditart.estadoProyecto = {} as Catalogo;

    this.usuarioMod = {} as OwnersOrResponsables;

    // console.log('valor de isPermiso:::' + this.isPermisoProyec)

    this.proyectoService.isPermiso(this.usuarioSesionService.usuario.idUsuario, Number(this.entrada))
      .subscribe((response: any) => {
        this.usuarioSesion = this.usuarioSesionService.usuario;
        //  console.log("valor rol user logeado: " + this.usuarioSesion.rol);
        //  console.log("valor numRegistro user logeado: " + this.usuarioSesion.idUsuario);
        this.isPermisoProyec = response;
        //  console.log('valor rol')                              
        if (this.isPermisoProyec || this.usuarioSesion.rol == 'ADMINISTRADOR' || this.usuarioSesion.rol == 'COORDINADOR') {

          this.proyectoService.obtenerProyecto(this.entrada).subscribe(
            (response: any) => {
              this.datosProyecto = response;
              this.fechaCreacion = this.datePipe.transform(this.datosProyecto.fechaCreacion, 'dd/MM/yyyy');
              this.fechaModificacion = this.datePipe.transform(this.datosProyecto.fechaModificacion, 'dd/MM/yyyy');
              //   console.log("folio proyecto obtenido: " + this.datosProyecto.numFolio);
              this.datosProyectoEditart.id = this.datosProyecto.id;
              this.datosProyectoEditart.numFolio = this.datosProyecto.numFolio;
              this.datosProyectoEditart.nombreProyecto = this.datosProyecto.nombreProyecto;
              this.datosProyectoEditart.tipoProyecto = this.datosProyecto.tipoProyecto;
              this.datosProyectoEditart.cveInfClasificacion = this.datosProyecto.cveInfClasificacion;
              this.datosProyectoEditart.estadoProyecto = this.datosProyecto.estadoProyecto;
              this.datosProyectoEditart.descripcionProyecto = this.datosProyecto.descripcionProyecto;

              this.personasOwner = this.datosProyecto.usuariosOwnerProyecto;
              this.personasRel = this.datosProyecto.usuariosResponsablesProyecto;

              if (this.datosProyecto.usuariosFirmaExterna.length > 0) {
                this.personasExternas = this.datosProyecto.usuariosFirmaExterna;
              } else {
                this.personasExternas = [];
              }
              this.isEdicion();
              //console.log("valor ispermisoEdicion::: " + this.isPermisoEdicion);
              this.isCerrar();
              // console.log("valor ispermisoCerrar::: " + this.isPermisoCerrar);
              this.perfilesNoPermitidosOwner.push(6);
              this.perfilesNoPermitidosOwner.push(3);
              this.perfilesNoPermitidosOwner.push(4);

              this.perfilesNoPermitidosRespo.push(6);
              this.perfilesNoPermitidosRespo.push(3);


              this.perfilesNoPermitidosExt.push(1);
              this.perfilesNoPermitidosExt.push(5);
              this.perfilesNoPermitidosExt.push(4);
              this.perfilesNoPermitidosExt.push(3);

              this.catalogoService.getCatalogosNemonico('TIPPO', true).subscribe(
                (response: any) => {
                  this.tipoP = response;
                }
              );
              this.catalogoService.getCatalogosNemonico('CLASF', true).subscribe(
                (response: any) => {
                  this.tipoC = response;
                }
              );


              this.archivoService.listFiles(this.datosProyecto.id + '', 'ORDOC-1').subscribe(
                (response: any) => {
                  this.archivos = response;
                }
              );

              this.solicitudS.getSolicitudes(this.datosProyecto.id + '', 0, 5).subscribe(
                (data: any) => {
                  this.solicitudes = data.content;
                  this.totalElements = data.totalElements;
                },
                error => {
                  console.error('Error--->', error);
                  this.messageService.add({ severity: 'error', summary: 'Error!', detail: 'Registros no cargados.' });
                }
              )


            }
          );



          const bitacora = {
            cveBitacora: null,
            descAccion: 'Consulta de proyecto',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: Number(this.entrada),
            tipoMovimiento: {
              id: 'TIPMO-4',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };

          this.bitacoraPSService.saveBitacora(bitacora).subscribe();


          this.loading = false;
        } else {

          const bitacora = {
            cveBitacora: null,
            descAccion: 'Usuario sin autorizacion al proyecto',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: Number(this.entrada),
            tipoMovimiento: {
              id: 'TIPMO-4',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };

          const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora).subscribe();


          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Sin permisos para ver el proyecto' });
          this.router.navigate([this.homePath + '/proyecto/inicio'])
        }

      })


  }

  isEdicion() {
    let existeRespo = this.personasRel.find(num => num.cveUsuario === this.usuarioSesion.idUsuario);
    if (existeRespo != null) {
      //  console.log("si me encuentro en responsables" + this.usuarioSesion.rol)
    }
    // console.log("VALOR ESTADO:::" + this.datosProyecto.estadoProyecto.desDetcat)
    if (this.usuarioSesion.rol == 'ADMINISTRADOR' || this.usuarioSesion.rol == 'COORDINADOR') {
      //    console.log("paso validacion administrador")
      this.isPermisoEdicion = true;
    } else if (existeRespo != null) {
      //    console.log("paso validacion responsable")
      this.isPermisoEdicion = true
    } else {
      //   console.log("no paso validacion no soy admin ni responsable")
      this.isPermisoEdicion = false
    }

  }

  isCerrar() {
    let existeRespo = this.personasRel.find(num => num.cveUsuario === this.usuarioSesion.idUsuario);
    if (existeRespo != null) {
      //  console.log("si me encuentro en responsables funcion cerrar" + this.usuarioSesion.rol)
      this.isPermisoCerrar = true;
    }
    else {
      //  console.log("no paso validacion estado es cerrado o cancelado funcion cerrar")
      this.isPermisoCerrar = false;
    }

  }


  loadLazy(event: LazyLoadEvent) {

    this.solicitudS.getSolicitudes(this.datosProyecto.id + "", event.first! / event.rows!, event.rows!).subscribe(
      (data: any) => {
        this.solicitudes = data.content;
        this.totalElements = data.totalElements;

      },
      error => {
        console.error('Error--->', error);

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
      }
    )

  }


  myUploader(event, formUpload): void {

    if (event.files.length !== 0) {
      for (let file of event.files) {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: file,
          fechaCreacion: null,
          nombre: file.name,
          origenDoc: 'ORDOC-1',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: '0'
        }
        let existeGlobal = this.archivos.find(arch => arch.nombre === archivo.nombre);
        let existeTemp = this.archivosSubida.find(arch => arch.nombre === archivo.nombre);

        if (existeTemp == null && existeGlobal == null) {
          this.archivosSubida.push(archivo);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos listos para subir' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya fue agregado un documento con el mismo nombre', life: 6000 });
        }
        formUpload.clear();

      }

      /*    this.archivos.forEach( x => {
           console.log("nombre archivos: " + x.nombre);
           console.log("tamaño: " + x.file.size);
          })  */

    }
  }

  eliminaArchSubidaTemp(arch: any) {
    this.archivosSubida = this.archivosSubida.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });
    this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Archivo eliminado.', life: 6000 });
  }

  async download(nombre: string, path: string) {
    this.loaderService.isLoading.next(true);
    if (this.homePath.includes('-internal')) {
      const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(nombre));
      this.archivoService.downloadFile(nombre, path)
        .subscribe(
          file => {
            const data: Blob = new Blob([file], { type: mime });
            fileSaver.saveAs(data, nombre);
            this.loaderService.isLoading.next(false);

            const bitacoraSubida = {
              cveBitacora: null,
              descAccion: 'Descarga archivo, nombre: ' + nombre + ' peso ' + data.size + ' bytes ',
              cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
              cveElemento: this.datosProyecto.id!,
              tipoMovimiento: {
                id: 'TIPMO-6',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              tipoBitacora: {
                id: 'TIPBI-1',
                desNemonico: '',
                cveDetcat: null,
                desDetcat: '',
                desDetcor: '',
                staRegis: true,
                catalogo: null,
                pais: null,
                seleccionar: null
              },
              cveIdProyecto: null
            };

            this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();


          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo no descargado.', life: 6000 });
          });


    } else {
      this.archivoService.downloadExternal(path, nombre);
      const bitacoraSubida = {
        cveBitacora: null,
        descAccion: 'Descarga archivo, nombre: ' + nombre + ' ',
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosProyecto.id!,
        tipoMovimiento: {
          id: 'TIPMO-6',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-1',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: null
      };

      this.bitacoraPSService.saveBitacora(bitacoraSubida).subscribe();
    }
  }

  getFileExtension3(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  showAgregarUsuario(titulo: any, rol: any) {
    this.tituloRol = titulo;
    this.rol = rol;
    this.dlgAgregarUsuario = true;
  }

  showAgregarUsuarioRespo(titulo: any, rol: any) {
    this.tituloRol = titulo;
    this.rol = rol;
    this.dlgAgregarUsuarioRespo = true;
  }

  showAgregarUsuarioExt(titulo: any, rol: any) {
    this.tituloRol = titulo;
    this.rol = rol;
    this.dlgAgregarUsuarioExterno = true;
  }

  async editarProyecto() {
    if (this.datosProyectoEditart.nombreProyecto.trim() && this.datosProyectoEditart.descripcionProyecto.trim()) {

      //  console.log("paso validacion");
      this.dlgEditar = true;
      return;
    }
    this.submitted = true;
    //  console.log("no paso validacion");
    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Favor de llenar los campos obligatorios.', life: 6000 });
  }

  async editarConfirmProyecto() {
    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;

    const editar$ = this.proyectoService.updateDatosProyecto(this.datosProyectoEditart);
    await lastValueFrom(editar$);

    if (this.datosProyecto.id != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'Edición del proyecto: ' + this.datosProyecto.numFolio + '- nombre anterior: ' +
          this.datosProyecto.nombreProyecto + '- nombre actualizado: ' + this.datosProyectoEditart.nombreProyecto +
          '- tipo proyecto anterior: ' + this.datosProyecto.tipoProyecto.desDetcat + '- tipo proyecto actualizado: ' +
          this.datosProyectoEditart.tipoProyecto.desDetcat + '- tipo clasificacióin anterior: ' +
          this.datosProyecto.cveInfClasificacion.desDetcat + '- tipo clasificación actual: ' +
          this.datosProyectoEditart.cveInfClasificacion.desDetcat + '- descripción anterior: ' +
          this.datosProyecto.descripcionProyecto.substring(0, 60) + '- descripción actual: ' + this.datosProyectoEditart.descripcionProyecto.substring(0, 60),
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosProyecto.id,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-1',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: null
      };

      const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacora$);
    }

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Los datos del proyecto fueron editados con éxito' });
    this.cancel();
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;
    this.ngOnInit();
  }

  async searchOwners(event: any) {
    //  console.log("entrando a search " + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosOwner, this.usuarioSesionService.usuario.pais).subscribe(
      owners => this.results = owners
    );
  }

  async searchResponsables(event: any) {
    // console.log("entrando a search " + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosRespo, this.usuarioSesionService.usuario.pais).subscribe(
      responsables => this.resultsRespo = responsables
    );
  }

  async searchExternos(event: any) {
    //  console.log("entrando a search " + event.query);
    this.proyectoService.searchUsuariosName(event.query, this.perfilesNoPermitidosExt, this.usuarioSesionService.usuario.pais).subscribe(
      externos => this.resultsExt = externos
    );
  }

  async agregarOwner() {

    if (this.tpOwnerRelSlc === 'nombre') {
      if (this.relacionar != null && this.relacionar.numRegistro != null) {
        const ownerTemp = {
          cveProyecto: this.datosProyecto.id,
          cveUsuario: this.relacionar.numRegistro,
          nombre: this.relacionar.nombre,
          indNotificacion: this.relacionar.indNotificacion,
          tipoRol: 'TIPRO-1'
        }
        this.relacionar = null;

        let existeOwner = this.personasOwner.find(num => num.cveUsuario === ownerTemp.cveUsuario);
        let existeRepo = this.personasRel.find(num => num.cveUsuario === ownerTemp.cveUsuario)
        if (existeOwner != null || existeRepo != null) {
          this.cancel();
          //   console.log("valor existeOwner es verdadero");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario ya fue seleccionado como owner o responsable', life: 6000 });
        } else {
          //    console.log("agregamos al owner el array ya fue inicializado");
          this.proyectoService.saveUserProyect(ownerTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como owner: ' + ownerTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Owner agregado.', life: 6000 });
          });
          // lastValueFrom(agregarOw$);

        }

      } else {
        this.cancel();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un owner.', life: 6000 });
      }
    } else {
      if (this.numRegistroInput != null && this.numRegistroInput.length > 0) {
        this.loaderService.isLoading.next(true);

        let existe = this.personasOwner.find(num => num.cveUsuario === this.numRegistroInput);
        let existeRepo = this.personasRel.find(num => num.cveUsuario === this.numRegistroInput)

        if (existe != null || existeRepo != null) {
          //   console.log("valor existeOwner es verdadero desde registro");
          this.numRegistroInput = null;
          this.loaderService.isLoading.next(false);
          this.cancel();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado como Owner o Responsable', life: 6000 });
        } else {
          const ownerRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInput, this.perfilesNoPermitidosOwner, this.usuarioSesionService.usuario.pais);
          this.ownerRegistro = await lastValueFrom(ownerRegistro$).catch((e) => {
            //    console.log("cacheando error en component :")
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
          });
          const ownerTemp = {
            cveProyecto: this.datosProyecto.id,
            cveUsuario: this.ownerRegistro.numRegistro,
            nombre: this.ownerRegistro.nombre,
            indNotificacion: this.ownerRegistro.indNotificacion,
            tipoRol: 'TIPRO-1'
          }
          //   console.log("agregamos al owner el array ya fue inicializado estamos en modo registro num " + this.ownerRegistro.numRegistro);
          this.proyectoService.saveUserProyect(ownerTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como owner: ' + ownerTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.ownerRegistro = null;
            this.numRegistroInput = null;
            this.loaderService.isLoading.next(false);
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Owner agregado.', life: 6000 });
          });
          //lastValueFrom(agregarOw$);

        }

      }
      else {
        this.loaderService.isLoading.next(false);
        this.cancel();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el numero de registro', life: 6000 });
      }
    }
  }

  async agregarRespo() {

    if (this.tpRespoRelSlc === 'nombre') {
      if (this.relacionarRespo != null && this.relacionarRespo.numRegistro != null) {
        const respoTemp = {
          cveProyecto: this.datosProyecto.id,
          cveUsuario: this.relacionarRespo.numRegistro,
          nombre: this.relacionarRespo.nombre,
          indNotificacion: this.relacionarRespo.indNotificacion,
          tipoRol: 'TIPRO-2'
        }
        this.relacionarRespo = null;

        let existeOwner = this.personasOwner.find(num => num.cveUsuario === respoTemp.cveUsuario);
        let existeRepo = this.personasRel.find(num => num.cveUsuario === respoTemp.cveUsuario)
        if (existeOwner != null || existeRepo != null) {
          this.cancel();
          //   console.log("valor existeOwner es verdadero");
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado como owner o responsable', life: 6000 });
        } else {
          //   console.log("agregamos al responsable el array ya fue inicializado");
          this.proyectoService.saveUserProyect(respoTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como responsable: ' + respoTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Responsable agregado.', life: 6000 });
          });
          // lastValueFrom(agregarOw$);

        }

      } else {
        this.cancel();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe seleccionar un responsable.', life: 6000 });
      }
    } else {
      if (this.numRegistroInputRespo != null && this.numRegistroInputRespo.length > 0) {
        this.loaderService.isLoading.next(true);

        let existe = this.personasOwner.find(num => num.cveUsuario === this.numRegistroInputRespo);
        let existeRepo = this.personasRel.find(num => num.cveUsuario === this.numRegistroInputRespo)

        if (existe != null || existeRepo != null) {
          //     console.log("valor existeOwner es verdadero desde registro");
          this.numRegistroInputRespo = null;
          this.loaderService.isLoading.next(false);
          this.cancel();
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado como owner o responsable', life: 6000 });
        } else {
          //      console.log("inpurRespo: " + this.numRegistroInputRespo)
          const respoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInputRespo, this.perfilesNoPermitidosRespo, this.usuarioSesionService.usuario.pais);
          this.respoRegistro = await lastValueFrom(respoRegistro$).catch((e) => {
            //       console.log("cacheando error en component :")
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
            this.numRegistroInputRespo = null;
            this.loaderService.isLoading.next(false);
          });
          //    console.log("user respo regresado " + this.respoRegistro.numRegistro)
          const respoTemp = {
            cveProyecto: this.datosProyecto.id,
            cveUsuario: this.respoRegistro.numRegistro,
            nombre: this.respoRegistro.nombre,
            indNotificacion: this.respoRegistro.indNotificacion,
            tipoRol: 'TIPRO-2'
          }

          this.proyectoService.saveUserProyect(respoTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como responsable: ' + respoTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.respoRegistro = null;
            this.numRegistroInputRespo = null;
            this.loaderService.isLoading.next(false);
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Responsable agregado.', life: 6000 });
          });
          //lastValueFrom(agregarOw$);
        }
      }
      else {
        this.loaderService.isLoading.next(false);
        this.cancel();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el numero de registro', life: 6000 });
      }
    }
  }

  async agregarExt() {
    if (this.tpExtRelSlc === 'nombre') {
      if (this.relacionarExt != null && this.relacionarExt.numRegistro != null) {
        const externoTemp = {
          cveProyecto: this.datosProyecto.id,
          cveUsuario: this.relacionarExt.numRegistro,
          nombre: this.relacionarExt.nombre,
          indNotificacion: this.relacionarExt.indNotificacion,
          tipoRol: 'TIPRO-3'
        }
        this.relacionarExt = null;
        if (this.personasExternas.length > 0) {
          let existeExterno = this.personasExternas.find(num => num.cveUsuario === externoTemp.cveUsuario);
          if (existeExterno != null) {
            //   console.log("valor existe es verdadero");
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue seleccionado', life: 6000 });
          } else {
            //  console.log("agregamos al externo el array ya fue inicializado");
            this.proyectoService.saveUserProyect(externoTemp).subscribe(e => {
              if (this.datosProyecto.id != null) {
                const bitacora = {
                  cveBitacora: null,
                  descAccion: 'Usuario agregado como externo: ' + externoTemp.cveUsuario,
                  cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                  cveElemento: this.datosProyecto.id,
                  tipoMovimiento: {
                    id: 'TIPMO-1',
                    desNemonico: '',
                    cveDetcat: null,
                    desDetcat: '',
                    desDetcor: '',
                    staRegis: true,
                    catalogo: null,
                    pais: null,
                    seleccionar: null
                  },
                  tipoBitacora: {
                    id: 'TIPBI-1',
                    desNemonico: '',
                    cveDetcat: null,
                    desDetcat: '',
                    desDetcor: '',
                    staRegis: true,
                    catalogo: null,
                    pais: null,
                    seleccionar: null
                  },
                  cveIdProyecto: null
                };

                this.bitacoraPSService.saveBitacora(bitacora).subscribe();

              }
              this.cancel();
              this.ngOnInit();
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Externo agregado.', life: 6000 });
            });
          }
        } else {

          //    console.log("valor nuevo ")
          this.proyectoService.saveUserProyect(externoTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como externo: ' + externoTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Externo agregado.', life: 6000 });
          });
        }

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No ha seleccionado un usuario.', life: 6000 });
      }
    } else {
      if (this.numRegistroInputExt != null && this.numRegistroInputExt.length > 0) {
        this.loaderService.isLoading.next(true);
        if (this.personasExternas.length > 0) {
          let existeE = this.personasExternas.find(num => num.cveUsuario === this.numRegistroInputExt);
          if (existeE != null) {
            //    console.log("valor existe es verdadero desde registro");
            this.numRegistroInputExt = null;
            this.loaderService.isLoading.next(false);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El Usuario ya fue agregado', life: 6000 });
          } else {
            const externoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInputExt, this.perfilesNoPermitidosExt, this.usuarioSesionService.usuario.pais);
            this.extRegistro = await lastValueFrom(externoRegistro$).catch((e) => {
              //      console.log("cacheando error en component :")
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
              this.numRegistroInputExt = null;
              this.loaderService.isLoading.next(false);
            });
            //      console.log("user respo regresado " + this.extRegistro.numRegistro)
            const respoTemp = {
              cveProyecto: this.datosProyecto.id,
              cveUsuario: this.extRegistro.numRegistro,
              nombre: this.extRegistro.nombre,
              indNotificacion: this.extRegistro.indNotificacion,
              tipoRol: 'TIPRO-3'
            }

            this.proyectoService.saveUserProyect(respoTemp).subscribe(e => {
              if (this.datosProyecto.id != null) {
                const bitacora = {
                  cveBitacora: null,
                  descAccion: 'Usuario agregado como externo: ' + respoTemp.cveUsuario,
                  cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                  cveElemento: this.datosProyecto.id,
                  tipoMovimiento: {
                    id: 'TIPMO-1',
                    desNemonico: '',
                    cveDetcat: null,
                    desDetcat: '',
                    desDetcor: '',
                    staRegis: true,
                    catalogo: null,
                    pais: null,
                    seleccionar: null
                  },
                  tipoBitacora: {
                    id: 'TIPBI-1',
                    desNemonico: '',
                    cveDetcat: null,
                    desDetcat: '',
                    desDetcor: '',
                    staRegis: true,
                    catalogo: null,
                    pais: null,
                    seleccionar: null
                  },
                  cveIdProyecto: null
                };
                this.bitacoraPSService.saveBitacora(bitacora).subscribe();
              }
              this.extRegistro = null;
              this.numRegistroInputExt = null;
              this.loaderService.isLoading.next(false);
              this.cancel();
              this.ngOnInit();
              this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Externo agregado.', life: 6000 });
            });
          }
        } else {

          const externoRegistro$ = this.proyectoService.searchUsuarioRegistro(this.numRegistroInputExt, this.perfilesNoPermitidosExt, this.usuarioSesionService.usuario.pais);
          this.extRegistro = await lastValueFrom(externoRegistro$).catch((e) => {
            //    console.log("cacheando error en component :")
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El usuario no existe en el sistema o tiene un perfil diferente para este rol', life: 6000 });
            this.numRegistroInputExt = null;
            this.loaderService.isLoading.next(false);
          });

          //  console.log("user respo regresado " + this.extRegistro.numRegistro)
          const respoTemp = {
            cveProyecto: this.datosProyecto.id,
            cveUsuario: this.extRegistro.numRegistro,
            nombre: this.extRegistro.nombre,
            indNotificacion: this.extRegistro.indNotificacion,
            tipoRol: 'TIPRO-3'
          }

          this.proyectoService.saveUserProyect(respoTemp).subscribe(e => {
            if (this.datosProyecto.id != null) {
              const bitacora = {
                cveBitacora: null,
                descAccion: 'Usuario agregado como externo: ' + respoTemp.cveUsuario,
                cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
                cveElemento: this.datosProyecto.id,
                tipoMovimiento: {
                  id: 'TIPMO-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                tipoBitacora: {
                  id: 'TIPBI-1',
                  desNemonico: '',
                  cveDetcat: null,
                  desDetcat: '',
                  desDetcor: '',
                  staRegis: true,
                  catalogo: null,
                  pais: null,
                  seleccionar: null
                },
                cveIdProyecto: null
              };

              this.bitacoraPSService.saveBitacora(bitacora).subscribe();

            }
            this.extRegistro = null;
            this.numRegistroInputExt = null;
            this.loaderService.isLoading.next(false);
            this.cancel();
            this.ngOnInit();
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Externo agregado.', life: 6000 });
          });

        }
      }
      else {
        this.loaderService.isLoading.next(false);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe ingresar el número de registro', life: 6000 });
      }
    }

  }

  showEditarUsuario(titulo: any, rol: any, id: number) {
    this.tituloRol = titulo;
    this.rol = rol;
    this.dlgEditarUsuario = true;
    this.usuarioMod = {} as OwnersOrResponsables;

    if (rol == 'OWNER') {
      var results = this.personasOwner.filter(function (ow) { return Number(ow.id) == id });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
      this.usuarioMod.id = user.id;
      this.usuarioMod.cveUsuario = user.cveUsuario;
      this.usuarioMod.tipoRol = user.tipoRol;
      this.usuarioMod.indNotificacion = user.indNotificacion;
      this.usuarioMod.nombre = user.nombre;

      //   console.log("usuario encontrado " + this.usuarioMod.nombre);
    } else if (rol == 'RESPONSABLE') {
      var results = this.personasRel.filter(function (re) { return Number(re.id) == id });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
      this.usuarioMod.id = user.id;
      this.usuarioMod.cveUsuario = user.cveUsuario;
      this.usuarioMod.tipoRol = user.tipoRol;
      this.usuarioMod.indNotificacion = user.indNotificacion;
      this.usuarioMod.nombre = user.nombre;

      //   console.log("usuario encontrado " + this.usuarioMod.nombre);
    } else {
      var results = this.personasExternas.filter(function (re) { return Number(re.id) == id });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
      this.usuarioMod.id = user.id;
      this.usuarioMod.cveUsuario = user.cveUsuario;
      this.usuarioMod.tipoRol = user.tipoRol;
      this.usuarioMod.indNotificacion = user.indNotificacion;
      this.usuarioMod.nombre = user.nombre;

      //  console.log("usuario encontrado " + this.usuarioMod.nombre);
    }

  }

  showEliminarDoc(arch: any) {
    this.archivoEliminar = arch;
    this.dlgConfirmDocDelete = true;

  }

  showEliminarUsuario(titulo: any, id: string, numReg: string) {
    this.tituloRol = titulo;
    this.dlgEliminarUsuario = true;
    this.idUsuario = id;
    this.numRegDelete = numReg;
  }

  showEliminarUsuarioRespo(titulo: any, id: string, numReg: string) {
    this.tituloRol = titulo;
    this.dlgEliminarUsuarioRespo = true;
    this.idUsuario = id;
    this.numRegDelete = numReg;
  }

  showEliminarUsuarioExt(titulo: any, id: string, numReg: string) {
    this.tituloRol = titulo;
    this.dlgEliminarUsuarioExt = true;
    this.idUsuario = id;
    this.numRegDelete = numReg;
  }

  async editarUsuario() {
    var idUser = this.usuarioMod.id;

    if (this.usuarioMod.tipoRol == "OWNER") {
      var results = this.personasOwner.filter(function (ow) { return ow.id == idUser });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
    } else if (this.usuarioMod.tipoRol == "RESPONSABLE") {
      var results = this.personasRel.filter(function (re) { return re.id == idUser });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
    } else {
      var results = this.personasExternas.filter(function (re) { return re.id == idUser });
      var user = (results.length > 0) ? results[0] : {} as OwnersOrResponsables;
    }

    if (this.usuarioMod.indNotificacion != user.indNotificacion) {
      this.proyectoService.updateUsuarioProyecto(this.usuarioMod).subscribe(e => {
        if (this.datosProyecto.id != null) {
          const bitacora = {
            cveBitacora: null,
            descAccion: 'Usuario ' + this.usuarioMod.cveUsuario + ' Modificado, envío notificaciones: ' + this.usuarioMod.indNotificacion,
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosProyecto.id,
            tipoMovimiento: {
              id: 'TIPMO-2',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };
          this.bitacoraPSService.saveBitacora(bitacora).subscribe();
        }

        this.cancel();
        this.ngOnInit();
      });
      //  console.log("entro a modificar")
    } else {
      //  console.log("no cambio los datos")
      this.cancel();
    }
  }

  async eliminarUsuario(rol: string) {
    var size;
    if (rol == "OWNER") {
      size = this.personasOwner.length;
    } else if (rol == "RESPONSABLE") {
      size = this.personasRel.length;
    } else {
      size = 2;
    }
    if (size > 1) {
      this.proyectoService.eliminarUsuarioProyecto(this.idUsuario).subscribe(e => {
        if (this.datosProyecto.id != null) {
          const bitacora = {
            cveBitacora: null,
            descAccion: 'Usuario ' + this.numRegDelete + ' Eliminado como ' + rol,
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosProyecto.id,
            tipoMovimiento: {
              id: 'TIPMO-3',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };

          this.bitacoraPSService.saveBitacora(bitacora).subscribe();

        }

        this.cancel();
        this.ngOnInit();

      });
    } else {
      this.loaderService.isLoading.next(false);
      this.cancel();
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Debe existir al menos un ' + rol, life: 6000 });
    }
  }

  async showBitacora() {
    if (this.datosProyecto.id != null) {
      this.loaderService.isLoading.next(true);
      this.bitacoraPSService.getBitacoraProyecto(0, 5, this.datosProyecto.id).subscribe(
        (data: any) => {
          this.bitacoraP = data.content;
          this.totalElementsB = data.totalElements;
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Consulta realizada.' });
        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
      this.dlgBitacora = true;
    }

  }

  loadLazyB(event: LazyLoadEvent) {

    if (this.datosProyecto.id != null) {
      this.loaderService.isLoading.next(true);

      this.bitacoraPSService.getBitacoraProyecto(event.first! / event.rows!, event.rows!, this.datosProyecto.id).subscribe(
        (data: any) => {
          this.bitacoraP = data.content;
          this.totalElementsB = data.totalElements;
          this.loaderService.isLoading.next(false);

        },
        error => {
          console.error('Error--->', error);
          this.loaderService.isLoading.next(false);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Registros no cargados.' });
        }
      )
    }
  }


  cancel() {
    this.dlgCerrar = false;
    this.dlgAbrir = false;
    this.dlgCancelar = false;
    this.dlgEditPro = false;
    this.dlgEditar = false;
    this.dlgAgregarUsuario = false;
    this.dlgEditarUsuario = false;
    this.dlgConfirmEditar = false;
    this.dlgEliminarUsuario = false;
    this.dlgEliminarUsuarioRespo = false;
    this.dlgEliminarUsuarioExt = false;
    this.dlgAgregarUsuarioRespo = false;
    this.dlgAgregarUsuarioExterno = false;
    this.dlgSubirArchivo = false;
    this.dlgConfirmSubida = false;
    this.dlgConfirmDocDelete = false;
    this.archivosSubida = [];
  }

  nextPage() {
    this.solicitudService.datosSolicitud.idProyecto = +this.entrada;
    this.router.navigate([this.homePath + '/proyecto/solicitud'])
  }

  prevPage() {
    this.router.navigate([this.homePath + '/proyecto/bandeja/1']);
  }

  routeDetalleSolicitud(id: number) {
    this.router.navigate([this.homePath + '/proyecto/detalleSolicitud', id, this.entrada]);
  }

  async cerrar() {

    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;
    const estatus$ = this.solicitudS.validarCierreProyecto(this.datosProyecto.id + '');
    let estatus = await lastValueFrom(estatus$);
    //   console.log("respuesta estatus: " + estatus);

    if (estatus == true) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El proyecto cuenta con solicitudes abiertas' });
      this.cancel();
      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
    }

    else {

      const datosProyecto = {
        id: this.datosProyecto.id,
        estadoProyecto: {
          id: "EDPRO-4"
        }
      }

      const cerrar$ = this.proyectoService.updateEstados(datosProyecto);
      await lastValueFrom(cerrar$);

      if (this.datosProyecto.id != null) {
        const bitacora = {
          cveBitacora: null,
          descAccion: 'Cierre del proyecto: ' + this.datosProyecto.numFolio,
          cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
          cveElemento: this.datosProyecto.id,
          tipoMovimiento: {
            id: 'TIPMO-2',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          tipoBitacora: {
            id: 'TIPBI-1',
            desNemonico: '',
            cveDetcat: null,
            desDetcat: '',
            desDetcor: '',
            staRegis: true,
            catalogo: null,
            pais: null,
            seleccionar: null
          },
          cveIdProyecto: null
        };

        const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
        await lastValueFrom(bitacora$);
      }

      for await (const cm of this.personasOwner) {

        if (cm.indNotificacion) {
          const ownerRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
          let rownerRegistro = await lastValueFrom(ownerRegistro$);
          this.listaCorreosNot.push(rownerRegistro.correo);
        }

      }

      for await (const rp of this.personasRel) {

        if (rp.indNotificacion) {
          const responsableRegistro$ = this.proyectoService.datosUsuario(rp.cveUsuario);
          let responsableRegistro = await lastValueFrom(responsableRegistro$);
          this.listaCorreosNot.push(responsableRegistro.correo);
        }

      }

      for await (const ex of this.personasExternas) {

        if (ex.indNotificacion) {
          const externoRegistro$ = this.proyectoService.datosUsuario(ex.cveUsuario);
          let externoRegistro = await lastValueFrom(externoRegistro$);
          this.listaCorreosNot.push(externoRegistro.correo);
        }

      }

      const mailDTO = {
        usuariosMail: this.listaCorreosNot,
        subject: "Notificación cierre de proyecto",
        infoMailDTO: {
          numFolio: this.datosProyecto.numFolio!,
          titulo: this.datosProyecto.nombreProyecto,
          descripcion: this.datosProyecto.descripcionProyecto,
          usuariosTramite: []
        }
      }

      const sendEmail$ = this.notificacionesService.notificarCierreProyecto(mailDTO);
      await lastValueFrom(sendEmail$);

      const bitacoraEmailAlta = {
        cveBitacora: null,
        descAccion: 'Envio correo electronico cierre proyecto número de folio: ' + this.datosProyecto.numFolio +
          ' título del proyecto: ' + this.datosProyecto.nombreProyecto + ' descripción ' + this.datosProyecto.descripcionProyecto +
          ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosProyecto.id!,
        tipoMovimiento: {
          id: 'TIPMO-5',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-1',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: null
      };

      const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmailAlta);
      await lastValueFrom(bitacoraEmail$);

      this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El proyecto fue cerrado con exito' });
      this.cancel();
      this.loaderService.isLoading.next(false);
      this.guardarFlag = false;
      this.ngOnInit();

    }
  }

  async abrir() {

    this.loaderService.isLoading.next(true);
    this.guardarFlag = true;

    const datosProyecto = {
      id: this.datosProyecto.id,
      estadoProyecto: {
        id: "EDPRO-1"
      }
    }

    const cerrar$ = this.proyectoService.updateEstados(datosProyecto);
    await lastValueFrom(cerrar$);

    if (this.datosProyecto.id != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'Se reabre el proyecto: ' + this.datosProyecto.numFolio,
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosProyecto.id,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-1',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: null
      };

      const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacora$);
    }

    for await (const cm of this.personasOwner) {

      if (cm.indNotificacion) {
        const ownerRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
        let rownerRegistro = await lastValueFrom(ownerRegistro$);
        this.listaCorreosNot.push(rownerRegistro.correo);
      }

    }

    for await (const rp of this.personasRel) {

      if (rp.indNotificacion) {
        const responsableRegistro$ = this.proyectoService.datosUsuario(rp.cveUsuario);
        let responsableRegistro = await lastValueFrom(responsableRegistro$);
        this.listaCorreosNot.push(responsableRegistro.correo);
      }

    }

    for await (const ex of this.personasExternas) {

      if (ex.indNotificacion) {
        const externoRegistro$ = this.proyectoService.datosUsuario(ex.cveUsuario);
        let externoRegistro = await lastValueFrom(externoRegistro$);
        this.listaCorreosNot.push(externoRegistro.correo);
      }

    }

    const mailDTO = {
      usuariosMail: this.listaCorreosNot,
      subject: "Notificación se reabre el proyecto",
      infoMailDTO: {
        numFolio: this.datosProyecto.numFolio!,
        titulo: this.datosProyecto.nombreProyecto,
        descripcion: this.datosProyecto.descripcionProyecto,
        usuariosTramite: []
      }
    }

    const sendEmail$ = this.notificacionesService.notificarAbrirProyecto(mailDTO);
    await lastValueFrom(sendEmail$);

    const bitacoraEmailAlta = {
      cveBitacora: null,
      descAccion: 'Envío correo electrónico se reabre proyecto número de folio: ' + this.datosProyecto.numFolio,
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosProyecto.id!,
      tipoMovimiento: {
        id: 'TIPMO-5',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmailAlta);
    await lastValueFrom(bitacoraEmail$);

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El proyecto fue reabierto con exito' });
    this.cancel();
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;
    this.ngOnInit();


  }

  async cancelar() {
    this.guardarFlag = true;
    this.loaderService.isLoading.next(true);

    const datosProyecto = {
      id: this.datosProyecto.id,
      estadoProyecto: {
        id: "EDPRO-5"
      }
    }

    const cerrar$ = this.proyectoService.updateEstados(datosProyecto);
    await lastValueFrom(cerrar$);

    const tempCve$ = this.solicitudS.getSolicitudesAll(this.datosProyecto.id + "");
    let clavesSol: number[] = await lastValueFrom(tempCve$);

    for await (const cveSol of clavesSol) {
      const datosSolicitud = {
        idSolicitud: cveSol,
        estado: "EDSOL-10"
      }

      const retirar$ = this.solicitudS.updateEstados(datosSolicitud);
      await lastValueFrom(retirar$);
    }

    if (this.datosProyecto.id != null) {
      const bitacora = {
        cveBitacora: null,
        descAccion: 'Cancelación del proyecto: ' + this.datosProyecto.numFolio,
        cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
        cveElemento: this.datosProyecto.id,
        tipoMovimiento: {
          id: 'TIPMO-2',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        tipoBitacora: {
          id: 'TIPBI-1',
          desNemonico: '',
          cveDetcat: null,
          desDetcat: '',
          desDetcor: '',
          staRegis: true,
          catalogo: null,
          pais: null,
          seleccionar: null
        },
        cveIdProyecto: null
      };

      const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
      await lastValueFrom(bitacora$);
    }

    for await (const cm of this.personasOwner) {

      if (cm.indNotificacion) {
        const ownerRegistro$ = this.proyectoService.datosUsuario(cm.cveUsuario);
        let rownerRegistro = await lastValueFrom(ownerRegistro$);
        this.listaCorreosNot.push(rownerRegistro.correo);
      }

    }

    for await (const rp of this.personasRel) {
      if (rp.indNotificacion) {
        const responsableRegistro$ = this.proyectoService.datosUsuario(rp.cveUsuario);
        let responsableRegistro = await lastValueFrom(responsableRegistro$);
        this.listaCorreosNot.push(responsableRegistro.correo);
      }
    }

    for await (const ex of this.personasExternas) {
      if (ex.indNotificacion) {
        const externoRegistro$ = this.proyectoService.datosUsuario(ex.cveUsuario);
        let externoRegistro = await lastValueFrom(externoRegistro$);
        this.listaCorreosNot.push(externoRegistro.correo);
      }
    }

    const mailDTO = {
      usuariosMail: this.listaCorreosNot,
      subject: "Notificación cancelación de proyecto",
      infoMailDTO: {
        numFolio: this.datosProyecto.numFolio!,
        titulo: this.datosProyecto.nombreProyecto,
        descripcion: this.datosProyecto.descripcionProyecto,
        usuariosTramite: []
      }
    }

    const sendEmail$ = this.notificacionesService.notificarCancelarProyecto(mailDTO);
    await lastValueFrom(sendEmail$);

    const bitacoraEmailAlta = {
      cveBitacora: null,
      descAccion: 'Envío correo electrónico cancelación de proyecto número de folio: ' + this.datosProyecto.numFolio +
        ' título del proyecto: ' + this.datosProyecto.nombreProyecto + ' descripción ' + this.datosProyecto.descripcionProyecto +
        ' lista de usuarios notificados por correo electrónico ' + this.listaCorreosNot.join(', '),
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosProyecto.id!,
      tipoMovimiento: {
        id: 'TIPMO-5',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    const bitacoraEmail$ = this.bitacoraPSService.saveBitacora(bitacoraEmailAlta);
    await lastValueFrom(bitacoraEmail$);

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'El proyecto fue cancelado con éxito' });
    this.cancel();
    this.loaderService.isLoading.next(false);
    this.guardarFlag = false;
    this.ngOnInit();

  }

  showEditar() {
    this.dlgEditPro = true;

  }

  showSubirArchivos() {
    this.dlgSubirArchivo = true;

  }

  async subirArchivos() {

    for await (const arch of this.archivosSubida) {
      arch.cveElemento = this.datosProyecto.id + "";
      if (this.homePath.includes('-internal')) {
        await this.archivoService.uploadFile(arch.documento, "PROYECTO-" + arch.cveElemento).toPromise().catch(() => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
        }).finally(async () => {
          this.archivoService.saveFile(arch).toPromise();
          const bitacoraSubida = {
            cveBitacora: null,
            descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosProyecto.id!,
            tipoMovimiento: {
              id: 'TIPMO-7',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };

          const bitacora$ = this.bitacoraPSService.saveBitacora(bitacoraSubida);
          await lastValueFrom(bitacora$);
        });
      } else {
        await this.archivoService.uploadFiles2(arch.documento, "PROYECTO/" + arch.cveElemento).promise().then(async () => {
          await lastValueFrom(this.archivoService.saveFile(arch));
          const bitacoraSubida = {
            cveBitacora: null,
            descAccion: 'Subida archivo, nombre: ' + arch.nombre + ' peso ' + arch.documento.size + ' bytes ',
            cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
            cveElemento: this.datosProyecto.id!,
            tipoMovimiento: {
              id: 'TIPMO-7',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            tipoBitacora: {
              id: 'TIPBI-1',
              desNemonico: '',
              cveDetcat: null,
              desDetcat: '',
              desDetcor: '',
              staRegis: true,
              catalogo: null,
              pais: null,
              seleccionar: null
            },
            cveIdProyecto: null
          };
          await lastValueFrom(this.bitacoraPSService.saveBitacora(bitacoraSubida));
        }).catch(() => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo: ' + arch.documento.name + ' no cargado.' });
        });
      }
    }
    this.archivosSubida = [];
    this.cancel();
    this.ngOnInit();
  }

  async eliminaArch() {

    const eliminarArch$ = this.archivoService.eliminarArchivoProyecto(this.archivoEliminar.cveDoc);
    await lastValueFrom(eliminarArch$);

    const bitacora = {
      cveBitacora: null,
      descAccion: 'Archivo con Id: ' + this.archivoEliminar.cveDoc + 'nombre: ' + this.archivoEliminar.nombre + ' eliminado',
      cveUsuarioAccion: this.usuarioSesionService.usuario.idUsuario,
      cveElemento: this.datosProyecto.id!,
      tipoMovimiento: {
        id: 'TIPMO-3',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      tipoBitacora: {
        id: 'TIPBI-1',
        desNemonico: '',
        cveDetcat: null,
        desDetcat: '',
        desDetcor: '',
        staRegis: true,
        catalogo: null,
        pais: null,
        seleccionar: null
      },
      cveIdProyecto: null
    };

    const bitacora$ = this.bitacoraPSService.saveBitacora(bitacora);
    await lastValueFrom(bitacora$);

    this.archivoEliminar = [];
    this.cancel();
    this.ngOnInit();

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }

}


