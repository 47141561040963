import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {

  fecha: Date;
  version: string;
  ambiente: string;

  constructor() {
    this.fecha = new Date();
    this.version = environment.version;
    this.ambiente = environment.ambiente;
  }

}
