import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Archivo } from 'src/app/interfaces/archivo.interface';
import { HomePathService } from 'src/app/services/home-path.service';
import { SolicitudInformacionService } from 'src/app/services/solicitud-informacion.service';
import { ArchivoService } from 'src/app/services/archivo.service';
import { lastValueFrom } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { MimeType } from 'src/app/services/mimeType.service';
import * as fileSaver from 'file-saver';
import { UsuarioSesionService } from 'src/app/services/usuario-sesion.service';

@Component({
  selector: 'app-documentos-solicitud',
  templateUrl: './documentos-solicitud.component.html'
})
export class DocumentosSolicitudComponent implements OnInit {

  archivos: any[] = [];
  archivosInformation: Archivo[] = [];
  archivosProyecto: Archivo[] = [];
  homePath: string;

  doc: any[] = [{ nombre: "documento.pdf" }];



  constructor(private router: Router,
    private homePathService: HomePathService,
    private messageService: MessageService,
    private solicitudInformacionService: SolicitudInformacionService,
    private archivoService: ArchivoService,
    private loaderService: LoaderService,
    private mimeType: MimeType,
    public usuarioSesionService: UsuarioSesionService) { }

  async ngOnInit() {
    this.homePath = this.homePathService.getHomePath();
    this.archivosInformation = this.solicitudInformacionService.datosSolicitud.archivosSolicitud;
    this.archivosProyecto = this.solicitudInformacionService.datosSolicitud.archivosProyectoVisible;

    if (this.archivosInformation != null) {
      this.archivosInformation.forEach(arch => {
        const archivo: any = {
          nombre: arch.nombre,
          file: arch.documento,
        }
        this.archivos.push(archivo);
      });
    } else {
      this.archivosInformation = [];
    }
    //  console.log("valor id proyecto: " + this.solicitudInformacionService.datosSolicitud.idProyecto)

    if (this.archivosProyecto == null) {
      const listaArchivos$ = this.archivoService.listFiles(this.solicitudInformacionService.datosSolicitud.idProyecto + '', 'ORDOC-1');
      this.archivosProyecto = await lastValueFrom(listaArchivos$);
    }

  }

  myUploader(event, formUpload): void {

    if (event.files.length !== 0) {
      for (let file of event.files) {
        const archivo = {
          nombre: file.name,
          file: file
        };

        if (this.archivos.length > 0) {
          let existeTemp = this.archivos.find(arch => arch.nombre === archivo.nombre);
          let existeGlobal = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);

          if (existeTemp == null && existeGlobal == null) {
            this.archivos.push(archivo);
            this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos cargados.' });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ya fue agregado un documento con el mismo nombre', life: 6000 });
          }

        } else {
          this.archivos.push(archivo);
          this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivos cargados.' });
        }


        formUpload.clear();

      }


      /*    this.archivos.forEach( x => {
           console.log("nombre archivos: " + x.nombre);
           console.log("tamaño: " + x.file.size);
          })  */


    }
  }


  eliminaArch(arch: any) {
    this.archivos = this.archivos.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });

    this.archivosInformation = this.archivosInformation.filter(function (value, index, arr) {
      return value.nombre !== arch.nombre;
    });

    this.messageService.add({ severity: 'success', summary: 'Éxito', detail: 'Archivo eliminado.', life: 6000 });
  }

  async download(nombre: string, path: string) {
    this.loaderService.isLoading.next(true);
    if (this.homePath.includes('-internal')) {
      const mime = this.mimeType.getMimeTypefromString('.' + this.getFileExtension3(nombre));
      this.archivoService.downloadFile(nombre, path)
        .subscribe(
          file => {
            const data: Blob = new Blob([file], { type: mime });
            fileSaver.saveAs(data, nombre);
            this.loaderService.isLoading.next(false);
          },
          error => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Archivo no descargado.', life: 6000 });
          });
    } else {
      this.archivoService.downloadExternal(path, nombre);
    }
  }

  getFileExtension3(filename) {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
  }

  prevPage() {

    // console.log("tamaño docmuentos: " + this.archivos.length)
    this.solicitudInformacionService.datosSolicitud.archivosProyectoVisible = this.archivosProyecto;

    if (this.archivos.length > 0) {

      this.archivos.forEach(arch => {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: arch.file,
          fechaCreacion: null,
          nombre: arch.nombre,
          origenDoc: 'ORDOC-2',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: this.solicitudInformacionService.datosSolicitud.idProyecto + ''
        }
        let existe = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);
        if (existe == null) {
          this.archivosInformation.push(archivo);
        }

      })


      this.solicitudInformacionService.datosSolicitud.archivosSolicitud = this.archivosInformation;
      this.router.navigate([this.homePath + '/proyecto/solicitud/checklist']);

    } else {

      this.solicitudInformacionService.datosSolicitud.archivosSolicitud = []
      this.router.navigate([this.homePath + '/proyecto/solicitud/checklist']);

    }


  }

  nextPage() {

    this.solicitudInformacionService.datosSolicitud.archivosProyectoVisible = this.archivosProyecto;



    if (this.archivos.length > 0) {

      this.archivos.forEach(arch => {
        const archivo: Archivo = {
          cveElemento: null,
          cveDoc: null,
          documento: arch.file,
          fechaCreacion: null,
          nombre: arch.nombre,
          origenDoc: 'ORDOC-2',
          usuarioCreacion: this.usuarioSesionService.usuario.idUsuario,
          visible: null,
          cveIdProyecto: this.solicitudInformacionService.datosSolicitud.idProyecto + ''
        }
        let existe = this.archivosInformation.find(arch => arch.nombre === archivo.nombre);
        if (existe == null) {
          this.archivosInformation.push(archivo);
        }
      })

      this.solicitudInformacionService.datosSolicitud.archivosSolicitud = this.archivosInformation;
      this.router.navigate([this.homePath + '/proyecto/solicitud/confirmacion'])

    } else {
      this.solicitudInformacionService.datosSolicitud.archivosSolicitud = []
      this.router.navigate([this.homePath + '/proyecto/solicitud/confirmacion'])
    }

  }


}
