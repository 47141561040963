import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { api } from 'src/environments/environment';
import { Proyecto, Solicitud } from "../interfaces/reportes.interface";
import { catchError, Observable, throwError } from "rxjs";
import { MessageService } from "primeng/api";

@Injectable()
export class ReportesService {

    URL = `${api.urlAPI}` + 'api/reportes/';

    constructor(private http: HttpClient,
        private messageService: MessageService) { }

    obtenerProyectos(page: number, size: number, filters: string | null, estatus: string | null): Observable<Proyecto[]> {
        let params = new HttpParams().set('page', page).append('size', size);

        if (filters) {
            params = params.append('filters', filters);
        }

        return this.http.get<Proyecto[]>(this.URL + 'proyectos/estatus/' + estatus, { params });
    }

    obtenerSolicitud(numFolio: string): Observable<Solicitud[]> {
        return this.http.get<Solicitud[]>(this.URL + numFolio).pipe(
            catchError(error => {
                console.error(error.error.mensaje + "cachando error !!!!!!");
                this.messageService.add({ severity: 'error', summary: 'Error!', detail: error.error.mensaje, life: 6000 });
                return throwError(error);
            })
        );
    }

    getListaReportes(numReg: string): Observable<string[]> {
        return this.http.get<string[]>(this.URL + 'lista/' + numReg);
    }

    downloadFile(usuario: string, estatus: string, fechaInicio: string): Observable<any> {
        let params = new HttpParams();
        params = params
            .set('usuario', usuario)
            .set('estatus', estatus)
            .set('fechaInicio', fechaInicio);
        return this.http.get(this.URL + 'excel', { responseType: 'text', params });
    }

    generaReporte(cveProyecto: string): Observable<any> {
        let params = new HttpParams()
            .set('cveProyecto', cveProyecto);
        return this.http.get(this.URL + 'excel', { responseType: 'text', params });
    }
}
